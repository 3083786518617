export function titleCase(word: string): string {
  if (!word || word.length === 0) {
    return ''
  }
  return toUpperCaseCustom(word.charAt(0)) + toLowerCaseCustom(word.substr(1))
}

export function stringifyObjectForGrid(obj: any): string {
  if (typeof obj === 'function') {
    return ''
  }

  if (typeof obj !== 'object') {
    return obj
  }

  const makeStringFromObject = (x: Record<string, any>) => {
    let str = ''
    let count = 0
    for (const [key, value] of Object.entries(x)) {
      if (count !== 0) {
        str += ', '
      }
      str += `${key}: ${value}`
      count += 1
    }
    return str
  }

  if (!Array.isArray(obj)) {
    return makeStringFromObject(obj)
  }

  let str = ''
  for (let i = 0; i < obj.length; i++) {
    if (i !== 0) {
      str += ', '
    }
    str += makeStringFromObject(obj[i])
  }

  return str
}

export function toLowerCaseCustom(text?: string | string[], transform?: (text: string) => string): string {
  if (!text) {
    return ''
  }
  if (typeof text === 'string') {
    if (transform) {
      return transform(text).toLowerCase()
    }
    return text.toLowerCase()
  }
  if (text.length === 0) {
    return ''
  }

  if (transform) {
    return transform(text[0]).toLowerCase()
  }
  return text[0]
}

export function toUpperCaseCustom(text: any, transform?: (text: string) => string): string {
  try {
    if (text === undefined) {
      return ''
    }
    if (transform) {
      return transform(text).toUpperCase()
    }
    return text.toUpperCase()
  } catch (error) {}
  return text
}

export function ensureSingleSpaceInsideString(text: any): string {
  try {
    text = text.replace(/\s+/g, ' ')
  } catch (error) {}
  return text
}

export function removeUnderScores(text: any): string {
  try {
    text = text.replace(/_/g, ' ')
  } catch (error) {}
  return text
}

export function sentenceCase(text: any): string {
  try {
    let textArray = text.split(' ') as string[]
    textArray = textArray.map((word) => titleCase(word))
    text = textArray.join(' ')
  } catch (error) {}
  return text
}

export const toSentenceCase = (camelCase: string) => {
  if (camelCase) {
    const result = camelCase.replace(/([A-Z])/g, ' $1')
    return result[0].toUpperCase() + result.substring(1).toLowerCase()
  }
  return ''
}

export function addUnderScores(text: any): string {
  let formattedText = ''
  try {
    formattedText = text.replace(/\s/g, '_')
  } catch (error) {}
  return formattedText
}

export function removeAllSpaces(text: any): string {
  try {
    text = text.replace(/ /g, '')
  } catch (error) {}
  return text
}

export function toPfKeySchema(text: string): string {
  if (!text) {
    return ''
  }
  text = text.replace(/^\s+|\s+$/gm, '')
  text = text.replace(/[^a-zA-Z\d\s:]*/g, '')
  return toLowerCaseCustom(addUnderScores(text))
}

export function ensureArrayFormat(variable: any, separator: string = '; '): string[] {
  let arrayVariable: string[] = []
  const isArray = Array.isArray(variable)
  const dataType = isArray ? 'array' : typeof variable

  if (dataType === 'array') {
    arrayVariable = variable
  } else if (dataType === 'string') {
    arrayVariable = removeAllSpaces(variable).split(removeAllSpaces(separator))
  } else if (dataType === 'number') {
    arrayVariable = [`${variable}`]
  } else if (dataType === 'object') {
    arrayVariable = Object.values(variable)
  }
  return arrayVariable
}

export function ensureStringFormat(possibleArray: any, separator: string = '; ') {
  if (possibleArray === undefined || possibleArray === null) {
    return ''
  }
  if (Array.isArray(possibleArray)) {
    possibleArray = possibleArray.filter((arrayValue) => arrayValue)
    possibleArray.sort()
    return possibleArray.join(separator)
  }
  return `${possibleArray}`
}

export function coalesce(...args: string[]): string {
  for (const arg of args) {
    if (arg === undefined || arg === null || typeof arg !== 'string' || arg === '') {
      continue
    }
    return arg
  }
  return ''
}

export function isNumericString(input: string): boolean {
  return typeof input === 'string' && !isNaN(parseInt(input))
}

export function normaliseStringForComparison(filterString: string): string {
  if (!filterString) {
    return ''
  }
  return removeUnderScores(toUpperCaseCustom(ensureStringFormat(filterString).trim()))
}

export function removeLeadingAndTrailingSpaces(text: string): string {
  if (!text) {
    return ''
  }
  return text.trim()
}

export function isLowerCase(str: string): boolean {
  return str === str.toLowerCase()
}
