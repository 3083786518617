import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Radium from "radium"
import Icon from "@mdi/react"
import { mdiLock } from "@mdi/js"

import { ColorPalette } from "../../config"
import { UiCategoryEnum, SubMenuSchema, SubSubMenuSchema } from "../../types"
import SubSubMenu from "./SubSubMenu"
import { toUpperCaseCustom } from "../../utils"
import { ExportService } from "../../services"
import { PeopleFlowCombinedReducer } from "../../store"

interface Props {
  subMenus: SubMenuSchema[]
  label: string
  subSubMenus?: SubSubMenuSchema
  selectedAssociation: string
  selectedRole: string
  primaryTableRef?: React.RefObject<any>
  buttonsWithCustomActions: string[]
  onSubMenuClick: (subMenuLabel: UiCategoryEnum, hasAccess?: boolean) => void
  onSubSubMenuClick: (subMenuLabel: UiCategoryEnum, subSubMenuName: UiCategoryEnum, hasAccess?: boolean) => void
}

interface State {
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  disciplineWarningMessage: string
  selectedDate: Date
  exportTemplate: string
}

class SubMenu extends Component<Props, State> {
  exportService = new ExportService()

  state: State = {
    loadingModalMessage: "",
    warningModalHeader: "",
    warningModalMessage: "",
    disciplineWarningMessage: "",
    selectedDate: new Date(),
    exportTemplate: "MASTER",
  }

  getRoute(subMenuLabel: UiCategoryEnum) {
    let route = "/comingsoon"
    if (Object.values(UiCategoryEnum).includes(subMenuLabel)) {
      route = `/${this.props.label.toLowerCase().replace(/\s/g, "")}/${subMenuLabel.toLowerCase().replace(/\s/g, "")}`
    }
    return route
  }

  linkClickHandler(event: any, screenName: UiCategoryEnum, hasAccess?: boolean) {
    const { buttonsWithCustomActions } = this.props
    if (buttonsWithCustomActions.includes(screenName) || !hasAccess) {
      event.preventDefault()
    }
  }

  render() {
    const { subSubMenus } = this.props
    const subMenuButtons = [...this.props.subMenus].map((subMenuConfig, i) => {
      const { screenName, label } = subMenuConfig
      let subMenu = null
      if (subSubMenus?.hasOwnProperty(screenName)) {
        subMenu = (
          <SubSubMenu
            parentScreen={screenName}
            menuOptions={subSubMenus}
            onSubSubMenuClick={(subSubMenuName: UiCategoryEnum, hasAccess?: boolean) =>
              this.props.onSubSubMenuClick(screenName, subSubMenuName, hasAccess)
            }
          />
        )
      }

      let lockIcon = null
      if (!subMenuConfig.hasAccess) {
        lockIcon = <Icon path={mdiLock} color={ColorPalette.PRIMARY_TEXT} style={{ ...styles.lockIconStyle }} />
      }
      return (
        <div key={`div_${i}_${label}`}>
          <Link
            key={`${i}_${label}`}
            style={{ ...styles.buttonContainer }}
            to={this.getRoute(screenName)}
            onClick={(event) => this.linkClickHandler(event, screenName, subMenuConfig.hasAccess)}>
            <button
              key={`${i}_${label}`}
              style={{ ...styles.subMenuButton, opacity: subMenuConfig.hasAccess ? 1 : 0.4 }}
              onClick={() => this.props.onSubMenuClick(screenName, subMenuConfig.hasAccess)}>
              {toUpperCaseCustom(label)}
            </button>
            {lockIcon}
          </Link>
          {subMenu}
        </div>
      )
    })

    return <div style={styles.subMenuContainer}>{subMenuButtons}</div>
  }
}

const styles = {
  buttonContainer: {
    width: "100%",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
  subMenuContainer: {
    zIndex: 100,
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 3px 4px rgba(140,140,150, 0.5)",
    borderRadius: "0px 0px 10px 10px",
    padding: "20px 30px 20px 20px",
    marginRight: -window.innerWidth * 0.04,
  },
  subMenuButton: {
    fontSize: "0.8rem",
    fontWeight: "500",
    fontFamily: "Roboto",
    color: ColorPalette.PRIMARY_TEXT,
    backgroundColor: "rgba(255,255,255,0)",
    marginTop: 5,
    marginBottom: 5,
    height: 26,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
    outline: "none",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "rgba(255,255,255,0)",
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as "solid",
    borderBottomColor: "rgba(255,255,255,0)",
    ":hover": {
      borderBottomColor: ColorPalette.PRIMARY_BLUE,
    },
    ":active": {
      filter: "brightness(30%)",
    },
  },
  lockIconStyle: {
    marginBottom: 6,
    width: 12,
    opacity: 0.4,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedRole: state.sessionManager.selectedRole,
  }
}

export default connect(mapStateToProps)(Radium(SubMenu))
