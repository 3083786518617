import { PpeSku, PpeSuppliers } from './ppe'

export type ConditionalSelectorItems = {
  priFieldKey: string
  values: Record<string, string[]>
}
export type ValidFieldType = 'text' | 'picker' | 'selector' | 'conditionalSelector'
export enum FieldConfigCompType {
  TEXT = 'text',
  SELECTOR = 'selector',
  PICKER = 'picker',
  CONDITIONAL_SELECTOR = 'conditionalSelector',
}

type AutoPopSelectorValue = string
export type AutoPopKey = string
type AutoPopValue = string | number | boolean
export type AutoPops = Record<AutoPopKey, AutoPopValue>
export type AutoPopulate = Record<AutoPopSelectorValue, AutoPops>

export type FieldConfigItemSchema = {
  key: FieldConfigKey
  DO_NOT_MODIFY?: string
  allowCustomOption?: boolean
  setDefaultValueFromHistory?: boolean
  suggestionsEnabled?: boolean
  useDefaultValue?: boolean
  validationType?: string
  compType: FieldConfigCompType
  label: string
  selectorItems?: string[]
  defaultValue?: string
  allowNull?: boolean
  autoPopulate?: AutoPopulate
  isPriField?: boolean
  compulsory?: boolean
  maxItems?: number
  dateFormat?: DateFormat
  skus?: PpeSku[]
  selectionPriority?: number
  bookValue?: string
  bookValueCurrency?: 'ZAR'
  isPpe?: boolean
  isUniquePerProfile?: boolean
  workflowBranch?: boolean
  disabled?: boolean
  filterParameters?: Record<string, string>
  iconName?: string
  keyboardType?: 'number-pad'
  multiline?: boolean
  conditionalSelectorItems?: ConditionalSelectorItems
  placeholder?: string
  maxLength?: number
  multiLine?: boolean
  singleSelectionMode?: boolean
  suppliers?: PpeSuppliers
  compTypePickerDisabled?: boolean
}

export type FieldName = string
export type FieldConfigKey = string
export type FieldConfigLabel = string

export type FieldConfigKeyLabelPair = {
  key: FieldConfigKey
  label: FieldConfigLabel
}

export type DateFormat = string

export type FieldConfigSchema = Record<FieldConfigKey, FieldConfigItemSchema>
