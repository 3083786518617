import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Property } from 'csstype'
import { GridSize } from '@material-ui/core/Grid'
import _ from 'lodash'
import { mdiPlus } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { removeUnderScores, toLowerCaseCustom, toUpperCaseCustom } from '../../utils'
import completedScreens from '../completedScreens'
import { SessionService, UserService } from '../../services'
import DataTable from '../../components/Tables/DataTable/DataTable'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { PeopleFlowCombinedReducer } from '../../store'
import UserDetailsModal from '../../components/Modals/UserDetailsModal'
import { CognitoMessageDeliveryMedium, NavMenuAccess, UiCategoryEnum } from '../../types'
import { UsersRepository } from '../../repositories'
import { User, UserProfileSchema } from '../../models'
import { AssociationSettingsRepository } from '../../repositories'
import { Toolbar } from '../../components/GeneralUI/Toolbar'
import Modal from '../../components/Modals/Modal'
import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../components/BaseComponents/Buttons/ButtonGrey'
import CheckboxTabLabelled from '../../components/BaseComponents/Checkboxes/CheckboxTabLabelled'

const { EMAIL, SMS } = CognitoMessageDeliveryMedium
const columnConfig: { id: ValidKeys; label: string; sizeFactor: number }[] = [
  { id: 'fullName', label: 'Name', sizeFactor: 1.2 },
  { id: 'username', label: 'Username', sizeFactor: 1.2 },
  { id: 'roleString', label: 'Roles', sizeFactor: 1.2 },
  { id: 'cognitoStatus', label: 'Password Status', sizeFactor: 1 },
]

type UsersRowContent = {
  id: string
  idPassport: string
  username: string
  fullName: string
  roleString: string
  cognitoStatus: string
  userEntity: User
}
type ValidKeys = 'id' | 'username' | 'fullName' | 'roleString' | 'cognitoStatus'

interface UsersProps extends RouteComponentProps {
  selectedAssociation: string
  idPassport: string
  password: string
  currentSection: UiCategoryEnum
  userRepo: UsersRepository
  associationRepo: AssociationSettingsRepository
  currentScreen: UiCategoryEnum
  selectedRole: string
  RequestPic: string
  navMenuAccess: NavMenuAccess

  updateState: (data: any) => void
  setAllEmployers: (allCompanies: string[]) => void
  changeScreen: (screen: string) => void
}

interface UsersState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  messageDeliveryMediumModalOpen: boolean
  forgotPasswordModalOpen: boolean
  userManagerModalOpen: boolean
  settingsModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  downloading: boolean
  tableData: UsersRowContent[]
  selectedRecord?: UsersRowContent
  tableWidth: GridSize
  selectedRowItemId: string
  searchString: string
  accountRoleNames: string[]
  selectedUserData: UserProfileSchema | null
  isUserCreation: boolean
  isUserConfirmed: boolean
  isExistingUserAddition: boolean
  selectedMessageDeliveryMedium: CognitoMessageDeliveryMedium
}

class Users extends Component<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  sectionHeaderRef: React.RefObject<any>
  primaryTableRef: React.RefObject<DataTable>

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    messageDeliveryMediumModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    userManagerModalOpen: false,
  }

  state: UsersState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    downloading: true,
    tableData: [],
    tableWidth: 11,
    selectedRowItemId: '',
    searchString: '',
    userManagerModalOpen: false,
    selectedUserData: null,
    accountRoleNames: [],
    isUserCreation: false,
    isUserConfirmed: false,
    isExistingUserAddition: false,
    selectedMessageDeliveryMedium: CognitoMessageDeliveryMedium.EMAIL,
  }

  componentDidMount() {
    this.props.updateState({
      RequestPic: '',
      currentSection: UiCategoryEnum.USERS,
      currentScreen: UiCategoryEnum.USERS,
    })
    this.loadTable()
  }

  componentDidUpdate(prevProps: UsersProps) {
    const screenChanged = prevProps.currentScreen !== this.props.currentScreen
    if (screenChanged) {
      this.loadUserData()
    }
  }

  async loadTable() {
    if (this.primaryTableRef && this.primaryTableRef.current) {
      this.primaryTableRef.current.reload()
      await this.loadUserData()
    } else if (this.props.currentSection === UiCategoryEnum.USERS) {
      setTimeout(() => this.loadTable(), 250)
    }
  }

  async loadUserData() {
    try {
      this.setState({ loadingModalOpen: true, loadingModalMessage: 'Loading users...' })
      await this.updateAllUsers()
      this.displayRowDataFromState()
      this.setState({ loadingModalOpen: false })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async updateAllUsers(): Promise<void> {
    const { idPassport, password, selectedAssociation, userRepo, associationRepo } = this.props
    const userService = new UserService()
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    const { accountRoleNames } = await userService.refreshAssociationUsers(selectedAssociation, userRepo, token)
    associationRepo.setAllAssocationRoles(selectedAssociation, accountRoleNames)
  }

  private displayRowDataFromState(): void {
    let userEntities = Object.values(this.props.userRepo.getAssocationUserEntities())
    userEntities = [...userEntities].filter((user) => user.getRoleNames().length)
    const tableData = this.prepareDisplayData(userEntities)
    this.setPrimaryDataAndReload(tableData)
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  closeRolesModal() {
    this.setState({ ...this.initialModalState, selectedUserData: null })
  }

  async refreshData() {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Refreshing users...',
    })
    return this.loadUserData()
  }

  private setPrimaryDataAndReload(tableData: UsersRowContent[]) {
    this.setState({ tableData, downloading: false }, () => {
      if (!this.primaryTableRef?.current) {
        return
      }
      this.primaryTableRef.current.reload()
    })
  }

  submissionHandler = async (
    userData: UserProfileSchema,
    userCreation: boolean,
    userConfirmed: boolean,
    existingUserAddition: boolean,
  ) => {
    if (userCreation || (!userConfirmed && !existingUserAddition)) {
      this.setState({
        ...this.initialModalState,
        messageDeliveryMediumModalOpen: true,
        selectedUserData: userData,
      })
    } else {
      await this.saveUpdates(userData)
    }
  }

  async saveUpdates(userData: UserProfileSchema, messageDeliveryMediums?: CognitoMessageDeliveryMedium[]) {
    try {
      this.setState({ loadingModalOpen: true, loadingModalMessage: 'Saving changes...', userManagerModalOpen: false })
      const { selectedAssociation, idPassport, password, userRepo } = this.props
      const { isUserCreation, isExistingUserAddition, isUserConfirmed } = this.state
      if (isExistingUserAddition) {
        userData = { username: userData.username, roleNames: userData.roleNames }
      }
      const userService = new UserService()
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      if (!isUserConfirmed && !isUserCreation && !isExistingUserAddition) {
        await userService.resendTempPassword(userData.username, token, messageDeliveryMediums)
      }
      await userService.upsertUser(
        userRepo,
        selectedAssociation,
        userData,
        token,
        isUserCreation,
        messageDeliveryMediums,
      )
      if (isExistingUserAddition) {
        this.loadUserData()
      } else {
        this.displayRowDataFromState()
        this.setState({ loadingModalOpen: false, selectedUserData: null })
      }
    } catch (error) {
      this.displayWarning(error)
    }
  }

  prepareDisplayData(userEntities: User[]): UsersRowContent[] {
    return userEntities.map((userEntity) => {
      const { username, name, surname } = userEntity.getPersonalUserInfo()
      const roleNames = userEntity.getRoleNames()
      return {
        id: username,
        idPassport: username,
        username: username,
        fullName: `${name} ${surname}`,
        roleString:
          roleNames
            .sort()
            .map((roleName) => toUpperCaseCustom(removeUnderScores(roleName)))
            .join('; ') || '',
        cognitoStatus:
          userEntity.getCognitoConfirmationStatus() === 'CONFIRMED' ? 'VALID' : userEntity.getPasswordExpiry(),
        userEntity,
      }
    })
  }

  selectUser(rowItemId: string) {
    this.sectionHeaderRef.current.disableSearch()
    const item = this.state.tableData.find((row) => row.id === rowItemId)
    if (item === undefined) {
      return
    }
    const personalInfo = item.userEntity.getPersonalUserInfo()
    const roleNames = item.userEntity.getRoleNames().map((role) => removeUnderScores(role))
    const isUserConfirmed = item.userEntity.getCognitoConfirmationStatus() === 'CONFIRMED'
    this.setState({
      selectedRowItemId: rowItemId,
      selectedUserData: { ...personalInfo, roleNames },
      isUserConfirmed,
      isUserCreation: false,
      isExistingUserAddition: false,
      userManagerModalOpen: true,
    })
  }

  searchHandler(searchString: string) {
    this.setState({ searchString })
    if (!this.primaryTableRef?.current) {
      return
    }
    this.primaryTableRef.current.search(searchString)
  }

  sideMenuHandler(screen: string) {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  buildMessageDeliveryMethodModal(open: boolean) {
    const { selectedMessageDeliveryMedium, selectedUserData } = this.state
    const selectionHandler = (values: CognitoMessageDeliveryMedium[]) => {
      if (values.length) {
        this.setState({ selectedMessageDeliveryMedium: values[0] })
      }
    }
    if (!open || !selectedUserData) {
      return null
    }
    return (
      <Modal
        open={true}
        title="Choose a method"
        subTitle="Which method should be used to send the signup message and temporary password?"
        titleStyle={{ paddingBottom: 0 }}
        style={{ padding: 80 }}
        actionButtons={[
          <ButtonGrey
            onClick={() => this.setState({ messageDeliveryMediumModalOpen: false })}
            style={{ marginTop: 40, width: '94%' }}>
            Cancel
          </ButtonGrey>,
          <ButtonBlue
            onClick={() => this.saveUpdates(selectedUserData, [selectedMessageDeliveryMedium])}
            style={{ marginTop: 10, marginBottom: 10, width: '94%' }}>
            Send
          </ButtonBlue>,
        ]}>
        <CheckboxTabLabelled
          style={{ width: '82%', marginTop: 40 }}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label="Temporary Password Delivery"
          values={[selectedMessageDeliveryMedium]}
          selectorItems={[EMAIL, SMS]}
          highlightEmpty={true}
          maxItems={1}
          selectionHandler={(values) => selectionHandler(values as CognitoMessageDeliveryMedium[])}
        />
      </Modal>
    )
  }

  displayWarning(error: any) {
    let warningModalHeader = 'Warning'
    let warningModalMessage = ''

    if (error instanceof Error) {
      warningModalMessage = error.message
    } else {
      try {
        if (error.code === 'NetworkError') {
          warningModalMessage = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
        } else if (error.code === 'InvalidMobile') {
          warningModalMessage = 'Invalid mobile number'
        } else if (error.code === 'InvalidEmail') {
          warningModalMessage = 'Invalid email address'
        } else if (error.code === 'InvalidUsername') {
          warningModalMessage = 'Invalid username'
        } else if (error.code === 'UserAlreadyExists') {
          warningModalMessage = 'A user with that username already exists'
        } else if (error.code === 'EmptyRoleNames') {
          warningModalMessage = 'At least one role must be selected'
        } else if ('message' in error) {
          warningModalMessage =
            "The following error message was returned when logging in:\n\n'" +
            error.message +
            "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
        } else {
          warningModalMessage =
            'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
        }
      } catch (error) {
        warningModalMessage =
          'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader,
      warningModalMessage,
    })
  }

  render() {
    const { associationRepo, selectedAssociation, match, location, history, navMenuAccess, currentScreen } = this.props
    const {
      userManagerModalOpen,
      selectedUserData,
      isUserCreation,
      isUserConfirmed,
      isExistingUserAddition,
      loadingModalOpen,
      loadingModalMessage,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
      tableData,
      tableWidth,
      selectedRowItemId,
      downloading,
      searchString,
      messageDeliveryMediumModalOpen,
    } = this.state

    let userCreationModal = null
    if (userManagerModalOpen) {
      userCreationModal = (
        <UserDetailsModal
          userData={selectedUserData}
          accountRoleNames={associationRepo.getAllAssociationRoles(selectedAssociation)}
          isUserCreation={isUserCreation}
          isUserConfirmed={isUserConfirmed}
          isExistingUserAddition={isExistingUserAddition}
          dismiss={() => this.closeRolesModal()}
          onSave={(userData: UserProfileSchema) =>
            this.submissionHandler(userData, isUserCreation, isUserConfirmed, isExistingUserAddition)
          }
        />
      )
    }
    const messageDeliveryMethodModal = this.buildMessageDeliveryMethodModal(messageDeliveryMediumModalOpen)

    return (
      <div style={styles.container}>
        <NavBar match={match} location={location} history={history} reloadPageData={() => this.loadUserData()} />

        <SectionHeader
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={downloading}
          searchString={searchString}
          textHandler={(e) => this.searchHandler(e.target.value)}
          onClick={() => {}}>
          {currentScreen}
        </SectionHeader>

        {userCreationModal}

        <div
          style={{
            ...styles.contentContainer,
            animation: 'x 0.15s ease-out',
            animationName: fadeIn,
          }}>
          <div style={styles.content}>
            <Toolbar
              actionButtons={[
                {
                  iconPath: mdiPlus,
                  onClick: () =>
                    this.setState({ userManagerModalOpen: true, isUserCreation: true, isExistingUserAddition: false }),
                  label: 'ADD NEW USER',
                },
                {
                  iconPath: mdiPlus,
                  onClick: () =>
                    this.setState({ userManagerModalOpen: true, isUserCreation: false, isExistingUserAddition: true }),
                  label: 'ADD EXISTING USER',
                },
              ]}
            />
            <div style={styles.tableContainer}>
              <DataTable
                ref={this.primaryTableRef}
                tableData={tableData}
                columnConfig={columnConfig}
                tableWidth={tableWidth}
                onRowClick={(rowData: Record<string, any>) => this.selectUser(rowData.id)}
                selectedRowItemId={selectedRowItemId}
              />
            </div>
          </div>
        </div>

        {messageDeliveryMethodModal}
        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          body={warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <AlertModalOneButton
          open={!navMenuAccess.hasUserManagementAccess}
          header={'Not Authorised'}
          body={"You don't have permission to manage users."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => history.goBack()}
        />
        <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const hidden = {
  marginLeft: window.innerWidth * 0.16,
}

const visible = {
  marginLeft: window.innerWidth * 0.47,
}

const fadeIn = Radium.keyframes({
  '0%': hidden,
  '100%': visible,
}) as Property.AnimationName

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(5em, 5%)',
    width: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  tableName: {
    marginTop: window.innerHeight * 0.055,
    height: window.innerHeight * 0.7,
    width: '94%',
  },
  newRequestButton: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedRole: state.sessionManager.selectedRole,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    navMenuAccess: state.sessionManager.navMenuAccess,
    // hasUserManagementAccess: state.sessionManager.hasUserManagementAccess,
    userRepo: state.sessionManager.userRepo as UsersRepository,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Users))
