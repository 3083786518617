import React, { Component } from "react"
import { mdiDownload } from "@mdi/js"
import { RouteComponentProps } from "react-router"
import { connect } from "react-redux"
import Radium from "radium"
import Icon from "@mdi/react"
import { mdiCheck, mdiInformation } from "@mdi/js"

import DataTable from "../../components/Tables/DataTable/DataTable"
import NavBar from "../../components/Navigation/NavBar"
import SectionHeader from "../../components/Headings/SectionHeaderPrimary"
import LoadingModal from "../../components/Modals/LoadingModal"
import { ColorPalette } from "../../config/colors"
import { ActionType } from "../../store/actions/actions"
import { PeopleFlowCombinedReducer } from "../../store"
import { Toolbar } from "../../components/GeneralUI/Toolbar/Toolbar"
import AlertModalOneButton from "../../components/Modals/AlertModalOneButton"
import {
  AssociationId,
  AuthCredentials,
  ImportActionEnum,
  ImportPlanEvaluationItemSchema,
  ImportId,
  ImportProgressEnum,
  NavMenuAccess,
  ImportResultEvaluationItemSchema,
} from "../../types"
import { ProfileRepository } from "../../repositories"
import { ProfileService } from "../../services"
import { ProfileImportService } from "../../services/profileImportService"
import { ensureStringFormat, toUpperCaseCustom } from "../../utils"
import { ActionButtonType } from "../../components/GeneralUI/Toolbar"

const columnConfigPlan = [
  { id: "id", label: "Change ID", sizeFactor: 1 },
  { id: "label", label: "Field", sizeFactor: 1 },
  { id: "plannedChange", label: "Planned Change", sizeFactor: 1 },
  { id: "willBeApplied", label: "Will be applied?", sizeFactor: 1 },
]

const columnConfigResult = [
  { id: "id", label: "Change ID", sizeFactor: 1 },
  { id: "label", label: "Field", sizeFactor: 1 },
  { id: "plannedChange", label: "Change", sizeFactor: 1 },
  { id: "wasApplied", label: "Was applied?", sizeFactor: 1 },
]

const profileImportService = new ProfileImportService()
const profileService = new ProfileService()

const { INITIALISING, REVIEW_PLAN, COMPLETE } = ImportProgressEnum
type RouteState = { importId: ImportId; action: ImportActionEnum }

interface ProfileImportProps extends RouteComponentProps {
  selectedAssociation: AssociationId
  navMenuAccess: NavMenuAccess
  idPassport: string
  password: string
  profileRepo: ProfileRepository
}

type ProfileImportState = {
  progressState: ImportProgressEnum
  loadingModalOpen: boolean
  loadingModalMessage: string
  loadingModalAuxMessage: string
  warningModalOpen: boolean
  warningModalHeader: string
  warningModalMessage: string
  planList: ImportPlanEvaluationItemSchema[]
  resultList: ImportResultEvaluationItemSchema[]
  applyResults: any[]
  searchString: string
  selectedRowItemId: string
  isFinishedProcessing: boolean
  planTableData: Record<string, any>[]
  resultTableData: Record<string, any>[]
  selectedResultsTableData: Record<string, any>[]
  sectionHeaderLabel: string
}

class ProfileImports extends Component<ProfileImportProps, ProfileImportState> {
  sectionHeaderRef: React.RefObject<any>
  primaryTableRef: React.RefObject<DataTable>

  constructor(props: ProfileImportProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  initialModalState = {
    loadingModalAuxMessage: "",
    loadingModalOpen: false,
    warningModalOpen: false,
  }

  state: ProfileImportState = {
    ...this.initialModalState,
    progressState: INITIALISING,
    loadingModalMessage: "",
    loadingModalAuxMessage: "",
    planList: [],
    resultList: [],
    applyResults: [],
    searchString: "",
    selectedRowItemId: "",
    isFinishedProcessing: false,
    planTableData: [],
    resultTableData: [],
    selectedResultsTableData: [],
    warningModalHeader: "",
    warningModalMessage: "",
    sectionHeaderLabel: "",
  }

  componentDidMount() {
    const { importId, action } = this.props.location.state as RouteState
    const sectionHeaderLabel = action === ImportActionEnum.ADHOC ? "Adhoc Import" : "Bulk Terminations"
    this.setState({ sectionHeaderLabel })
    this.pollForPlanEvaluationList(importId)
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  pollForPlanEvaluationList = async (importId: ImportId) => {
    this.setState({
      progressState: INITIALISING,
      loadingModalOpen: true,
      loadingModalMessage: "Planning...",
      loadingModalAuxMessage: "This may take a few mins",
    })
    const planList = await this.getPlanEvaluationList(importId)
    if (!planList) {
      setTimeout(() => this.pollForPlanEvaluationList(importId), 5000)
    } else {
      const planTableData = this.packagePlanTableData(planList)
      this.setState({
        ...this.initialModalState,
        progressState: REVIEW_PLAN,
        planList,
        planTableData,
      })
    }
  }

  pollForResultEvaluationList = async (
    importId: ImportId,
    profileUpdateArgs?: {
      profileRepo: ProfileRepository
      association: AssociationId
      authCredentials: AuthCredentials
    },
  ) => {
    this.setState({
      loadingModalOpen: true,
      loadingModalMessage: "Applying changes...",
      loadingModalAuxMessage: "This may take a few mins",
    })
    const resultList = await this.getResultEvaluationList(importId)
    if (!resultList) {
      setTimeout(() => this.pollForResultEvaluationList(importId, profileUpdateArgs), 5000)
    } else {
      const resultTableData = this.packageResultTableData(resultList)
      this.setState({
        loadingModalMessage: "Fetching updated profiles...",
        progressState: COMPLETE,
        resultList,
        resultTableData,
      })
      if (!profileUpdateArgs) {
        this.closeModals()
        return
      }
      const { profileRepo, association, authCredentials } = profileUpdateArgs
      const profilePksToFetch = resultList.filter((change) => change.wasApplied).map((change) => change.pk || "")
      await profileService.downloadProfiles(profileRepo, association, profilePksToFetch, authCredentials)
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: "Complete",
        warningModalMessage: "All done. Check the following list to confirm which changes were successfully applied.",
      })
    }
  }

  getPlanEvaluationList = async (importId: ImportId) => {
    try {
      const { password, idPassport, selectedAssociation } = this.props
      const authCredentials = { username: idPassport, password }
      const result = await profileImportService.getProfileImportPlanResults(
        selectedAssociation,
        importId,
        authCredentials,
      )
      console.log("result: ", result)
      if (!result) {
        return
      }
      if (result.isComplete) {
        return result.importList
      }
    } catch (error) {
      console.log(error)
    }
    return null
  }

  getResultEvaluationList = async (importId: ImportId) => {
    try {
      const { password, idPassport, selectedAssociation } = this.props
      const authCredentials = { username: idPassport, password }
      const result = await profileImportService.getProfileImportResults(selectedAssociation, importId, authCredentials)
      if (!result) {
        return
      }
      if (result.isComplete) {
        return result.importList
      }
    } catch (error) {
      console.log(error)
    }
    return null
  }

  searchHandler(event: React.ChangeEvent<{ value: string }>) {
    this.setState({ searchString: event.target.value })
    if (!this.primaryTableRef?.current) {
      return
    }
    this.primaryTableRef.current.search(event.target.value)
  }

  packagePlanTableData(planList: ImportPlanEvaluationItemSchema[]) {
    return planList.map(({ rowId, label, message, before, after, willBeApplied }) => {
      let rightComponent = <div style={styles.iconPlaceholderContainer}></div>
      if (message) {
        rightComponent = (
          <div style={styles.warningIcon}>
            <Icon path={mdiInformation} color={ColorPalette.OFF_WHITE} size={0.75} />
          </div>
        )
      }

      const EMPTY_VALUE_PLACEHOLDER = " - "
      let plannedChange = toUpperCaseCustom(`${before} => ${after}`)
      if (!ensureStringFormat(before).length && !ensureStringFormat(after).length) {
        plannedChange = EMPTY_VALUE_PLACEHOLDER
      }

      return {
        id: toUpperCaseCustom(rowId),
        label: toUpperCaseCustom(label),
        willBeApplied: willBeApplied ? "YES" : "NO",
        plannedChange,
        disableSelect: !willBeApplied,
        onHoverMessage: message || null,
        rightComponent,
      }
    })
  }

  packageResultTableData(resultList: ImportResultEvaluationItemSchema[]) {
    return resultList.map(({ rowId, label, message, before, after, wasApplied }) => {
      let rightComponent = (
        <div style={styles.successIcon}>
          <Icon path={mdiCheck} color={ColorPalette.OFF_WHITE} size={0.75} />
        </div>
      )
      if (message) {
        rightComponent = (
          <div style={styles.warningIcon}>
            <Icon path={mdiInformation} color={ColorPalette.OFF_WHITE} size={0.75} />
          </div>
        )
      }

      const EMPTY_VALUE_PLACEHOLDER = " - "
      let plannedChange = toUpperCaseCustom(`${before} => ${after}`)
      if (!ensureStringFormat(before).length && !ensureStringFormat(after).length) {
        plannedChange = EMPTY_VALUE_PLACEHOLDER
      }

      return {
        id: toUpperCaseCustom(rowId),
        label: toUpperCaseCustom(label),
        wasApplied: wasApplied ? "YES" : "NO",
        plannedChange,
        disableSelect: !wasApplied,
        onHoverMessage: message || null,
        rightComponent,
      }
    })
  }

  onDownloadPlanList = async (association: AssociationId, importId: ImportId, authCredentials: AuthCredentials) => {
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: "Downloading plan evaluation list...",
      })
      await profileImportService.downloadImportPlanList(association, importId, authCredentials)
      this.closeModals()
    } catch (error) {
      this.errorHandler(error)
    }
  }

  onDownloadResultList = async (association: AssociationId, importId: ImportId, authCredentials: AuthCredentials) => {
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: "Downloading result evaluation list...",
      })
      await profileImportService.downloadImportResultList(association, importId, authCredentials)
      this.closeModals()
    } catch (error) {
      this.errorHandler(error)
    }
  }

  applyImport = async (
    association: AssociationId,
    importId: ImportId,
    authCredentials: AuthCredentials,
    profileRepo: ProfileRepository,
  ) => {
    try {
      const { planList } = this.state
      const hasChangesToApply = planList.some(({ willBeApplied }) => willBeApplied)
      if (!hasChangesToApply) {
        throw { code: "NoValidImportChanges" }
      }
      this.setState({
        loadingModalOpen: true,
        loadingModalMessage: "Applying changes...",
        loadingModalAuxMessage: "This may take a few mins",
      })
      // Fire and forget. Processing imports has the potential to take a long time and therefore execution time may exceed the 30s API Gateway timeout. Instead, we poll for results.
      profileImportService.applyImport(association, importId, authCredentials)
      this.pollForResultEvaluationList(importId, { profileRepo, association, authCredentials })
    } catch (error) {
      this.errorHandler(error)
    }
  }

  errorHandler = (error: any) => {
    let warningModalHeader = "Problem"
    let warningModalMessage =
      "We experienced a problem. Reload all profiles and try again. If the problem persists, then reach out to tech support."
    if (error.code === "NoValidImportChanges") {
      warningModalHeader = "Nothing to do"
      warningModalMessage = "The import list doesn't contain any valid changes."
    }
    this.setState({ loadingModalOpen: false, warningModalOpen: true, warningModalHeader, warningModalMessage })
  }

  getToolbarNavButtons = (
    progressState: ImportProgressEnum,
    selectedAssociation: AssociationId,
    importId: ImportId,
    authCredentials: AuthCredentials,
    profileRepo: ProfileRepository,
  ) => {
    let toolbarNavButtonConfig = {} as Record<string, any>
    if (progressState === REVIEW_PLAN) {
      toolbarNavButtonConfig = {
        left: { onClick: this.handleBackNavButtonClick },
        right: {
          onClick: () => this.applyImport(selectedAssociation, importId, authCredentials, profileRepo),
        },
      }
    }
    return toolbarNavButtonConfig
  }

  getToolbarActionButtons = (
    progressState: ImportProgressEnum,
    selectedAssociation: AssociationId,
    importId: ImportId,
    authCredentials: AuthCredentials,
  ) => {
    let toolbarActionButtons = [] as ActionButtonType[]
    if (progressState === REVIEW_PLAN) {
      toolbarActionButtons = [
        {
          iconPath: mdiDownload,
          onClick: () => this.onDownloadPlanList(selectedAssociation, importId, authCredentials),
          disabled: false,
          label: "DOWNLOAD PLAN",
        },
      ]
    }
    if (progressState === COMPLETE) {
      toolbarActionButtons = [
        {
          iconPath: mdiDownload,
          onClick: () => this.onDownloadResultList(selectedAssociation, importId, authCredentials),
          disabled: false,
          label: "DOWNLOAD RESULTS",
        },
      ]
    }
    return toolbarActionButtons
  }

  goBackToProfiles = () => {
    this.props.history.push("/people/employees")
  }

  handleBackNavButtonClick = () => {
    this.props.history.push("/people/employees", {
      // @ts-ignore
      openTerminationModal: this.props.history.location.state?.via === "bulkActions", // trigger termination file upload if we came via bulk actions
    })
  }

  render() {
    const {
      progressState,
      planTableData,
      resultTableData,
      selectedRowItemId,
      sectionHeaderLabel,
      loadingModalOpen,
      loadingModalMessage,
      loadingModalAuxMessage,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
    } = this.state

    const { importId } = this.props.location.state as RouteState
    const { password, idPassport, navMenuAccess, selectedAssociation, profileRepo } = this.props
    const { hasBulkTerminationAccess } = navMenuAccess
    const authCredentials = { username: idPassport, password }
    const toolbarNavButtons = this.getToolbarNavButtons(
      progressState,
      selectedAssociation,
      importId,
      authCredentials,
      profileRepo,
    )
    const toolbarActionButtons = this.getToolbarActionButtons(
      progressState,
      selectedAssociation,
      importId,
      authCredentials,
    )

    let contentArea = null
    if (progressState === REVIEW_PLAN) {
      contentArea = (
        <DataTable
          key={"plan_table"}
          ref={this.primaryTableRef}
          tableData={planTableData}
          columnConfig={columnConfigPlan}
          tableWidth={10}
          filterState={undefined}
          onRowClick={(rowData: Record<string, any>) => this.setState({ selectedRowItemId: rowData.id })}
          selectedRowItemId={selectedRowItemId}
          customFilterConfig={[]}
          filterModeEnabled={false}
          selectionEnabled={false}
          disabled={true}
          customComponentConfig={{ rightComponentWidth: 40 }}
          // initialSelectedRows={validSelectionIds}
        />
      )
    } else if (progressState === COMPLETE) {
      contentArea = (
        <DataTable
          key={"result_table"}
          ref={this.primaryTableRef}
          tableData={resultTableData}
          columnConfig={columnConfigResult}
          tableWidth={10}
          filterState={undefined}
          onRowClick={(rowData: Record<string, any>) => this.setState({ selectedRowItemId: rowData.id })}
          selectedRowItemId={selectedRowItemId}
          customFilterConfig={[]}
          filterModeEnabled={false}
          selectionEnabled={false}
          disabled={true}
          customComponentConfig={{ rightComponentWidth: 40 }}
          // initialSelectedRows={validSelectionIds}
        />
      )
    }

    return (
      <div style={{ ...styles.container }}>
        <NavBar
          match={this.props.match}
          location={this.props.location}
          history={this.props.history}
          primaryTableRef={this.primaryTableRef} // destined for SubMenu via prop drilling
        />
        <SectionHeader
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={false}
          searchString={this.state.searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => null}>
          {sectionHeaderLabel}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <div style={{ ...styles.main, paddingInline: "min(5em, 5%)" }}>
            <Toolbar navButtons={toolbarNavButtons} actionButtons={toolbarActionButtons} />
            <div style={styles.mainContent}>{contentArea}</div>
          </div>
        </div>
        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!hasBulkTerminationAccess}
          header={"Not Authorised"}
          body={"You don't have permission to access the bulk termination module."}
          buttonLabel={"Ok"}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          subHeader={warningModalMessage}
          buttonLabel={"Ok"}
          opaqueBackground={true}
          onClick={() => this.closeModals()}
        />
        <LoadingModal
          open={loadingModalOpen}
          loadingMessage={loadingModalMessage}
          auxilliaryMessage={loadingModalAuxMessage}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: "100vh",
  },
  sectionHeader: {
    margin: "3.5% auto 1.5%",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    overflow: "auto",
  },
  main: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    overflow: "hidden",
  },
  mainContent: {
    boxShadow: "0px -1px 8px rgba(60,60,60, 0.1)",
    display: "flex",
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
    overflow: "auto",
  },
  tableNavButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  toolbarButton: {
    width: 180,
    fontWeight: "bolder" as "bolder",
    fontSize: "0.8rem",
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ":hover": {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ":active": {
      color: ColorPalette.DARK_GREY,
    },
  },
  warningIcon: {
    backgroundColor: ColorPalette.WARNING_RED_30PERCENT,
    width: 40,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  successIcon: {
    backgroundColor: ColorPalette.PRIMARY_BLUE_TRANSPARENT,
    width: 40,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconPlaceholderContainer: {
    borderBottom: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    width: 40,
    height: "100%",
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    selectedAssociation: state.sessionManager.selectedAssociation,
    navMenuAccess: state.sessionManager.navMenuAccess,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ProfileImports))
