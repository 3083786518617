import { Iam } from '../models'
import { PolicySchema } from '../models/policy'
import { UiCategoryEnum, SystemFeaturesEnum, SystemOperationsEnum } from '../types'

const { LOGIN, PEOPLE, EMPLOYEES, CANDIDATES, TRASH, DOCUMENT_VALIDITY, REPORTING } = UiCategoryEnum
const { reporting, cohorts } = SystemFeaturesEnum
const { all, listTrashInCohort, listEmployeesInCohort, listCandidatesInCohort, viewDocumentValidity } =
  SystemOperationsEnum

const screenNamePermissionConfig = {
  [LOGIN as UiCategoryEnum]: [
    {
      targetScreen: PEOPLE,
      requiredPermissions: [
        {
          feature: cohorts,
          operations: [all, listTrashInCohort, listEmployeesInCohort, listCandidatesInCohort],
          resources: [],
        },
      ],
      nestedScreens: [
        {
          targetScreen: EMPLOYEES,
          requiredPermissions: [
            {
              feature: cohorts,
              operations: [all, listEmployeesInCohort],
              resources: [],
            },
          ],
        },
        {
          targetScreen: CANDIDATES,
          requiredPermissions: [
            {
              feature: cohorts,
              operations: [all, listCandidatesInCohort],
              resources: [],
            },
          ],
        },
        {
          targetScreen: TRASH,
          requiredPermissions: [
            {
              feature: cohorts,
              operations: [all, listTrashInCohort],
              resources: [],
            },
          ],
        },
      ],
    },
    {
      targetScreen: REPORTING,
      requiredPermissions: [
        {
          feature: reporting,
          operations: [all, viewDocumentValidity],
          resources: [],
        },
      ],
      nestedScreens: [
        {
          targetScreen: DOCUMENT_VALIDITY,
          requiredPermissions: [
            {
              feature: reporting,
              operations: [all, viewDocumentValidity],
              resources: [],
            },
          ],
        },
      ],
    },
  ],
}

export class NavigationRouteProvider {
  static getRoute(
    iamEntity: Iam,
    sourceScreen: UiCategoryEnum,
  ): { screen: UiCategoryEnum; section?: UiCategoryEnum } | null {
    for (const config of screenNamePermissionConfig[sourceScreen]) {
      for (const permission of config.requiredPermissions) {
        const hasAccess = NavigationRouteProvider.checkPermissionMatch(iamEntity, permission)
        if (!hasAccess) {
          continue
        }
        if (!config.nestedScreens || !config.nestedScreens.length) {
          return { screen: config.targetScreen.split(' ').join('') as UiCategoryEnum }
        }
        for (const nestedConfig of config.nestedScreens) {
          for (const nestedPermission of nestedConfig.requiredPermissions) {
            const hasNestedAccess = NavigationRouteProvider.checkPermissionMatch(iamEntity, nestedPermission)
            if (!hasNestedAccess) {
              continue
            }
            return {
              screen: config.targetScreen.split(' ').join('') as UiCategoryEnum,
              section: nestedConfig.targetScreen.split(' ').join('') as UiCategoryEnum,
            }
          }
        }
      }
    }
    return null
  }

  private static checkPermissionMatch(iamEntity: Iam, permission: PolicySchema) {
    let hasOperationMatch = false
    if (!permission.operations.length) {
      hasOperationMatch = true
    } else {
      for (const operation of permission.operations) {
        if (iamEntity.checkAccess(permission.feature, operation as SystemOperationsEnum)) {
          hasOperationMatch = true
          break
        }
      }
    }

    let hasResourceMatch = false
    if (!permission.resources.length) {
      hasResourceMatch = true
    } else {
      for (const resource of permission.resources) {
        if (iamEntity.checkAccess(permission.feature, undefined, resource)) {
          hasResourceMatch = true
          break
        }
      }
    }
    return hasOperationMatch && hasResourceMatch
  }
}
