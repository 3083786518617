import { UiCategoryEnum } from "../types"
const {
  BULK_ACTIONS,
  BULK_DISCIPLINE_FORMS,
  DISCIPLINE,
  EXPORT,
  EXPORT_DOC_VALIDITY_DATA,
  EXPORT_DOCS,
  EXPORT_EMPLOYEE_CARDS,
  EXPORT_PROFILES,
  IMPORT,
  REPORTING,
  FILES_AND_PERFORMANCE_DATA_IMPORTS,
  PROFILE_IMPORTS,
  UNDER_PERFORMANCE,
  TERMINATE,
  INTEGRATIONS,
  ADAGIN,
  AGRIGISTICS,
  PAYSPACE,
  ADAGIN_SYNC,
  AGRIGISTICS_SYNC,
  PAYSPACE_SYNC,
  ADAGIN_LOGS,
  AGRIGISTICS_LOGS,
  PAYSPACE_LOGS,
} = UiCategoryEnum

export const navButtonsWithCustomActions: string[] = [
  BULK_ACTIONS,
  BULK_DISCIPLINE_FORMS,
  DISCIPLINE,
  UNDER_PERFORMANCE,
  EXPORT,
  IMPORT,
  EXPORT_DOC_VALIDITY_DATA,
  EXPORT_DOCS,
  EXPORT_EMPLOYEE_CARDS,
  EXPORT_PROFILES,
  REPORTING,
  FILES_AND_PERFORMANCE_DATA_IMPORTS,
  PROFILE_IMPORTS,
  TERMINATE,
  INTEGRATIONS,
  ADAGIN,
  AGRIGISTICS,
  PAYSPACE,
  ADAGIN_SYNC,
  AGRIGISTICS_SYNC,
  PAYSPACE_SYNC,
  ADAGIN_LOGS,
  AGRIGISTICS_LOGS,
  PAYSPACE_LOGS,
]
