import React, { LegacyRef, createRef, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { GridSize } from '@material-ui/core/Grid'
import { mdiRefresh } from '@mdi/js'
import { cloneDeep } from 'lodash'
import Radium from 'radium'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { titleCase, toLowerCaseCustom, toUpperCaseCustom } from '../../utils'
import { DataCaptureMode, DocumentCategories } from '../../config'
import { CohortName, EmploymentStatus, UiCategoryEnum, TrashStatus } from '../../types'
import completedScreens from '../completedScreens'
import { ProcessModes } from '../../config/processModes'
import DataTable, { CustomFilterState } from '../../components/Tables/DataTable/DataTable'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import { ProfileNavMenu } from '../../components/GeneralUI/ProfileNavMenu/ProfileNavMenu'
import { PeopleFlowCombinedReducer } from '../../store'
import { SessionService, ProfileService, ConfigService } from '../../services'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from '../../repositories'
import { Profile } from '../../models/profile/profile'
import { GeneralErrorCodesEnum, IamServiceErrorCodesEnum } from '../../enums'

const { MissingProfile } = GeneralErrorCodesEnum
const { Unauthorised } = IamServiceErrorCodesEnum

const sessionService = new SessionService()
const configService = new ConfigService()
const profileService = new ProfileService()

interface ProfilesProps extends RouteComponentProps {}

type ProfileCore = { id: string; name: string; surname: string; idPassport: string }
type ColumnConfig = { id: string; label: string; sizeFactor: number }
type RouteState = {
  prevCustomFilterState: CustomFilterState[]
}
const profileViewingOptionsRef: LegacyRef<ProfileNavMenu> = createRef()
const sectionHeaderRef: LegacyRef<any> = createRef()
const primaryTableRef: LegacyRef<DataTable> = createRef()

const Profiles = (props: ProfilesProps) => {
  const initialModalState = {
    loadingModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    warningModalOpen: false,
    loadingModalMessage: '',
    loadingModalAuxMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [sideMenuComponents, setSideMenuComponents] = useState(<div />)
  const [sideMenuVisible, setSideMenuVisible] = useState(false)
  const [profileData, setProfileData] = useState<any[]>([])
  const [tableWidth, setTableWith] = useState<GridSize>(11)
  const [selectedRowItemId, setSelectedRowItemId] = useState('')
  const [searchString, setSearchString] = useState('')
  const [customProfileFilterConfig, setCustomProfileFilterConfig] = useState<{ key: string; label: string }[]>([
    {
      key: 'idPassport',
      label: 'ID/Passport',
    },
  ])
  const [tableReloadTrigger, setTableReloadTrigger] = useState(0)

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const selectedEmploymentStatus = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedEmploymentStatus,
  )
  const currentScreen = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentScreen)
  const navMenuAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.navMenuAccess)
  const tableColumnConfigRef = useRef<ColumnConfig[]>([])

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })

  const initialTableCustomFilterState = (props.location.state as RouteState)?.prevCustomFilterState

  useEffect(() => {
    if (!selectedAssociation) {
      return
    }
    setSideMenuVisible(false)
    setSearchString('')
    setProfileData([])
    initialiseCustomTableColumnConfig()
    setCustomProfileFilterConfig(associationRepo.getProfileFilterConfig(selectedAssociation))
    updateState({
      profilePic: '',
      currentSection: UiCategoryEnum.PEOPLE,
      currentScreen: `${selectedEmploymentStatus}S`,
    })
  }, [selectedAssociation])

  // useEffect(() => {
  //   loadTable()
  // }, [tableReloadTrigger])

  useEffect(() => {
    downloadMissingProfiles(tableColumnConfigRef.current, customProfileFilterConfig)
  }, [selectedAssociation, selectedCohort, customProfileFilterConfig])

  const closeModals = () => {
    setModalState(initialModalState)
  }

  const initialiseCustomTableColumnConfig = () => {
    const customProfileTableColumnConfig = associationRepo.getProfileTableColumnConfig(selectedAssociation)
    const columnConfig = cloneDeep(customProfileTableColumnConfig)
    columnConfig.forEach((config: any) => {
      config.id = config.key
      delete config.key
    })
    // @ts-ignore
    tableColumnConfigRef.current = [...columnConfig.slice(0, 5)]
  }

  // const loadTable = () => {
  //   if (primaryTableRef && primaryTableRef.current) {
  //     primaryTableRef.current.reload()
  //   }
  // }

  const downloadMissingProfiles = async (
    tableColumnConfig: ColumnConfig[],
    profileFilterConfig: { key: string; label: string }[],
  ) => {
    try {
      const loadingModalMessage = 'Fetching profiles...'
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage,
        loadingModalAuxMessage: '',
      })
      await profileService.downloadMissingAssociationProfiles(
        associationRepo,
        profileRepo,
        { username: idPassport, password },
        selectedAssociation,
        (progress: string) =>
          setModalState({
            ...initialModalState,
            loadingModalOpen: true,
            loadingModalMessage,
            loadingModalAuxMessage: progress,
          }),
      )
      updateProfileRenderData(
        profileRepo,
        [selectedCohort],
        [selectedEmploymentStatus],
        tableColumnConfig,
        profileFilterConfig,
      )
    } catch (error: any) {
      errorHandler(error)
    }
    closeModals()
  }

  const refreshAllProfiles = async (
    tableColumnConfig: ColumnConfig[],
    profileFilterConfig: { key: string; label: string }[],
  ) => {
    try {
      const loadingModalMessage = 'Downloading profiles'
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage,
        loadingModalAuxMessage: '',
      })

      await profileService.downloadAllAssociationProfiles(
        sessionService,
        associationRepo,
        profileRepo,
        { username: idPassport, password },
        selectedAssociation,
        selectedCohort,
        (progress: string) => {
          setModalState({
            ...initialModalState,
            loadingModalOpen: true,
            loadingModalMessage,
            loadingModalAuxMessage: progress,
          })
        },
      )
      updateProfileRenderData(
        profileRepo,
        [selectedCohort],
        [selectedEmploymentStatus],
        tableColumnConfig,
        profileFilterConfig,
      )
    } catch (error: any) {
      errorHandler(error)
    }
    closeModals()
  }

  const updateProfileRenderData = (
    profileRepo: ProfileRepository,
    targetCohorts: CohortName[],
    targetEmploymentStatus: string[],
    tableColumnConfig: ColumnConfig[],
    profileFilterConfig: { key: string; label: string }[],
  ) => {
    const allAssociationProfileEntities = profileRepo.getAllProfiles()
    if (allAssociationProfileEntities) {
      const allProfilesToRender = profileRepo.getTargetProfiles(targetCohorts, targetEmploymentStatus, [
        TrashStatus.NONE,
      ])
      let updatedProfileData = prepareProfileDataForDisplay(
        Object.values(allProfilesToRender),
        tableColumnConfig,
        profileFilterConfig,
      )
      setProfileData(updatedProfileData)
      setTableReloadTrigger(Date.now())
    }
  }

  const prepareProfileDataForDisplay = (
    profilesEntitiesToRender: Profile[],
    tableColumnConfig: ColumnConfig[],
    customProfileFilterConfig: { key: string; label: string }[],
  ): any[] => {
    return profilesEntitiesToRender.map((profileEntity: any) => {
      const profilePk = profileEntity.getPk()
      let returnData = { id: profilePk } as Record<string, string>
      customProfileFilterConfig.forEach((configItem) => {
        returnData[configItem.key] = profileEntity.getGeneralDataValue(configItem.key, '-')
      })
      tableColumnConfig.forEach((configItem) => {
        let value = profileEntity.getGeneralDataValue(configItem.id, '-')
        if (Array.isArray(value)) {
          value = value.join('; ')
        }
        returnData[configItem.id] = toUpperCaseCustom(value)
      })
      return returnData
    })
  }

  const searchHandler = (e: React.ChangeEvent<{ value: string }>) => {
    const newSearchString = e.target.value
    setSearchString(newSearchString)
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.search(newSearchString)
  }

  const selectProfile = async (rowItem: Record<string, any>) => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Refreshing profile data...',
      })
      sectionHeaderRef.current.disableSearch()
      let targetProfileData = profileData.find((x: ProfileCore) => x.id === rowItem.id)
      if (!targetProfileData) {
        return
      }
      const { id } = targetProfileData
      const profileEntity = profileRepo.getProfileEntity(id)
      if (!profileEntity) {
        throw { code: MissingProfile }
      }
      const { name, surname, idPassport } = profileEntity.getGeneralData()
      const profilePk = profileEntity.getPk()
      await profileService.downloadProfiles(profileRepo, selectedAssociation, [profilePk], {
        username: idPassport,
        password,
      })
      const refreshedProfileEntity = profileRepo.getProfileEntity(id)
      if (!refreshedProfileEntity) {
        throw { code: MissingProfile }
      }
      const profilePic = await profileService.getProfilePic(profilePk)
      const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
      if (!iamEntity) {
        throw { code: Unauthorised }
      }
      const profileNavMenuAccess = configService.generateProfileNavMenuConfig(
        iamEntity,
        selectedEmploymentStatus as EmploymentStatus,
      )
      updateState({
        profilePic,
        profile: refreshedProfileEntity,
        isReadOnly: false,
        dataCaptureMode: DataCaptureMode.STANDARD,
        activeDocumentPortalSection: DocumentCategories.RECRUITMENT_AND_ONBOARDING,
        processMode:
          selectedEmploymentStatus === 'EMPLOYEE' ? ProcessModes.GENERAL_EMPLOYEE : ProcessModes.GENERAL_CANDIDATE,
        sourceData: cloneDeep(refreshedProfileEntity.getGeneralData()),
        profileNavMenuAccess,
      })
      const sideMenuComponents = (
        <ProfileNavMenu
          ref={profileViewingOptionsRef}
          profilePic={profilePic}
          name={`${name} ${surname}`}
          idPassport={idPassport}
          onClick={(screen) => sideMenuHandler(screen)}
          currentScreen={currentScreen}
          accessFlags={profileNavMenuAccess}
          key={idPassport}
        />
      )
      setSelectedRowItemId(id)
      setTableWith(9)
      setSideMenuComponents(sideMenuComponents)
      setSideMenuVisible(true)
      closeModals()
    } catch (error) {
      errorHandler(error)
    }
  }

  const sideMenuHandler = (screen: UiCategoryEnum) => {
    if (completedScreens.includes(screen)) {
      updateState({ currentScreen: screen, previousScreen: UiCategoryEnum.PROFILES })
      // pass current custom filter state to the next screen
      props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`, {
        prevCustomFilterState: primaryTableRef.current?.state.customFilterState,
      })
    } else {
      props.history.push('/comingsoon')
    }
  }

  const errorHandler = (error: any): void => {
    let header = 'Warning'
    let warningModalMessage = ''

    switch (error.code) {
      case Unauthorised:
        header = 'Unauthorised'
        warningModalMessage = 'You do not have permission to perform this action.'
        break
      case MissingProfile:
        warningModalMessage = 'There was a problem accessing this profile. Refresh the browser and try again.'
        break
      default:
        if ('message' in error) {
          warningModalMessage = `The following error message was returned: \n\n 
            ${error.message}.\n\n Contact tech support to report the issue.`
        } else {
          warningModalMessage =
            'We encountered a problem. Refresh the page and try again. If you are still unsuccessful, contact tech support for assistance'
        }
        break
    }
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      warningModalHeader: header,
      warningModalMessage,
    })
  }

  const { hasEmployeesAccess, hasCandidatesAccess } = navMenuAccess
  const { loadingModalOpen, loadingModalMessage, loadingModalAuxMessage } = modalState

  let toolbarActionButtons: ActionButtonType[] = [
    {
      iconPath: mdiRefresh,
      iconColor: ColorPalette.PRIMARY_BLUE,
      onClick: () => refreshAllProfiles(tableColumnConfigRef.current, customProfileFilterConfig),
      label: 'RELOAD',
      title: 'Clear cache and refetch data',
    },
  ]

  return (
    <div style={{ ...styles.container }}>
      <NavBar
        match={props.match}
        location={props.location}
        history={props.history}
        primaryTableRef={primaryTableRef} // destined for SubMenu via prop drilling
      />
      <SectionHeader
        ref={sectionHeaderRef}
        style={styles.sectionHeader}
        searchString={searchString}
        textHandler={searchHandler}
        onClick={() => {
          setSideMenuVisible(false)
          setTableWith(11)
        }}
        key={`sectionHeader_${selectedAssociation}`}>
        {titleCase(`${currentScreen}`)}
      </SectionHeader>
      <div style={styles.contentContainer}>
        <SideMenu visible={sideMenuVisible} menuComponents={sideMenuComponents} />
        <div
          style={{
            ...styles.rightSide,
            paddingInline: sideMenuVisible ? 'max(2em, 2%)' : 'min(3em, 3%)',
          }}>
          <Toolbar actionButtons={toolbarActionButtons} key={`actionToolbar_${toolbarActionButtons.length}`} />
          <div style={styles.rightSideContent}>
            <DataTable
              ref={primaryTableRef}
              tableData={profileData}
              columnConfig={tableColumnConfigRef.current}
              tableWidth={tableWidth}
              filterState={{}}
              onRowClick={(rowData: Record<string, any>) => selectProfile(rowData)}
              selectedRowItemId={selectedRowItemId}
              customFilterConfig={customProfileFilterConfig}
              customFilterState={initialTableCustomFilterState}
              selectionEnabled={true}
              key={`dataTable_${selectedAssociation}_${tableReloadTrigger}_${tableColumnConfigRef.current.length}`}
            />
          </div>
        </div>
      </div>
      <AlertModalOneButton
        // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
        open={
          (!hasEmployeesAccess && selectedEmploymentStatus === EmploymentStatus.EMPLOYEE) ||
          (!hasCandidatesAccess && selectedEmploymentStatus === EmploymentStatus.CANDIDATE)
        }
        header="Not Authorised"
        body="You don't have permission to view employee/candidate info."
        buttonLabel="Ok"
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <AlertModalOneButton
        open={modalState.warningModalOpen}
        header={modalState.warningModalHeader}
        body={modalState.warningModalMessage}
        buttonLabel={'Ok'}
        onClick={() => closeModals()}
      />
      <LoadingModal
        open={loadingModalOpen}
        loadingMessage={loadingModalMessage}
        auxilliaryMessage={loadingModalAuxMessage}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 0.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}

export default Radium(Profiles)
