import { UnixMsString } from './common'
import { FieldConfigKey, FieldConfigLabel } from './fieldConfigTypes'

export type CsvColumnConfig = { key: FieldConfigKey; label: FieldConfigLabel; isCompulsory: boolean }
export type ImportId = UnixMsString
export enum ImportActionEnum {
  ADHOC = 'ADHOC',
  TERMINATE = 'TERMINATE',
  EMPLOY = 'EMPLOY',
}

export type ImportPlanEvaluationItemSchema = {
  rowId: string
  label: string
  willBeApplied: boolean
  message?: string
  pk?: string
  before?: any
  after?: any
}

export type ImportResultEvaluationItemSchema = {
  rowId: string
  label: string
  message: string
  wasApplied: boolean
  unixMs: UnixMsString
  pk?: string
  before?: any
  after?: any
}

export enum PlanWarningReasonEnum {
  DOESNT_MATCH_SELECTOR_ITEMS = 'DOESNT_MATCH_SELECTOR_ITEMS',
  CANNOT_BE_UPDATED = 'CANNOT_BE_UPDATED',
  INVALID_COHORTS = 'INVALID_COHORTS',
  INVALID_EMPLOYMENT_STATUS = 'INVALID_EMPLOYMENT_STATUS',
  INVALID_TRASH_STATUS = 'INVALID_TRASH_STATUS',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_TERMINATION_REASON = 'INVALID_TERMINATION_REASON',
  MISSING_FIELD = 'MISSING_FIELD',
  PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
  INVALID_FIELD = 'INVALID_FIELD',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export enum ImportProgressEnum {
  INITIALISING = 'INITIALISING',
  REVIEW_PLAN = 'REVIEW_PLAN',
  COMPLETE = 'COMPLETE',
}
