import { Component } from 'react'
import { ScrollView } from '@cantonjs/react-scroll-view'

import { ColorPalette } from '../../config'
import { UserService } from '../../services'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import TextInputLabelled from '../BaseComponents/Text/TextInputLabelled'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { removeUnderScores } from '../../utils'
import { UserProfileSchema } from '../../models'
import { CognitoMessageDeliveryMedium, RoleName, Username } from '../../types'

const { EMAIL, SMS } = CognitoMessageDeliveryMedium

interface UserCreationModalProps {
  userData: UserProfileSchema | null
  accountRoleNames: string[]
  isUserCreation: boolean
  isUserConfirmed: boolean
  isExistingUserAddition: boolean
  onSave: (userData: UserProfileSchema) => void
  dismiss: () => void
}

interface UserCreationModalState {
  userData: UserProfileSchema
  allRoleNames: string[]
  messageDeliveryMediumModalOpen: boolean
}

class UserDetailsModal extends Component<UserCreationModalProps, UserCreationModalState> {
  state: UserCreationModalState = {
    userData: UserService.createEmptyUser(),
    allRoleNames: [],
    messageDeliveryMediumModalOpen: false,
  }

  componentDidMount() {
    const { userData } = this.props
    if (userData) {
      this.setState({ userData })
    }
  }

  buildForm() {
    const { userData, accountRoleNames, isUserCreation, isExistingUserAddition } = this.props
    console.log('accountRoleNames: ', accountRoleNames)
    const allRoleNames = accountRoleNames.map((role) => removeUnderScores(role))
    let modalConfig = [
      { compType: 'text', key: 'username', label: 'USERNAME', disabled: !isUserCreation },
      { compType: 'text', key: 'name', label: 'FIRST NAME' },
      { compType: 'text', key: 'surname', label: 'SURNAME' },
      { compType: 'text', key: 'email', label: 'EMAIL' },
      { compType: 'text', key: 'mobile', label: 'MOBILE' },
      {
        compType: 'checkbox',
        key: 'roleNames',
        label: 'ROLES',
        selectorItems: allRoleNames,
        defaultValues: userData?.roleNames,
      },
    ]
    if (isExistingUserAddition) {
      modalConfig = [
        { compType: 'text', key: 'username', label: 'USERNAME' },
        {
          compType: 'checkbox',
          key: 'roleNames',
          label: 'ROLES',
          selectorItems: allRoleNames,
          defaultValues: [],
        },
      ]
    }
    return modalConfig.map((config) => {
      const { key, label, compType, selectorItems = [], defaultValues = [], disabled = false } = config
      if (compType === 'text') {
        return (
          <TextInputLabelled
            containerStyle={{ width: '100%', marginTop: 10 }}
            inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
            label={label}
            //@ts-ignore
            value={this.state.userData[key]}
            disabled={disabled}
            highlightEmpty={true}
            multiline={false}
            textHandler={(value) => {
              this.dataHandler(key, value)
            }}
          />
        )
      } else {
        return (
          <div style={styles.checkboxContainer}>
            <CheckboxTabLabelled
              style={{ width: '96%' }}
              itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
              label={label}
              //@ts-ignore
              values={this.state.userData[key]}
              selectorItems={selectorItems}
              defaultValues={defaultValues}
              highlightEmpty={true}
              maxItems={10}
              selectionHandler={(values) => this.dataHandler(key, values)}
            />
          </div>
        )
      }
    })
  }

  dataHandler(key: string, value: string | string[]): void {
    let { userData } = this.state
    //@ts-ignore
    userData[key] = value
    this.setState({ userData })
  }

  getHeaderLabel(userCreation: boolean) {
    if (userCreation) {
      return 'Add User'
    }
    return 'Update User'
  }

  getButtonLabel(userConfirmed: boolean, existingUserAddition: boolean, userCreation: boolean) {
    if (!userConfirmed && !existingUserAddition && !userCreation) {
      return 'Save and re-send signup invite'
    }
    if (existingUserAddition) {
      return 'Add'
    }
    if (userCreation) {
      return 'Create'
    }
    return 'Save'
  }

  render() {
    const { isUserCreation, isUserConfirmed, isExistingUserAddition } = this.props
    const { userData } = this.state
    const formFields = this.buildForm()

    let actionButton = (
      <ButtonBlue style={{ width: '98%', marginTop: 30 }} onClick={() => this.props.onSave(userData)}>
        {this.getButtonLabel(isUserConfirmed, isExistingUserAddition, isUserCreation)}
      </ButtonBlue>
    )
    if (!isUserConfirmed && !isExistingUserAddition && !isUserCreation) {
      actionButton = (
        <ButtonGrey style={{ width: '98%', marginTop: 30 }} onClick={() => this.props.onSave(userData)}>
          {this.getButtonLabel(isUserConfirmed, isExistingUserAddition, isUserCreation)}
        </ButtonGrey>
      )
    }
    return (
      <div style={{ ...styles.screenContainer }}>
        <button style={styles.backgroundOverlay} onClick={() => this.props.dismiss()} />
        <div style={{ ...styles.cardContainer }}>
          <h1 style={{ ...styles.textStyle }}>{this.getHeaderLabel(isUserCreation)}</h1>
          <ScrollView
            style={{ ...styles.scrollContainer }}
            contentContainerStyle={{ ...styles.scrollContentContainer }}>
            {formFields}
            <div style={styles.buttonContainer}>{actionButton}</div>
          </ScrollView>
        </div>
      </div>
    )
  }
}

let styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 100000,
    overflow: 'hidden',
  },
  backgroundOverlay: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: 'transparent',
    zIndex: 10000,
    outline: 0,
    border: 'none',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: 50,
    left: window.innerWidth * 0.22,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 30, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: window.innerWidth * 0.56,
    maxHeight: window.innerHeight * 0.95,
    paddingTop: 40,
    paddingBottom: 20,
    borderRadius: 8,
  },
  scrollContainer: {
    width: '100%',
    minHeight: '100%',
    maxHeight: window.innerHeight * 0.85,
    padding: 5,
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  scrollContentContainer: {
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: '3%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
  },
  checkboxContainer: {
    marginTop: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
  },
  textStyle: {
    fontWeight: 'bold',
    marginTop: 0,
    marginBottom: 20,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
}

export default UserDetailsModal
