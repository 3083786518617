import Papa from "papaparse"
import Storage from "@aws-amplify/storage"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import { cloneDeep } from "lodash"
import { base64ToBlob } from "base64-blob"

import { formatDateAndTime } from "./calendar"
import { getRemoteFile } from "../providers"
import { FileEncodingEnum, FileFormat } from "../types"

export const packageDataAndDownloadFile = (data: string, fileName: string, fileType: string = "text/csv") => {
  const blob = new Blob([data], { type: fileType })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.setAttribute("href", url)
  a.setAttribute("download", fileName)
  a.click()
}

export const packageAndUploadFile = async (
  fileData: any[],
  fileName: string,
  filePath: string,
  fileFormat: FileFormat,
) => {
  try {
    const getMimeType = (fileFormat: FileFormat) => {
      const { json, csv, txt, xlsx } = FileFormat
      const { application_json, text_csv, text_plain, application_xml } = FileEncodingEnum
      const fileFormatMimeTypeMap = {
        [json]: application_json,
        [csv]: text_csv,
        [txt]: text_plain,
        [xlsx]: application_xml,
      }
      return fileFormatMimeTypeMap[fileFormat]
    }

    const mimeType = getMimeType(fileFormat)
    const file = new File(fileData, fileName, { type: mimeType })
    await writeLocalFileToRemoteStorage(`${filePath}/${fileName}`, file, mimeType)
  } catch (error) {
    console.log("error: ", error)
  }
}

export const csvToJson = async (csvFile: File) => {
  return new Promise((resolve, reject) => {
    const config = {
      header: true,
      skipEmptyLines: true,
      complete: (result: any) => resolve(result.data),
      error: (error: any) => {
        console.error("csvToJson error: ", error)
        throw error.message
      },
    }
    Papa.parse(csvFile, config)
  })
}

export const jsonToCsv = (jsonData: any, config?: Record<string, any>) => {
  return Papa.unparse(jsonData, config)
}

export function filePicker(event: React.ChangeEvent<{ value: string }>): Record<string, any>[] {
  const filelist = (event?.target as HTMLInputElement)?.files
  if (filelist === null || filelist.length === 0) {
    throw { code: "UnknownError" }
  }

  return Array.from(filelist).map((fileitem: File) => {
    const url = URL.createObjectURL(fileitem)
    return {
      file: fileitem,
      name: fileitem.name,
      type: fileitem.type,
      url,
    }
  })
}

export function normaliseS3Path(path: string): string {
  if (path.startsWith("public")) {
    path = path.replace("public/", "") // Storage API adds public/ it by default
  }
  return path
}

export async function writeLocalFileToRemoteStorage(
  path: string,
  file: any,
  contentType: string,
  encoding?: string,
): Promise<any> {
  path = normaliseS3Path(path)
  if (encoding === "base64") {
    file = await base64ToBlob(file)
  }
  return await Storage.put(path, file, { contentType })
}

export async function writeBlobToRemoteStorage(path: string, blob: any, contentType: string): Promise<any> {
  path = normaliseS3Path(path)
  return await Storage.put(path, blob, { contentType })
}

export async function writeRemoteFilesToLocalStorage(
  docsToDownload: { docPath: string; filename: string }[],
): Promise<any> {
  let zip = new JSZip()
  for (let i = 0; i < docsToDownload.length; i++) {
    const { filename, docPath } = docsToDownload[i]
    const file = await getRemoteFile(docPath, { download: true })
    zip.file(filename, file.Body, { binary: true })
  }

  const blob = await zip.generateAsync({ type: "blob" })
  const { day, month, year, minutes, hour } = formatDateAndTime(new Date())
  saveAs(blob, `PeopleFlow Files - ${day} ${month} ${year}, ${hour}-${minutes}.zip`)
}

export async function writeSingleRemoteFileToLocalStorage(docPath: string) {
  await getRemoteFile(docPath, { download: true })
}

export async function writeDataToCsvFile(filePath: string, csvHeaderColumns: string[], csvRowData: string[][]) {
  const dataRowArray = cloneDeep(csvRowData)
  dataRowArray.unshift(csvHeaderColumns)
  const csvDataString = generateCsvRows(dataRowArray)
  const csvBlob = new Blob([csvDataString], { type: "text/csv" })
  await writeBlobToRemoteStorage(filePath, csvBlob, "text/csv")
}

function generateCsvRow(data: string[]): string {
  let csvRow: string[] = []
  csvRow.push(data.join(","))
  // returning the array joining with new line
  return csvRow.join("\n")
}

function generateCsvRows(data: string[][]): string {
  let csvRows: string[] = []
  data.forEach((item: any) => {
    const row = generateCsvRow(item)
    csvRows.push(row)
  })
  // returning the array joining with new line
  return csvRows.join("\n")
}
