import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'
import { ProfileNavMenuAccess, UiCategoryEnum } from '../../../types'

import React, { Component } from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import Person from '@material-ui/icons/Person'
import FaceIcon from '@material-ui/icons/Face'
import DescriptionIcon from '@material-ui/icons/Description'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import ViewListIcon from '@material-ui/icons/ViewList'
import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import profileAvatar from '../../../assets/profileAvatar.png'
// import Spinner from 'react-spinkit'

interface ProfileNavSideProps {
  accessFlags: ProfileNavMenuAccess
  profilePic: string
  idPassport: string
  currentScreen: UiCategoryEnum
  name: string
  restrictedAuditorRoleAccess?: boolean
  onClick: (screen: UiCategoryEnum) => void
}

interface ProfileNavSideState {
  profilePic: string
  profilePicValid: boolean
}

export class ProfileNavMenu extends Component<ProfileNavSideProps, ProfileNavSideState> {
  state = {
    profilePic: '',
    profilePicValid: true,
  }

  componentDidMount() {
    this.initialise()
  }

  async initialise() {
    const { profilePic } = this.props
    this.setState({ profilePic })
  }

  getTabBackgroundColor(selectedScreen: UiCategoryEnum, screen: UiCategoryEnum) {
    if (selectedScreen === screen) {
      return ColorPalette.BUTTON_ACTIVE_GREY
    } else {
      return 'transparent'
    }
  }

  generateSideMenuOptions(accessFlags: ProfileNavMenuAccess, currentScreen: UiCategoryEnum) {
    const {
      hasDataManagerAccess,
      hasFaceshotAccess,
      hasDocPortalAccess,
      hasPerformanceAccess,
      hasDisciplineAccess,
      hasLogsAccess,
    } = accessFlags

    let dataManagerOption = null
    let faceShotOption = null
    let documentsOption = null
    let performanceOption = null
    let disciplineOption = null
    let logsOption = null
    if (hasDataManagerAccess) {
      dataManagerOption = (
        <ButtonGeneric
          style={{
            ...styles.button,
            backgroundColor: this.getTabBackgroundColor(currentScreen, UiCategoryEnum.DATA_MANAGER),
          }}
          iconBefore={<Person style={styles.buttonIcon} />}
          label={'PROFILE'}
          onClick={() => this.props.onClick(UiCategoryEnum.DATA_MANAGER)}
        />
      )
    }
    if (hasFaceshotAccess) {
      faceShotOption = (
        <ButtonGeneric
          style={{
            ...styles.button,
            backgroundColor: this.getTabBackgroundColor(currentScreen, UiCategoryEnum.FACE_SHOTS),
          }}
          iconBefore={<FaceIcon style={styles.buttonIcon} />}
          label={'FACESHOTS'}
          onClick={() => this.props.onClick(UiCategoryEnum.FACE_SHOTS)}
        />
      )
    }
    if (hasDocPortalAccess) {
      documentsOption = (
        <ButtonGeneric
          style={{
            ...styles.button,
            backgroundColor: this.getTabBackgroundColor(currentScreen, UiCategoryEnum.DOCUMENTS),
          }}
          iconBefore={<DescriptionIcon style={styles.buttonIcon} />}
          label={'DOCS'}
          onClick={() => this.props.onClick(UiCategoryEnum.DOCUMENTS)}
        />
      )
    }
    if (hasPerformanceAccess) {
      performanceOption = (
        <ButtonGeneric
          style={{
            ...styles.button,
            backgroundColor: this.getTabBackgroundColor(currentScreen, UiCategoryEnum.PERFORMANCE),
          }}
          iconBefore={<DonutLargeIcon style={styles.buttonIcon} />}
          label={'PERFORMANCE'}
          onClick={() => this.props.onClick(UiCategoryEnum.PERFORMANCE)}
        />
      )
    }
    if (hasDisciplineAccess) {
      // disciplineOption = (
      //   <ButtonGeneric
      //     style={{
      //       ...styles.button,
      //       backgroundColor:
      //         this.props.currentScreen === UiCategoryEnum.DISCIPLINE ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
      //     }}
      //     iconBefore={<ErrorIcon style={styles.buttonIcon} />}
      //     label={'DISCIPLINE'}
      //     onClick={() => this.props.onClick(UiCategoryEnum.DISCIPLINE)}
      //   />
      // )
    }
    if (hasLogsAccess) {
      logsOption = (
        <ButtonGeneric
          style={{
            ...styles.button,
            backgroundColor: this.getTabBackgroundColor(currentScreen, UiCategoryEnum.LOGS),
          }}
          iconBefore={<ViewListIcon style={styles.buttonIcon} />}
          label={'CHANGE LOGS'}
          onClick={() => this.props.onClick(UiCategoryEnum.LOGS)}
        />
      )
    }
    return [dataManagerOption, faceShotOption, documentsOption, performanceOption, disciplineOption, logsOption]
  }

  render() {
    const { profilePicValid, profilePic } = this.state
    const { currentScreen, accessFlags } = this.props

    styles.profilePicDimensionContainer = {
      ...styles.profilePicDimensionContainer,
      width: window.innerHeight * (profilePicValid ? 0.135 : 0.125),
      height: window.innerHeight * (profilePicValid ? 0.135 : 0.125),
    }

    styles.profilePicStyle = {
      ...styles.profilePicStyle,
      width: window.innerHeight * (profilePicValid ? 0.135 : 0.125),
    }
    const sideMenuOptions = this.generateSideMenuOptions(accessFlags, currentScreen)

    return (
      <div style={styles.container}>
        <div style={styles.imageContainer}>
          <div style={styles.profilePicDimensionContainer}>
            {' '}
            <img
              style={styles.profilePicStyle}
              alt={'profile'}
              src={profilePicValid ? profilePic : profileAvatar}
              onError={() => this.setState({ profilePicValid: false })}
            />
          </div>
        </div>
        <p style={styles.nameStyle}>{toUpperCaseCustom(this.props.name)}</p>
        <p style={styles.idStyle}>{this.props.idPassport}</p>
        <div style={styles.dividerLine} />

        <ScrollView style={{ height: window.innerHeight * 0.42 }} contentContainerStyle={{ paddingBottom: 40 }}>
          {sideMenuOptions}
        </ScrollView>
      </div>
    )
  }
}

const styles: Record<string, React.CSSProperties | Record<string, React.CSSProperties>> = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    flex: 1,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
    marginTop: 25,
    alignSelf: 'center',
    width: window.innerHeight * 0.135,
    height: window.innerHeight * 0.135,
    borderRadius: window.innerHeight * 0.5,
  },
  profilePicDimensionContainer: {
    overflow: 'hidden',
    borderRadius: window.innerHeight * 0.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePicStyle: {
    height: 'auto',
  },
  nameStyle: {
    marginTop: 15,
    fontSize: '0.9rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
  },
  idStyle: {
    marginTop: -10,
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
  },
  dividerLine: {
    width: '86%',
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
}

export default Radium(ProfileNavMenu)
