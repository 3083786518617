export interface IErrorContent {
  header: string
  body: string
}

export function extractErrorCode(error: any): string {
  try {
    const errorCode = error.response.data.code
    return errorCode
  } catch (err) {}
  return ""
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message
  return String(error)
}
