import { AssociationId, CognitoMessageDeliveryMedium } from '../types/index'
import { UserProfileSchema } from '../models'
import { UsersRepository } from '../repositories'
import { getUsersForAssociation, createUser, updateUser, resendTempPassword } from '../providers/index'
import { addUnderScores, toLowerCaseCustom, toUpperCaseCustom } from '../utils'

export class UserService {
  async refreshAssociationUsers(
    selectedAssociation: AssociationId,
    userRepo: UsersRepository,
    token: string,
  ): Promise<{ accountRoleNames: string[] }> {
    const { users, accountRoleNames } = await getUsersForAssociation(selectedAssociation, token)
    userRepo.setAssociationUsers(users)
    return { accountRoleNames }
  }

  async upsertUser(
    userRepo: UsersRepository,
    association: AssociationId,
    userData: UserProfileSchema,
    token: string,
    isNewUser: boolean,
    messageDeliveryMediums?: CognitoMessageDeliveryMedium[],
  ) {
    try {
      const userForm = this.toUserProfileForm(userData, isNewUser)
      let newUser
      if (isNewUser) {
        newUser = await createUser(association, userForm, token, messageDeliveryMediums)
      } else {
        newUser = await updateUser(association, userForm, token)
      }
      userRepo.setAssociationUser(newUser)
    } catch (error) {
      throw error
    }
  }

  async resendTempPassword(username: string, token: string, messageDeliveryMediums?: CognitoMessageDeliveryMedium[]) {
    try {
      await resendTempPassword(username, token, messageDeliveryMediums)
    } catch (error) {
      throw error
    }
  }

  private toUserProfileForm(user: UserProfileSchema, isNewUser: boolean): UserProfileSchema {
    try {
      if (!user.roleNames?.length && isNewUser) {
        throw { code: 'EmptyRoleNames' }
      }
      let userProfileForm = {
        roleNames: user.roleNames?.map((role) => addUnderScores(role)) || [],
      } as UserProfileSchema

      if (user.username) {
        userProfileForm.username = toUpperCaseCustom(user.username)
      }
      if (user.name) {
        userProfileForm.name = toUpperCaseCustom(user.name)
      }
      if (user.surname) {
        userProfileForm.surname = toUpperCaseCustom(user.surname)
      }
      if (user.email) {
        userProfileForm.email = toLowerCaseCustom(user.email)
      }
      if (user.mobile) {
        userProfileForm.mobile = user.mobile
      }
      return userProfileForm
    } catch (error) {
      throw error
    }
  }

  static createEmptyUser(): UserProfileSchema {
    return {
      email: '',
      username: '',
      name: '',
      mobile: '',
      surname: '',
      roleNames: [],
    }
  }
}
