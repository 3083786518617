// import { cloneDeep } from 'lodash'

import { APP_VERSION } from '../cloud-config'
import {
  getTimeBasedDocRecords,
  // generateTerminationPlan,
  triggerDisciplineFormGeneration,
  exportProfileDataTemplate,
  // checkForTerminationPlanResults,
  // getRemoteFile,
} from '../providers'
import { SessionService } from './sessionService'
// import { CommonTs } from '../models/common-ts/commonts'
import { AuthCredentials, DocAuditReportResponse } from '../types'
// import { updateProfile } from '../providers/endpointDataProvider/employees'
// import { csvToJson } from '../utils'

export class ExportService {
  async exportRemoteData(
    selectedAssociation: any,
    selectedProfiles: string[],
    selectedExportTemplate: any,
    selectedExportFormat: string,
    emails: any,
    authCredentials: AuthCredentials,
  ): Promise<void> {
    const payload = {
      selectedEmployer: selectedAssociation,
      selectedProfiles,
      selectedExportTemplate,
      selectedExportFormat,
      emails,
      webVersion: APP_VERSION,
    }
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    exportProfileDataTemplate(payload, token)
  }

  async exportDocAuditReports(
    selectedAssociation: any,
    emails: any,
    cohorts: string[],
    authCredentials: AuthCredentials,
  ): Promise<DocAuditReportResponse> {
    const params = {
      emails,
      cohorts,
      ignoreJobType: true,
      flagUnpersisted: true,
    }

    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await getTimeBasedDocRecords(selectedAssociation, params, token)
  }

  async generateBulkDisciplineForms(
    selectedAssociation: string,
    selectedDate: Date,
    emails: string[],
    authCredentials: AuthCredentials,
  ): Promise<void> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await triggerDisciplineFormGeneration(selectedAssociation, selectedDate, emails, token)
    } catch (error) {
      throw error
    }
  }
}
