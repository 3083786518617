import React, { Component, createRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { GridSize } from '@material-ui/core/Grid'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { isEmpty, cloneDeep } from 'lodash'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import completedScreens from '../completedScreens'
import { ProcessModes } from '../../config/processModes'
import {
  toLowerCaseCustom,
  generateListOfChanges,
  getDataChanges,
  removeUnderScores,
  getConfiguredProcessInfo,
  extractNewFromDataChanges,
} from '../../utils'
import NavBar from '../../components/Navigation/NavBar'
import { ConfigService, ProfileService } from '../../services'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileNavMenu from '../../components/GeneralUI/ProfileNavMenu/ProfileNavMenu'
import DataFlowManager from '../../components/DataFlows/DataFlowManager'
import ConfirmChanges from '../../components/Modals/ConfirmChanges'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import { RouteComponentProps } from 'react-router'
import { PeopleFlowCombinedReducer } from '../../store'
import { DataCaptureMode } from '../../config'
import { DocConfig, Profile } from '../../models'
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from '../../repositories'
import {
  FieldConfigSchema,
  UiCategoryEnum,
  PeopleRouteState,
  EmploymentStatus,
  ProfileNavMenuAccess,
  FieldConfigItemSchema,
} from '../../types'
import { IDataFlow, ISectionConfigItem } from '../../interfaces'
import { IamServiceErrorCodesEnum } from '../../enums'

const { Unauthorised } = IamServiceErrorCodesEnum
const configService = new ConfigService()
const profileService = new ProfileService()
interface IProfileDataManagerProps extends RouteComponentProps {
  currentScreen: UiCategoryEnum
  currentSection: UiCategoryEnum
  processMode: ProcessModes
  previousScreen: UiCategoryEnum
  dataCaptureMode: DataCaptureMode
  isReadOnly: boolean
  profilePic: string
  profile: Profile
  showDataFlowManager: boolean
  selectedDocNames: string[]
  selectedAssociation: string
  selectedEmploymentStatus: string
  selectedCohort: string
  // selectedRole: string
  sourceData: Record<string, any>
  associationRepo: AssociationSettingsRepository
  userRepo: UsersRepository
  profileRepo: ProfileRepository
  idPassport: string
  password: string
  profileNavMenuAccess: ProfileNavMenuAccess

  updateState: (state: any) => void
  overrideProfile: (data: Profile) => void
  updateCurrentScreen: (screen: UiCategoryEnum) => void
  updateCurrentSection: (section: UiCategoryEnum) => void
  // clearProfileWorkflow: () => void;
}

interface IProfileManagerState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  settingsModalOpen: boolean
  confirmChangesModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuVisible: boolean
  shouldGoBack: boolean
  tableWidth: GridSize
  sections: any[]
  changeList: any[]
  currentSectionName: string
  autoLoadScreen: string
  idChangedObject: any
  sideMenuComponents: JSX.Element
  goBackModalDiscardOpen?: boolean
  changesMade: Record<string, { old: any; new: any }>
  searchString?: string
  fieldConfig: FieldConfigSchema
  sectionConfig: ISectionConfigItem[]
  showDataFlowManager: boolean
  dataFlow: Record<string, FieldConfigItemSchema[]>
  newData: Record<string, any>
  screenHeight: number
}

class ProfileDataManager extends Component<IProfileDataManagerProps, IProfileManagerState> {
  dataFlowManagerRef: React.RefObject<DataFlowManager>

  constructor(props: IProfileDataManagerProps) {
    super(props)
    this.dataFlowManagerRef = createRef()
  }

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    settingsModalOpen: false,
    confirmChangesModalOpen: false,
    screenHeight: window.innerHeight,
  }

  state: IProfileManagerState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    sideMenuVisible: true,
    shouldGoBack: false,
    tableWidth: 9,
    sections: [],
    changeList: [],
    changesMade: {},
    currentSectionName: '',
    autoLoadScreen: '',
    idChangedObject: { changed: false },
    fieldConfig: {},
    sectionConfig: [],
    dataFlow: {},
    newData: {},
    showDataFlowManager: false,
  }

  componentDidMount() {
    try {
      const { associationRepo, selectedAssociation, sourceData, dataCaptureMode, selectedDocNames, previousScreen } =
        this.props
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      let sectionConfig: ISectionConfigItem[] = []
      let dataFlow: Record<string, FieldConfigItemSchema[]> = {}

      if (dataCaptureMode === DataCaptureMode.STANDARD || dataCaptureMode === DataCaptureMode.COMBINATION) {
        const dataFlowCategory = this.props.processMode
        const processConfig = associationRepo.getProcessConfig(selectedAssociation)
        sourceData.division = this.props.selectedCohort // important to ensure that the correct data flows are considered in getConfiguredProcessInfo
        const dataFlowNames = getConfiguredProcessInfo(
          'dataFlowNames',
          dataFlowCategory,
          sourceData,
          processConfig,
        ) as string[]
        const result = this.prepareDataFlow(dataFlowNames, associationRepo.getAllDataFlows(selectedAssociation))
        sectionConfig = result.sectionConfig
        dataFlow = result.dataFlow
      }
      if (dataCaptureMode === DataCaptureMode.JIT || dataCaptureMode === DataCaptureMode.COMBINATION) {
        const selectedDocConfig = associationRepo.getDocConfig(selectedAssociation)
        const jitDataFlow = this.generateJitDataFlow(selectedDocConfig, selectedDocNames, fieldConfig)
        const jitSectionConfig = this.generateJitSectionConfig(Object.keys(jitDataFlow))
        sectionConfig = [...sectionConfig, ...jitSectionConfig]
        dataFlow = { ...dataFlow, ...jitDataFlow }
      }
      this.initialiseSideMenu()
      this.setState({
        dataFlow,
        sectionConfig,
        fieldConfig,
        showDataFlowManager: true,
      })
      if (previousScreen !== UiCategoryEnum.DISCIPLINE) {
        this.props.updateCurrentSection(UiCategoryEnum.PEOPLE)
        this.props.updateCurrentScreen(UiCategoryEnum.DATA_MANAGER)
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  prepareDataFlow = (dataFlowNames: string[], allDataFlowConfig: Record<string, IDataFlow>) => {
    let sectionConfig: ISectionConfigItem[] = []
    let dataFlow = {}
    dataFlowNames.forEach((dataFlowName) => {
      sectionConfig = [...sectionConfig, ...allDataFlowConfig[dataFlowName].sectionConfig]
      dataFlow = { ...dataFlow, ...allDataFlowConfig[dataFlowName].dataFlow }
    })
    return { sectionConfig, dataFlow }
  }

  generateJitDataFlow(
    selectedDocConfig: Record<string, DocConfig>,
    selectedDocNames: string[],
    fieldConfig: Record<string, FieldConfigItemSchema>,
  ) {
    let extractedFields: Record<string, FieldConfigItemSchema[]> = {}
    selectedDocNames.forEach((folder) => {
      Object.keys(selectedDocConfig).forEach((docName) => {
        if (docName === folder && selectedDocConfig[docName]) {
          const publishedVersions = Object.keys(selectedDocConfig[docName].published)

          if (publishedVersions.length) {
            if (!extractedFields[folder]) {
              extractedFields[folder] = []
            }
            const version = publishedVersions[0]
            const formFieldConfig = selectedDocConfig[docName].published[version].fieldConfig

            let processedKeys: string[] = []
            Object.keys(formFieldConfig).forEach((fieldId, index) => {
              const selectedFieldConfig = fieldConfig[formFieldConfig[fieldId].key]
              if (selectedFieldConfig && !processedKeys.includes(formFieldConfig[fieldId].key)) {
                extractedFields[folder] = [...extractedFields[folder], selectedFieldConfig]
                processedKeys.push(formFieldConfig[fieldId].key)
              }
            })
          }
        }
      })
    })
    return extractedFields
  }

  generateJitSectionConfig = (selectedDocNames: string[]) => {
    const sectionConfig = selectedDocNames.map((docName) => {
      return {
        sectionName: docName,
        sectionLabel: removeUnderScores(docName),
        iconName: 'file-document',
      }
    })
    return sectionConfig
  }

  goBack(): void {
    this.closeModals()
    window.scrollTo(0, 0)
    const { previousScreen, history, selectedEmploymentStatus, location } = this.props
    if (previousScreen === UiCategoryEnum.DOCUMENT_VALIDITY) {
      history.goBack()
      return
    }
    if (previousScreen === UiCategoryEnum.PROFILES) {
      if (location?.state) {
        history.push(`/people/${toLowerCaseCustom(selectedEmploymentStatus)}s`, {
          prevCustomFilterState: (location.state as PeopleRouteState).prevCustomFilterState ?? {},
        })
        return
      }
    }
    history.push(`/people/${toLowerCaseCustom(selectedEmploymentStatus)}s`)
  }

  initialiseSideMenu() {
    const { profile, profilePic, userRepo, selectedAssociation, currentScreen, history, selectedEmploymentStatus } =
      this.props
    const { name, surname, idPassport } = profile.getPersonalInfo()
    if (!name || !surname || !idPassport) {
      this.displayWarning({ code: 'ProfileFormatError' })
      history.replace(`/people`)
      return
    }
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
    if (!iamEntity) {
      throw { code: Unauthorised }
    }
    const profileNavMenuAccess = configService.generateProfileNavMenuConfig(
      iamEntity,
      selectedEmploymentStatus as EmploymentStatus,
    )
    const sideMenuComponents = (
      <ProfileNavMenu
        profilePic={profilePic}
        name={`${name} ${surname}`}
        idPassport={idPassport}
        onClick={(selectedScreen: string) => this.navigate(selectedScreen)}
        currentScreen={currentScreen}
        accessFlags={profileNavMenuAccess}
      />
    )
    this.props.updateState({ profileNavMenuAccess })
    this.setState({ sideMenuComponents })
  }

  sideMenuHandler(screen: string): void {
    if (completedScreens.includes(screen)) {
      const prevRouteState = this.props.location.state || {}
      this.props.updateState({ currentScreen: screen, previousScreen: UiCategoryEnum.DATA_MANAGER })
      this.props.history.replace(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`, prevRouteState)
    } else {
      this.props.updateState({ currentScreen: UiCategoryEnum.COMING_SOON })
      this.props.history.push('/comingsoon')
    }
  }

  navigate = (screen: string = '', shouldGoBack: boolean = false) => {
    try {
      const { associationRepo, selectedAssociation, sourceData, isReadOnly } = this.props
      const { dataFlow, sectionConfig } = this.state
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      const oldData = sourceData
      const newData = this.dataFlowManagerRef?.current?.getCapturedData()
      if (newData === undefined) {
        this.displayWarning({ code: 'ProfileFormatError' })
        return
      }
      const changesMade = getDataChanges(oldData, newData)
      const changesMadeTally = Object.keys(changesMade).length
      const changeList = generateListOfChanges(changesMade, fieldConfig, dataFlow, sectionConfig)
      if (!changesMadeTally || isReadOnly) {
        if (screen) {
          this.sideMenuHandler(screen)
        } else {
          this.goBack()
        }
        return
      }
      this.setState({
        ...this.initialModalState,
        newData,
        changesMade,
        changeList,
        autoLoadScreen: screen,
        shouldGoBack,
        confirmChangesModalOpen: true,
      })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async saveAndClose(): Promise<void> {
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Saving changes...',
      })

      let {
        idPassport,
        password,
        selectedAssociation,
        selectedEmploymentStatus,
        selectedCohort,
        profile,
        userRepo,
        profileRepo,
        associationRepo,
      } = this.props
      const { autoLoadScreen, shouldGoBack, changesMade, newData } = this.state
      if (!newData.idPassport || !newData.name || !newData.surname) {
        this.setState({
          ...this.initialModalState,
          warningModalHeader: 'MISSING COMPULSORY FIELDS',
          warningModalMessage:
            "The following fields are all compulsory: ID / passport, name, and surname.\n\n If you choose to continue then any changes you've made will be discarded and any active processes will be cancelled.\n\n\nWould you like to continue?",
        })
        return
      }

      if (!isEmpty(changesMade)) {
        const fieldChanges = extractNewFromDataChanges(changesMade)
        const { exists, location, fieldLabel } = profileService.checkChangesForUniqueFieldViolation(
          profileRepo,
          associationRepo,
          selectedAssociation,
          fieldChanges,
        )
        if (exists) {
          console.debug('Field already exists', exists, location, fieldLabel)
          this.displayWarning({ code: 'FieldAlreadyExists', existsLocation: location, fieldLabel })
          return
        }
      }
      const employmemtStatus = selectedEmploymentStatus as EmploymentStatus
      const userEntity = userRepo.getCurrentUserEntity()
      await profileService.saveProfileChanges(
        { selectedAssociation, selectedEmploymentStatus: employmemtStatus, selectedCohort },
        newData,
        changesMade,
        profile,
        userEntity,
        profileRepo,
        { username: idPassport, password },
      )
      this.setState(
        {
          ...this.initialModalState,
          shouldGoBack: false,
          idChangedObject: { changed: false },
        },
        () => {
          this.props.updateState({ sourceData: cloneDeep(profile.getGeneralData()) })
          if (shouldGoBack) {
            this.goBack()
            this.setState({ autoLoadScreen: '' })
          } else if (autoLoadScreen) {
            this.sideMenuHandler(this.state.autoLoadScreen)
          }
        },
      )
    } catch (error) {
      this.displayWarning(error)
    }
  }

  discardChangesAndClose() {
    const { autoLoadScreen, shouldGoBack } = this.state
    if (shouldGoBack) {
      this.goBack()
    } else if (autoLoadScreen) {
      this.sideMenuHandler(this.state.autoLoadScreen)
    }
    this.setState({ ...this.initialModalState, autoLoadScreen: '', shouldGoBack: false })
  }

  displayWarning(error: any) {
    let warningModalHeader = 'Warning'
    let warningModalMessage = ''
    const defaultMessage =
      'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'

    if ('code' in error) {
      if ('message' in error) {
        warningModalMessage =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        switch (error.code) {
          case Unauthorised:
            warningModalMessage = 'You do not have permission to perform this action.'
            break
          case 'NetworkError':
            warningModalMessage =
              'Seems like your internet connection is down. Reconnect to the network, then try again.'
            break
          case 'ProfileFormatError':
            warningModalMessage =
              'It looks like there is a problem with this profile. Contact tech support to look into this for you.'
            break
          case 'IdAlreadyExists':
            warningModalMessage = 'There is already another profile using this ID/passport number.'
            break
          case 'FieldAlreadyExists':
            warningModalHeader = 'Already Exists'
            const sectionName = error.existsLocation === 'TRASHED' ? 'TRASHED' : `${error.existsLocation}S`
            warningModalMessage = `The specified ${error.fieldLabel} field value belongs to another registered profile, which can be found in ${sectionName}. If you don't find this profile there, then click "REFRESH" on the ${sectionName} screen and re-check.`
            break
          default:
            warningModalMessage = defaultMessage
        }
      }
    } else {
      warningModalMessage = defaultMessage
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader,
      warningModalMessage,
    })
  }

  render() {
    const { sourceData, isReadOnly, profileNavMenuAccess } = this.props
    const { hasDataManagerAccess } = profileNavMenuAccess
    const { dataFlow, sectionConfig, fieldConfig, showDataFlowManager, confirmChangesModalOpen } = this.state

    let dataFlowManager = null
    if (showDataFlowManager) {
      dataFlowManager = (
        <DataFlowManager
          ref={this.dataFlowManagerRef}
          fieldConfig={fieldConfig}
          sectionConfig={sectionConfig}
          dataFlow={dataFlow}
          data={sourceData}
          showSectionHeader={true}
          highlightEmpty={true}
          dataFlowDisabled={isReadOnly}
          // selectedRole={selectedRole}
        />
      )
    }

    let confirmChangesModal = null
    if (confirmChangesModalOpen) {
      confirmChangesModal = (
        <ConfirmChanges
          open={true}
          save={() => this.saveAndClose()}
          dontSave={() => this.discardChangesAndClose()}
          onClose={() => this.closeModals()}
          changes={this.state.changeList}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavBar history={this.props.history} match={this.props.match} location={this.props.location} />

        <SectionHeader
          style={styles.sectionHeader}
          searchString={this.state.searchString}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          Data Manager
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.state.sideMenuComponents} />
          <div style={styles.rightSide}>
            <ButtonGeneric
              style={{
                ...styles.backButton,
                width: (window.innerWidth * (this.state.tableWidth as number)) / 12,
              }}
              iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
              label={'BACK'}
              onClick={() => this.navigate('', true)}
            />
            <div style={styles.rightSideContent}>{dataFlowManager}</div>
          </div>
        </div>

        {confirmChangesModal}

        <AlertModalOneButton
          // TODO: There should only be one AlertModalOneButton component per screen with the required headers, body, and functions being set accordingly by a handler.
          open={!hasDataManagerAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view this data."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UsersRepository,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    dataCaptureMode: state.sessionManager.dataCaptureMode,
    isReadOnly: state.sessionManager.isReadOnly,
    processMode: state.sessionManager.processMode,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    previousScreen: state.sessionManager.previousScreen,
    selectedDocNames: state.sessionManager.selectedDocNames,
    sourceData: state.sessionManager.sourceData,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedCohort: state.sessionManager.selectedCohort,
    selectedEmploymentStatus: state.sessionManager.selectedEmploymentStatus,
    // selectedRole: state.sessionManager.selectedRole,
    profileNavMenuAccess: state.sessionManager.profileNavMenuAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    updateCurrentScreen: (screen: string) =>
      dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
    updateCurrentSection: (section: string) =>
      dispatch({ type: ActionType.CHANGE_CURRENT_SECTION, data: { currentSection: section } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ProfileDataManager))
