import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import { ColorPalette } from '../../config/colors'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'

interface OptionsButtonModalProps {
  buttonConfig: any[]
  buttonLabel: string
  open: boolean
  warning: string | JSX.Element
  header: string
  body: string | React.ReactNode[] | React.ReactNode
  onClick: () => void
}

const optionsButtonModal: React.FC<OptionsButtonModalProps> = (props: OptionsButtonModalProps) => {
  const buttons = [...props.buttonConfig].map((buttonComponent, index) => {
    return (
      <Grid key={`optionsButtonModal_${index}`} item container direction="row" alignItems="center" justify="center">
        {buttonComponent}
      </Grid>
    )
  })

  let warning = null
  if (props.warning) {
    warning = <p style={styles.warningTextStyle}>{props.warning}</p>
  }
  // TODO: use /components/Modals/Modal.tsx after web factor lands
  return (
    <Dialog open={props.open} PaperProps={{ style: { borderRadius: 8 } }}>
      <div style={{ ...styles.container }}>
        <h1 style={{ ...styles.textStyle }}>{props.header}</h1>
        <h1 style={{ ...styles.textStyle, fontSize: '0.9rem', fontWeight: 'normal', marginTop: 5 }}>{props.body}</h1>

        <Grid
          style={{ marginTop: 20, alignSelf: 'center' }}
          container
          direction="row"
          alignItems="center"
          justify="center">
          {buttons}
        </Grid>

        {warning}
        <ButtonBlue style={{ marginTop: 40, width: '100%', alignSelf: 'center' }} onClick={props.onClick}>
          {props.buttonLabel}
        </ButtonBlue>
      </div>
    </Dialog>
  )
}

let styles = {
  container: {
    // width: window.innerWidth * 0.4,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '0px 40px 40px 40px',
  },
  textStyle: {
    marginTop: 25,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1.2rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  warningTextStyle: {
    alignSelf: 'center',
    textAlign: 'center' as 'center',
    fontSize: '0.9rem',
    fontWeight: '300',
    marginTop: 30,
    color: ColorPalette.WARNING_RED,
  },
}

export default optionsButtonModal
