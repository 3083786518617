import _ from 'lodash'

import { APP_VERSION } from '../cloud-config'
import { Profile, User, UserProfileSchema } from '../models'
import {
  linkDocsToProfile,
  updateEmployeeData,
  uploadAdHocDataRecord,
} from '../providers/endpointDataProvider/dataSync'
import { fetchProfileData, getRemoteFile, FilePathProvider } from '../providers'
import { processTypes, toLowerCaseCustom } from '../utils'
import { SessionService } from './sessionService'
import { AssociationId, AuthCredentials, CohortName, EmploymentStatus, ProfilePk, TrashStatus } from '../types'
import { AssociationSettingsRepository, ProfileRepository } from '../repositories'
import { compulsoryRecordFieldKeys } from '../config'
import AdHocRecord from '../models/adHocRecord/adHocRecord'

export class ProfileService {
  async assignDocumentsToProfile(
    profilePk: ProfilePk,
    selectedAssociation: string,
    selectedFolder: any,
    newlyCreatedDocs: any,
    userProfile: UserProfileSchema,
    authCredentials: AuthCredentials,
  ): Promise<any> {
    const params = {
      profileInfo: { idPassport: profilePk },
      selectedFolder,
      newlyCreatedDocs,
      webVersion: APP_VERSION,
      assignedBy: `${userProfile.name} ${userProfile.surname}`,
    }
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      const data = await linkDocsToProfile(selectedAssociation, params, token)
      const { importTracker } = data
      return importTracker
    } catch (error) {
      throw error
    }
  }

  async saveProfileChanges(
    segmentData: {
      selectedAssociation: AssociationId
      selectedCohort: CohortName
      selectedEmploymentStatus: EmploymentStatus
    },
    newData: Record<string, any>,
    rawDataChanges: Record<string, { old: any; new: any }>,
    profileEntity: Profile,
    userEntity: User,
    profileRepo: ProfileRepository,
    authCredentials: AuthCredentials,
  ): Promise<any> {
    try {
      const { selectedAssociation, selectedCohort, selectedEmploymentStatus } = segmentData
      profileEntity.updateGeneralData(newData)

      const profilePk = profileEntity.getPk()
      const compulsoryRecordData = this.extractCompulsoryRecordData(
        profileEntity,
        userEntity,
        selectedAssociation,
        selectedEmploymentStatus,
        selectedCohort,
      )
      const processRecord = new AdHocRecord({ rawData: { ...newData, ...compulsoryRecordData } })
      processRecord.addTimestamp()
      const { name = '', surname = '' } = userEntity.getPersonalUserInfo()
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)

      await updateEmployeeData(
        selectedAssociation,
        rawDataChanges,
        profileEntity,
        { name, surname, idPassport: username },
        token,
      )
      await uploadAdHocDataRecord(selectedAssociation, profilePk, [processRecord.convertToJson()], token)
      await profileRepo.updateProfiles({ [profilePk]: profileEntity })
    } catch (error) {
      throw error
    }
  }

  private extractCompulsoryRecordData(
    profileEntity: Profile,
    userEntity: User,
    association: AssociationId,
    employmentStatus: EmploymentStatus,
    selectedCohort: CohortName,
  ) {
    const process =
      employmentStatus === EmploymentStatus.CANDIDATE ? processTypes.generalCandidate : processTypes.generalEmployee

    let compulsoryData = {
      profilePk: profileEntity.getPk(),
      appVersion: `${APP_VERSION}`,
      process,
      association,
      cohort: selectedCohort,
      division: selectedCohort,
      jobType: profileEntity.getGeneralDataValue('jobType'),
      jobSubType: profileEntity.getGeneralDataValue('jobSubType'),
    } as Record<string, any>

    if (userEntity) {
      const { username, name, surname } = userEntity.getPersonalUserInfo()
      compulsoryData = {
        ...compulsoryData,
        username,
        userFirstName: name,
        userSurname: surname,
      }
    }
    compulsoryRecordFieldKeys.forEach((key) => (compulsoryData[key] = profileEntity.getGeneralDataValue(key)))
    return compulsoryData
  }

  checkChangesForUniqueFieldViolation(
    profileRepo: ProfileRepository,
    associationRepo: AssociationSettingsRepository,
    association: string,
    fieldChanges: Record<string, string | string[]>,
  ) {
    const uniqueFields = associationRepo.getUniqueFieldKeysFromConfig(association)
    if (!uniqueFields.length) {
      return { exists: false, location: '' }
    }
    let exists = false
    let location = ''
    let matchingFieldLabel = ''
    const profileEntities = profileRepo.getAllProfiles()
    for (const profileEntity of Object.values(profileEntities)) {
      const matchingFieldValueFound = uniqueFields.find((fieldName: string) => {
        const existingFieldValue = profileEntity.getGeneralDataValue(fieldName)
        if (!fieldChanges.hasOwnProperty(fieldName)) {
          return false
        }
        const newFieldValue = fieldChanges[fieldName]
        const match = toLowerCaseCustom(existingFieldValue) === toLowerCaseCustom(newFieldValue)
        if (match) {
          matchingFieldLabel = associationRepo.getFieldLabelFromConfig(association, fieldName)
        }
        return match
      })
      if (matchingFieldValueFound) {
        const employmentStatus = profileEntity.getEmploymentStatus()
        const trashStatus = profileEntity.getTrashStatus()
        exists = true
        location = trashStatus === TrashStatus.TRASHED ? TrashStatus.TRASHED : employmentStatus
        break
      }
    }
    return { exists, location, fieldLabel: matchingFieldLabel }
  }

  async downloadAllAssociationProfiles(
    sessionService: SessionService,
    associationRepo: AssociationSettingsRepository,
    profileRepo: ProfileRepository,
    authCredentials: AuthCredentials,
    targetAssociation: AssociationId,
    targetCohort?: CohortName,
    setUiProgress?: (progress: string) => void,
  ) {
    const newAssociationSettingsJson = await sessionService.hydrateAssociationSettings(
      [targetAssociation],
      authCredentials,
    )
    await associationRepo.initialise(newAssociationSettingsJson)
    const targetPks = associationRepo.getAuthorisedPks(targetAssociation, targetCohort)
    const batchSize = 50
    await this.downloadProfiles(profileRepo, targetAssociation, targetPks, authCredentials, batchSize, setUiProgress)
  }

  async downloadMissingAssociationProfiles(
    associationRepo: AssociationSettingsRepository,
    profileRepo: ProfileRepository,
    authCredentials: AuthCredentials,
    targetAssociation: AssociationId,
    setUiProgress?: (progress: string) => void,
  ) {
    const targetPks = await this.getNonCachedProfilePks(associationRepo, profileRepo, targetAssociation)
    const batchSize = 50
    await this.downloadProfiles(profileRepo, targetAssociation, targetPks, authCredentials, batchSize, setUiProgress)
  }

  async getNonCachedProfilePks(
    associationRepo: AssociationSettingsRepository,
    profileRepo: ProfileRepository,
    targetAssociation: AssociationId,
  ) {
    const targetPks = associationRepo.getAuthorisedPks(targetAssociation)
    const cachedPks = await profileRepo.listProfilePksLocalStorage()
    let missingPks = new Set(cachedPks)
    targetPks.forEach((pk) => missingPks.delete(pk))
    return Array.from(missingPks)
  }

  async downloadProfiles(
    profileRepo: ProfileRepository,
    targetAssociation: AssociationId,
    targetPks: ProfilePk[],
    authCredentials: AuthCredentials,
    batchSize: number = 50,
    setUiProgress = (progress: string) => {},
  ) {
    let progressCount = 0
    const profilePkChunks = _.chunk(targetPks, batchSize)
    for (const chunk of profilePkChunks) {
      try {
        progressCount += chunk.length
        setUiProgress(`${progressCount} of ${targetPks.length}`)
        const { username, password } = authCredentials
        const token = await SessionService.prepareAuthTokens(username, password)
        const profiles = await fetchProfileData(targetAssociation, chunk, token)
        await profileRepo.updateProfiles(profiles)
      } catch (error) {
        console.log('downloadProfiles error(): ', error)
      }
    }
  }

  async getProfilePic(profilePk: ProfilePk) {
    const remoteUrl = FilePathProvider.getRemoteProfilePicFilePath(profilePk)
    return await getRemoteFile(remoteUrl)
  }
}
