import {
  mdiAlertCircle,
  mdiAccountMultipleCheck,
  mdiFileDocumentMultiple,
  mdiClipboardClock,
  mdiCardAccountDetails,
  mdiDownload,
  mdiSync,
} from "@mdi/js"

import { UiCategoryEnum, MenuSchema, NavMenuAccess } from "../types"

export class NavBarService {
  buildMenuTabConfig(menuAccess: NavMenuAccess): MenuSchema[] {
    const maybeConfiguratorMenu = this.buildConfiguratorMenu(menuAccess)
    const maybeReportingMenu = this.buildReportingMenu(menuAccess)
    const maybeRequestMenu = this.buildRequestMenu(menuAccess)
    const maybePeopleMenu = this.buildPeopleMenu(menuAccess)
    const maybeBulkActionsMenu = this.buildBulkActionsMenu(menuAccess)
    const maybeLogsMenu = this.buildLogsMenu(menuAccess)
    const maybeIntegrationsMenu = this.buildIntegrationsMenu(menuAccess)

    let config: MenuSchema[] = []
    if (maybeConfiguratorMenu) {
      config.push(maybeConfiguratorMenu)
    }
    if (maybeReportingMenu) {
      config.push(maybeReportingMenu)
    }
    if (maybeRequestMenu) {
      config.push(maybeRequestMenu)
    }
    if (maybePeopleMenu) {
      config.push(maybePeopleMenu)
    }
    if (maybeBulkActionsMenu) {
      config.push(maybeBulkActionsMenu)
    }
    if (maybeLogsMenu) {
      config.push(maybeLogsMenu)
    }
    if (maybeIntegrationsMenu) {
      config.push(maybeIntegrationsMenu)
    }
    return config
  }

  private buildConfiguratorMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: UiCategoryEnum.USERS,
          screenName: UiCategoryEnum.USERS,
          hasAccess: menuAccess.hasUserManagementAccess,
        },
        {
          label: UiCategoryEnum.DATA_QUERIES,
          screenName: UiCategoryEnum.DATA_QUERIES,
          hasAccess: menuAccess.hasAdvancedDataQueryAccess,
        },
        {
          label: UiCategoryEnum.FORMS,
          screenName: UiCategoryEnum.FORMS,
          hasAccess: menuAccess.hasDocumentLibraryAccess,
        },
        {
          label: UiCategoryEnum.PPE_CONFIG,
          screenName: UiCategoryEnum.PPE_CONFIG,
          hasAccess: menuAccess.hasPpeStockConfigAccess,
        },
        {
          label: UiCategoryEnum.DATA_FIELDS,
          screenName: UiCategoryEnum.DATA_FIELDS,
          hasAccess: menuAccess.hasFieldConfigAccess,
        },
        {
          label: UiCategoryEnum.DATA_FLOWS,
          screenName: UiCategoryEnum.DATA_FLOWS,
          hasAccess: menuAccess.hasDataFlowConfigAccess,
        },
        {
          label: UiCategoryEnum.PROCESSES,
          screenName: UiCategoryEnum.PROCESSES,
          hasAccess: menuAccess.hasProcessConfigAccess,
        },
        {
          label: UiCategoryEnum.JSON_CONFIG_FILES,
          screenName: UiCategoryEnum.JSON_CONFIG_FILES,
          hasAccess: menuAccess.hasJsonConfigFileAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: UiCategoryEnum.CONFIGURATOR,
      screenName: UiCategoryEnum.CONFIGURATOR,
      subMenus,
    } as MenuSchema
  }

  private buildIntegrationsMenu(menuAccess: NavMenuAccess) {
    const buildSubSubMenus = (menuAccess: NavMenuAccess) => {
      let subSubMenuConfig = {} as Record<UiCategoryEnum, any>
      subSubMenuConfig[UiCategoryEnum.ADAGIN] = [
        {
          iconName: mdiSync,
          screenName: UiCategoryEnum.ADAGIN_SYNC,
          label: "TRIGGER SYNC",
          hasAccess: menuAccess.hasAdaginSyncTriggerAccess,
        },
        {
          iconName: mdiDownload,
          screenName: UiCategoryEnum.ADAGIN_LOGS,
          label: "DOWNLOAD ERROR LOGS",
          hasAccess: menuAccess.hasAdaginLogDownloadAccess,
        },
      ]
      subSubMenuConfig[UiCategoryEnum.AGRIGISTICS] = [
        {
          iconName: mdiSync,
          screenName: UiCategoryEnum.AGRIGISTICS_SYNC,
          label: "TRIGGER SYNC",
          hasAccess: menuAccess.hasAgrigisticsSyncTriggerAccess,
        },
        {
          iconName: mdiDownload,
          screenName: UiCategoryEnum.AGRIGISTICS_LOGS,
          label: "DOWNLOAD ERROR LOGS",
          hasAccess: menuAccess.hasAgrigisticsLogDownloadAccess,
        },
      ]
      subSubMenuConfig[UiCategoryEnum.PAYSPACE] = [
        {
          iconName: mdiSync,
          screenName: UiCategoryEnum.PAYSPACE_SYNC,
          label: "TRIGGER SYNC",
          hasAccess: menuAccess.hasPaySpaceSyncTriggerAccess,
        },
        {
          iconName: mdiDownload,
          screenName: UiCategoryEnum.PAYSPACE_LOGS,
          label: "DOWNLOAD ERROR LOGS",
          hasAccess: menuAccess.hasPaySpaceLogDownloadAccess,
        },
      ]
      return subSubMenuConfig
    }

    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: UiCategoryEnum.ADAGIN,
          screenName: UiCategoryEnum.ADAGIN,
          hasAccess: menuAccess.hasAdaginAccess,
        },
        {
          label: UiCategoryEnum.AGRIGISTICS,
          screenName: UiCategoryEnum.AGRIGISTICS,
          hasAccess: menuAccess.hasAgrigisticsAccess,
        },
        {
          label: UiCategoryEnum.PAYSPACE,
          screenName: UiCategoryEnum.PAYSPACE,
          hasAccess: menuAccess.hasPaySpaceAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    const subSubMenus = buildSubSubMenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: UiCategoryEnum.INTEGRATIONS,
      screenName: UiCategoryEnum.INTEGRATIONS,
      subMenus,
      subSubMenus,
    } as MenuSchema
  }

  private buildReportingMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: UiCategoryEnum.BILLING,
          screenName: UiCategoryEnum.BILLING,
          hasAccess: menuAccess.hasBillingAccess,
        },
        {
          label: UiCategoryEnum.DOCUMENT_VALIDITY,
          screenName: UiCategoryEnum.DOCUMENT_VALIDITY,
          hasAccess: menuAccess.hasDocValidityAccess,
        },
        {
          label: UiCategoryEnum.PPE_INVENTORY,
          screenName: UiCategoryEnum.PPE_INVENTORY,
          hasAccess: menuAccess.hasPpeStockAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: UiCategoryEnum.REPORTING,
      screenName: UiCategoryEnum.REPORTING,
      subMenus,
    } as MenuSchema
  }

  private buildBulkActionsMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: UiCategoryEnum.DISCIPLINE,
          screenName: UiCategoryEnum.DISCIPLINE,
          hasAccess: menuAccess.hasBulkDisciplineAccess,
        },
        {
          label: UiCategoryEnum.EXPORT,
          screenName: UiCategoryEnum.EXPORT,
          hasAccess: menuAccess.hasExportAccess,
        },
        {
          label: UiCategoryEnum.IMPORT,
          screenName: UiCategoryEnum.IMPORT,
          hasAccess: menuAccess.hasImportAccess,
        },
        {
          label: UiCategoryEnum.TERMINATE,
          screenName: UiCategoryEnum.TERMINATE,
          hasAccess: menuAccess.hasBulkTerminationAccess,
        },
      ]
    }

    const buildSubSubMenus = (menuAccess: NavMenuAccess) => {
      let subSubMenuConfig = {} as Record<UiCategoryEnum, any>
      subSubMenuConfig[UiCategoryEnum.EXPORT] = [
        {
          iconName: mdiFileDocumentMultiple,
          screenName: UiCategoryEnum.EXPORT_DOCS,
          label: "DOCS",
          hasAccess: menuAccess.hasDocExportAccess,
        },
        {
          iconName: mdiClipboardClock,
          screenName: UiCategoryEnum.EXPORT_DOC_VALIDITY_DATA,
          label: "DOC VALIDITY DATA",
          hasAccess: menuAccess.hasDocValidityAccess,
        },
        {
          iconName: mdiCardAccountDetails,
          screenName: UiCategoryEnum.EXPORT_EMPLOYEE_CARDS,
          label: "EMPLOYEE CARDS",
          hasAccess: true,
        },
        {
          iconName: mdiAccountMultipleCheck,
          screenName: UiCategoryEnum.EXPORT_PROFILES,
          label: "PROFILES",
          hasAccess: menuAccess.hasExportAccess,
        },
      ]
      subSubMenuConfig[UiCategoryEnum.DISCIPLINE] = [
        {
          iconName: mdiAlertCircle,
          screenName: UiCategoryEnum.UNDER_PERFORMANCE,
          hasAccess: menuAccess.hasBulkDisciplineAccess,
          label: "UNDER PERFORMANCE",
        },
      ]
      subSubMenuConfig[UiCategoryEnum.IMPORT] = [
        {
          iconName: mdiAlertCircle,
          screenName: UiCategoryEnum.FILES_AND_PERFORMANCE_DATA_IMPORTS,
          hasAccess: menuAccess.hasFileAssociationAccess || menuAccess.hasPerformanceImportAccess,
          label: "FILES AND PERFORMANCE DATA",
        },
        {
          iconName: mdiAlertCircle,
          screenName: UiCategoryEnum.PROFILE_IMPORTS,
          hasAccess: menuAccess.hasBulkProfileImportAccess,
          label: "PROFILES",
        },
      ]
      return subSubMenuConfig
    }
    const subMenus = buildSubmenus(menuAccess)
    const subSubMenus = buildSubSubMenus(menuAccess)
    return {
      label: UiCategoryEnum.BULK_ACTIONS,
      screenName: UiCategoryEnum.BULK_ACTIONS,
      subMenus,
      subSubMenus,
    } as MenuSchema
  }

  private buildRequestMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      let subMenuConfig = []
      if (menuAccess.hasPpeRequestAccess) {
        subMenuConfig.push({
          label: UiCategoryEnum.REQUESTS_PPE,
          screenName: UiCategoryEnum.REQUESTS_PPE,
          hasAccess: menuAccess.hasPpeRequestAccess,
        })
      }
      return subMenuConfig
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: UiCategoryEnum.REQUESTS,
      screenName: UiCategoryEnum.REQUESTS,
      subMenus,
    } as MenuSchema
  }

  private buildPeopleMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: UiCategoryEnum.EMPLOYEES,
          screenName: UiCategoryEnum.EMPLOYEES,
          hasAccess: menuAccess.hasEmployeesAccess,
        },
        {
          label: UiCategoryEnum.CANDIDATES,
          screenName: UiCategoryEnum.CANDIDATES,
          hasAccess: menuAccess.hasCandidatesAccess,
        },
        { label: UiCategoryEnum.TRASH, screenName: UiCategoryEnum.TRASH, hasAccess: menuAccess.hasTrashAccess },
        { label: UiCategoryEnum.GROUPS, screenName: UiCategoryEnum.GROUPS, hasAccess: menuAccess.hasGroupAccess },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: UiCategoryEnum.PEOPLE,
      screenName: UiCategoryEnum.PEOPLE,
      subMenus,
    } as MenuSchema
  }

  private buildLogsMenu(menuAccess: NavMenuAccess) {
    const buildSubmenus = (menuAccess: NavMenuAccess) => {
      return [
        {
          label: UiCategoryEnum.DATA_LOGS,
          screenName: UiCategoryEnum.DATA_LOGS,
          hasAccess: menuAccess.hasDataLogsAccess,
        },
        {
          label: UiCategoryEnum.DEVICE_LOGS,
          screenName: UiCategoryEnum.DEVICE_LOGS,
          hasAccess: menuAccess.hasDeviceLogsAccess,
        },
      ]
    }
    const subMenus = buildSubmenus(menuAccess)
    if (!subMenus.length) {
      return null
    }
    return {
      label: UiCategoryEnum.GLOBAL_LOGS,
      screenName: UiCategoryEnum.GLOBAL_LOGS,
      subMenus,
    } as MenuSchema
  }
}
