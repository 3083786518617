import { AssociationId, ImportActionEnum, ImportId, Username } from "../../types"
import { httpGet, httpPost } from "../remoteDataProvider"

export async function submitImportUploadRequest(
  association: AssociationId,
  importId: ImportId,
  username: Username,
  token: string,
) {
  const { data } = await httpGet(
    "people_flow_core",
    `/imports/profiles/uploadrequest/${association}/${importId}/${username}`,
    token,
  )
  const { fileDir, fileName, fileFormat } = data
  return { fileDir, fileName, fileFormat }
}

export async function submitPlanDownloadRequest(
  association: AssociationId,
  importId: ImportId,
  username: Username,
  token: string,
) {
  const { data } = await httpGet(
    "people_flow_core",
    `/imports/import-plan-evaluation-list/downloadrequest/${association}/${importId}/${username}`,
    token,
  )
  const { fileDir, fileName, fileFormat } = data
  return { fileDir, fileName, fileFormat }
}

export async function submitResultDownloadRequest(
  association: AssociationId,
  importId: ImportId,
  username: Username,
  token: string,
) {
  const { data } = await httpGet(
    "people_flow_core",
    `/imports/import-result-evaluation-list/downloadrequest/${association}/${importId}/${username}`,
    token,
  )
  const { fileDir, fileName, fileFormat } = data
  return { fileDir, fileName, fileFormat }
}

export async function planProfileImport(
  association: AssociationId,
  importId: ImportId,
  username: Username,
  action: ImportActionEnum,
  token: string,
) {
  const body = {}
  const { data } = await httpPost(
    "people_flow_core",
    `/imports/profiles/plan/${association}/${importId}/${username}/${action}`,
    body,
    token,
  )
  return data
}

export async function checkForProfileImportPlanList(
  association: AssociationId,
  importId: ImportId,
  username: Username,
  token: string,
) {
  const { data } = await httpGet(
    "people_flow_core",
    `/imports/profiles/plan-evaluation-list/${association}/${importId}/${username}`,
    token,
  )
  const { isComplete, fileConfig } = data
  return { isComplete, fileConfig }
}

export async function checkForProfileImportResultList(
  association: AssociationId,
  importId: ImportId,
  username: Username,
  token: string,
) {
  const { data } = await httpGet(
    "people_flow_core",
    `/imports/profiles/result-evaluation-list/${association}/${importId}/${username}`,
    token,
  )
  const { isComplete, fileConfig } = data
  return { isComplete, fileConfig }
}

export async function applyImport(association: AssociationId, importId: ImportId, username: Username, token: string) {
  const body = {}
  const { data } = await httpPost(
    "people_flow_core",
    `/imports/profiles/apply/${association}/${importId}/${username}`,
    body,
    token,
  )
  return data
}
