import { Storage, API } from "aws-amplify"

import {
  addUnderScores,
  titleCase,
  toLowerCaseCustom,
  formatDateAndTime,
  sortArrayOfObjects,
  normaliseS3Path,
} from "../utils"
import { ColorPalette } from "../config/colors"
import { PerformanceImportTracker } from "../store"
import { DeviceLog } from "../components/Logs/DeviceLogBlock"
import { Profile } from "../models/profile/profile"
import { FieldConfigSchema } from "../types"

export async function httpPost(apiName: string, path: string, body: any, token: string): Promise<any> {
  const init: any = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    response: true,
    body,
  }

  return API.post(apiName, path, init)
}

export async function httpPatch(apiName: string, path: string, body: any, token: string): Promise<any> {
  let init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    response: true,
    body,
  }
  return API.patch(apiName, path, init)
}

export async function httpGet(apiName: string, path: string, token: string): Promise<any> {
  let init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    response: true,
  }

  return API.get(apiName, path, init)
}

export async function httpDelete(apiName: string, path: string, token: string): Promise<any> {
  let init = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    response: true,
  }

  return API.del(apiName, path, init)
}

// TODO: move following functions to a separate services/files

export function unlinkDocuments(
  profile: Profile,
  selectedAssociation: string,
  pk: string,
  updateItem: any,
  selectedDocs: string[],
  token: string,
): Promise<Profile> {
  try {
    const item = {
      updateTrackerBatch: { [pk]: updateItem },
      selectedEmployer: selectedAssociation,
      dataCategory: "docs",
    }

    return httpPost("people_flow_data_sync", "/data-sync/update-employee-data", item, token)
      .then((httpresult) => {
        const { result } = httpresult.data
        if (result !== "success") {
          throw { code: "UnlinkDocumentsError" }
        }

        profile.unlinkDocuments(selectedAssociation, selectedDocs)
        return profile
      })
      .catch((err) => {
        throw err
      })
  } catch (error) {
    throw error
  }
}

export async function listRemoteFilesInDirectory(path: string): Promise<any[]> {
  return await Storage.list(path)
}

export async function getRemoteFile(
  path: string,
  config: any = { download: false, cacheControl: "no-cache" },
): Promise<any> {
  try {
    path = normaliseS3Path(path)
    return await Storage.get(path, config)
  } catch (error) {
    console.error("Error fetching remote file", error)
    return null
  }
}

export async function fetchProfileData(
  selectedAssociation: string,
  profilesToFetch: string[],
  token: string,
): Promise<Record<string, Profile>> {
  const body = { selectedEmployer: selectedAssociation, profilesToFetch }
  return httpPost("people_flow_data_sync", "/data-sync/fetch-batch-profiles", body, token).then((httpresult) => {
    const { result, profiles } = httpresult.data
    if (result !== "success") {
      throw { code: "UnknownError" }
    }
    return profiles
  })
}

export async function fetchChangeLogs(
  currentLogs: any,
  selectedAssociation: string,
  idPassport: string,
  fieldConfig: FieldConfigSchema,
  batchNumber: any,
  profileIdPassport: string | null,
  profilePk: string | null,
  uid: string | null,
  token: string,
) {
  try {
    const logType = profileIdPassport ? "individual" : "global"
    const item: Record<string, any> = {
      batchNumber,
      logType,
      shouldGetSyncTracker: true,
      userIdPassport: idPassport,
      selectedEmployer: selectedAssociation,
    }
    if (logType === "individual") {
      item["idPassport"] = profilePk
      item["uid"] = uid
    }
    const httpresult = await httpPost("people_flow_data_sync", "/data-sync/fetch-data-logs", item, token)
    const remoteLogInfo = httpresult.data
    if (remoteLogInfo.result !== "success") {
      throw { code: "UnknownError" }
    }

    let orderedLogs = sortArrayOfObjects<any>("timestamp", "descending", remoteLogInfo.remoteLogs)
    orderedLogs = orderedLogs.map((logItem) => {
      const dateElements = formatDateAndTime(new Date(logItem.timestamp))

      if (logItem.dataCategory === "rawData") {
        Object.keys(logItem.rawData).forEach((key) => {
          const newKeyName = fieldConfig[key] && fieldConfig[key].label ? fieldConfig[key].label : key
          logItem.rawData[newKeyName] = logItem.rawData[key]

          if (newKeyName !== key) {
            delete logItem.rawData[key]
          }
        })
      }

      if (remoteLogInfo.syncTrackerData) {
        if (!logItem.local) {
          logItem = {
            ...logItem,
            local: true,
            downloadedToDevice: true,
            downloadedEverywhere: true,
          }
          if (logItem.timestamp > remoteLogInfo.syncTrackerData.userTimestamp) {
            logItem.downloadedToDevice = false
          } else if (logItem.timestamp > remoteLogInfo.syncTrackerData.oldestTimestamp) {
            logItem.downloadedEverywhere = false
          }
        } else {
          if (logItem.timestamp < remoteLogInfo.syncTrackerData.userTimestamp) {
            logItem.downloadedToDevice = true
          } else if (logItem.timestamp < remoteLogInfo.syncTrackerData.oldestTimestamp) {
            logItem.downloadedEverywhere = true
          }
        }
      }

      logItem = {
        ...logItem,
        title: `${logItem.name} ${logItem.surname}  |  ${logItem.profileIdPassport}`,
        metaData: `V${logItem.appVersion} - ${titleCase(logItem.userName)} ${titleCase(logItem.userSurname)} - ${
          dateElements.day
        }.${dateElements.monthNumber}.${dateElements.year}, ${dateElements.hour}:${dateElements.minutes}`,
        time: `${dateElements.day} ${dateElements.month}`,
        circleColor: ColorPalette.DARK_GREY,
        lineColor: ColorPalette.DARK_GREY,
      }
      return logItem
    })

    return {
      logs: [...currentLogs, ...orderedLogs],
      currentBatchNumber: remoteLogInfo.batchNumber,
    }
  } catch (error) {
    // console.log("fetchChangeLogs() error: ", error)
    throw error
  }
}

export function fetchPerformanceData(
  selectedAssociation: string,
  idPassport: string,
  appVersion: string | number,
  token: string,
): Promise<any> {
  return httpPost(
    "people_flow_data_sync",
    "/data-sync/fetch-performance",
    { selectedEmployer: selectedAssociation, idPassport, appVersion },
    token,
  )
    .then((httpresult) => {
      const { result, performanceData } = httpresult.data

      if (result !== "success") {
        throw { code: "" }
      }
      return performanceData
    })
    .catch((err) => err)
}

export function fetchImportHistory(
  type: string,
  selectedAssociation: string,
  timestamp: any,
  token: string,
): Promise<{ upToDate: any; remoteImportTracker: PerformanceImportTracker[] }> {
  return httpPost(
    "people_flow_import_export",
    "/data-import/fetch-import-history",
    { selectedEmployer: selectedAssociation, timestamp, type },
    token,
  )
    .then((httpresult) => {
      const { result, upToDate, importTracker } = httpresult.data

      if (result !== "success") {
        throw { code: "UnknownError" }
      }

      return { upToDate: upToDate, remoteImportTracker: importTracker as any[] }
    })
    .catch((error) => {
      throw error
    })
}

export async function fetchListOfFilesInRemoteFolder(remotePath: string, fileType: string): Promise<string[]> {
  return listRemoteFilesInDirectory(remotePath).then((listOfFiles) => {
    return listOfFiles
      .map((file) => {
        if (!file?.key) {
          return null
        }
        const fileNameElements = file.key.split("/")
        const docName = fileNameElements[fileNameElements.length - 1]
        if (!toLowerCaseCustom(docName).includes(fileType)) {
          return null
        }
        return docName
      })
      .filter((x) => x !== null) as string[]
  })
}

export function fetchImportedDocsList(selectedAssociation: string, token: string): Promise<string[]> {
  const remotePath = `Companies/${addUnderScores(selectedAssociation)}/Documents/Imports`
  return fetchListOfFilesInRemoteFolder(remotePath, ".pdf")
}

export function fetchDeviceLogs(selectedAssociation: string, token: string): Promise<DeviceLog[]> {
  return httpPost(
    "people_flow_data_sync",
    "/data-sync/device-logs",
    { selectedEmployer: selectedAssociation, action: "FETCH" },
    token,
  ).then((httpresult) => {
    const { result, deviceLogs } = httpresult.data
    if (result !== "success") {
      throw { code: "FetchDeviceLogsError" }
    }
    return deviceLogs
  })
}
