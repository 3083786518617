import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import { DateStandardEnum } from '../types'
import { checkDigitsOnly } from './regexUtils'
dayjs.extend(customParseFormat)

interface IDateParts {
  year: number
  month: string
  monthNumber: string
  day: string
  hour: string
  minutes: string
}

export function getMonthName(date: Date): string {
  return numberMonthMap[date.getMonth().toString()]
}

function leftPadInteger(num: string | number): string {
  if (typeof num === 'string') {
    num = parseInt(num)
  }
  if (num < 10) {
    return `0${num}`
  }

  return `${num}`
}

export function formatDateAndTime(date: Date): IDateParts {
  let year = date.getFullYear()
  let month = getMonthName(date)
  let monthNumber = leftPadInteger(date.getMonth() + 1)
  let day = leftPadInteger(date.getDate())
  let hour = leftPadInteger(date.getHours())
  let minutes = leftPadInteger(date.getMinutes())

  return { year, month, monthNumber, day, hour, minutes }
}

export const numberMonthMap: Record<string, string> = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

export const monthNumberMap: Record<string, string> = {
  Jan: '0',
  Feb: '1',
  Mar: '2',
  Apr: '3',
  May: '4',
  Jun: '5',
  Jul: '6',
  Aug: '7',
  Sep: '8',
  Oct: '9',
  Nov: '10',
  Dec: '11',
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export const timestampToDateString = (timestamp: string | number, format: string = 'YYYY/MM/DD') => {
  if (typeof timestamp === 'string') {
    timestamp = parseInt(timestamp)
  }
  return dayjs.unix(timestamp / 1000).format(format)
}

export function getDateString(date: Date) {
  const { year, monthNumber: month, day } = formatDateAndTime(date)
  return `${year}/${month}/${day}`
}

export function getDateFormat(dateString: string, dateFormatConvention: DateStandardEnum = DateStandardEnum.UK) {
  const formatOptions = {
    [DateStandardEnum.UK]: {
      YEAR_FIRST: [
        'YYYY/MM/DD',
        'YYYY/MM/D',
        'YYYY/M/D',
        'YYYY/M/DD',
        'YYYY-MM-DD',
        'YYYY-MM-D',
        'YYYY-M-D',
        'YYYY-M-DD',
        'YYYYMMDD',
        'YYYYMDD',
        'YYYYMMD',
        'YYYYMD',
      ],
      DAY_FIRST: [
        'DD/MM/YYYY',
        'D/MM/YYYY',
        'D/M/YYYY',
        'DD/M/YYYY',
        'DD-MM-YYYY',
        'D-MM-YYYY',
        'D-M-YYYY',
        'DD-M-YYYY',
        'DDMMYYYY',
        'DDMYYYY',
        'DMMYYYY',
        'DMYYYY',
        'DD MM YYYY',
        'DD M YYYY',
        'D MM YYYY',
        'D M YYYY',
      ],
    },
    [DateStandardEnum.US]: {
      YEAR_FIRST: [
        'YYYY/DD/MM',
        'YYYY/D/MM',
        'YYYY/D/M',
        'YYYY/DD/M',
        'YYYY-DD-MM',
        'YYYY-D-MM',
        'YYYY-D-M',
        'YYYY-D-MM',
        'YYYYDDMM',
        'YYYYDMM',
        'YYYYDDM',
        'YYYYDM',
      ],
      MONTH_FIRST: [
        'MM/DD/YYYY',
        'M/DD/YYYY',
        'M/D/YYYY',
        'MM/D/YYYY',
        'MM-DD-YYYY',
        'M-DD-YYYY',
        'M-D-YYYY',
        'MM-D-YYYY',
        'MMDDYYYY',
        'MMDYYYY',
        'MDDYYYY',
        'MDYYYY',
        'MM DD YYYY',
        'MM D YYYY',
        'M DD YYYY',
        'M D YYYY',
      ],
    },
  } as Record<DateStandardEnum, Record<string, string[]>>
  const dateOrders = Object.keys(formatOptions[dateFormatConvention])
  for (const dateOrder of dateOrders) {
    for (const format of formatOptions[dateFormatConvention][dateOrder]) {
      if (dayjs(dateString, format).format(format) === dateString) {
        return format
      }
    }
  }
  return ''
}

export function toValidPfDate(date: any, targetFormat: string = 'YYYY/MM/DD', sourceDateConvention?: DateStandardEnum) {
  try {
    if (!date) {
      return ''
    }
    function getDateString(date: string, sourceFormat: string, targetFormat: string) {
      const targetDate = dayjs(date, sourceFormat).format(targetFormat)
      if (targetDate.toLowerCase().includes('invalid')) {
        return date
      }
      return targetDate
    }
    if (Array.isArray(date)) {
      if (date.length) {
        date = date[0]
      }
    }
    if (checkDigitsOnly(date)) {
      const unixMsString = `${date}`.substring(0, 10)
      return dayjs.unix(parseInt(unixMsString)).format(targetFormat)
    }
    const sourceFormat = getDateFormat(date, sourceDateConvention)
    if (!sourceFormat) {
      return date
    }
    return getDateString(date, sourceFormat, targetFormat)
  } catch (error) {
    return ''
  }
}
