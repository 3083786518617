import { FieldConfig } from '../models/fieldConfig/fieldConfigModel'
import { CsvColumnConfig } from '../types'

export const buildTerminationColumnConfig = (fieldConfig: FieldConfig): CsvColumnConfig[] => {
  const columnConfig = ['idPassport', 'terminationDate', 'reasonForTermination']
  return columnConfig.map((key) => {
    const label = fieldConfig.getLabel(key) || key
    return {
      key,
      label,
      isCompulsory: true,
    }
  })
}

export const buildAdHocColumnConfig = (fieldConfig: FieldConfig): CsvColumnConfig[] => {
  const columnConfig = ['idPassport']
  return columnConfig.map((key) => {
    const label = fieldConfig.getLabel(key) || key
    return {
      key,
      label,
      isCompulsory: true,
    }
  })
}

export const buildPpeStockColumnConfig = (fieldConfig: FieldConfig): CsvColumnConfig[] => {
  const columnConfig = ['sku', 'ppeSize', 'qty', 'purchaseOrderId', 'supplier']
  return columnConfig.map((key) => {
    const label = fieldConfig.getLabel(key) || key
    return {
      key,
      label,
      isCompulsory: true,
    }
  })
}
