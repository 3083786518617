import React, { Component, createRef, RefObject } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'

import SubMenu from '../../Navigation/SubMenu'
import { ActionType } from '../../../store/actions/actions'
import { ColorPalette } from '../../../config/colors'
import { PeopleFlowCombinedReducer } from '../../../store'
import { ExportService } from '../../../services'
import { ValidEmploymentStatus, UiCategoryEnum, SubMenuSchema, SubSubMenuSchema } from '../../../types'

interface INavButtonProps {
  subMenus: SubMenuSchema[]
  label: string
  selectedAssociation: string
  key: string
  currentProfile?: string
  currentSection: string
  currentScreen: UiCategoryEnum
  selectedEmploymentStatus: ValidEmploymentStatus
  subSubMenus?: SubSubMenuSchema
  primaryTableRef?: React.RefObject<any>
  buttonsWithCustomActions: string[]
  disabled?: boolean
  style?: React.CSSProperties
  onMenuClick: (menuLabel: string, subMenuLabel?: string, subSubMenuLabel?: string, hasAccess?: boolean) => void
  updateState: (data: any) => void
}

interface INavButtonState {
  showSubMenu: boolean
  loadingModalOpen: boolean
  terminationModalOpen: boolean
}

class NavButton extends Component<INavButtonProps, INavButtonState> {
  exportService = new ExportService()
  terminationDataUploadDivRef: RefObject<HTMLDivElement> = createRef()

  initialModalState = {
    loadingModalOpen: false,
    terminationModalOpen: false,
  }

  state: INavButtonState = {
    ...this.initialModalState,
    showSubMenu: false,
  }

  toggleSubMenu() {
    if (this.props.subMenus.length) {
      this.setState((prevState) => ({ showSubMenu: !prevState.showSubMenu }))
    }
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  render() {
    if (window.location.pathname.includes(this.props.label.toLowerCase().replace(/\s/g, ''))) {
      styles.drawerButton.borderBottom = `3px solid ${ColorPalette.PRIMARY_TEXT}`
    } else {
      styles.drawerButton.borderBottom = `2px solid rgba(255,255,255,0)`
    }
    const { subMenus, subSubMenus, label, buttonsWithCustomActions, primaryTableRef } = this.props

    let dropDownArrow: JSX.Element | null = null
    const hasSubMenus = subMenus.length > 0
    if (hasSubMenus) {
      dropDownArrow = <ArrowIcon style={styles.dropDownArrow} />
    }

    let subMenu = null
    if (this.state.showSubMenu) {
      subMenu = (
        <SubMenu
          label={label}
          subMenus={subMenus}
          subSubMenus={subSubMenus}
          onSubMenuClick={(subMenuLabel: UiCategoryEnum, hasAccess?: boolean) =>
            this.props.onMenuClick(label, subMenuLabel, undefined, hasAccess)
          }
          onSubSubMenuClick={(subMenuLabel: UiCategoryEnum, subSubMenuLabel: UiCategoryEnum, hasAccess?: boolean) =>
            this.props.onMenuClick(label, subMenuLabel, subSubMenuLabel, hasAccess)
          }
          buttonsWithCustomActions={buttonsWithCustomActions}
          primaryTableRef={primaryTableRef}
          key={`subMenu_${label}`}
        />
      )
    }

    return (
      <div
        key={`${this.props.key}_${this.props.label}`}
        style={this.props.style}
        onMouseEnter={() => this.toggleSubMenu()}
        onMouseLeave={() => this.toggleSubMenu()}>
        <button
          style={{ ...styles.drawerButton, marginRight: this.props.subMenus.length ? 0 : 15 }}
          onClick={() => null}>
          {this.props.label}
          {dropDownArrow}
        </button>
        {subMenu}
      </div>
    )
  }
}

let styles = {
  drawerButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.95rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: 40,
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
    outline: 'none',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0)',
    ':hover': {
      borderBottom: `2px solid ${ColorPalette.PRIMARY_TEXT}`,
    },
    borderBottom: '',
  },
  dropDownArrow: {
    width: '1.4rem',
    height: '1.4rem',
    marginLeft: 2,
    marginRight: -10,
  },
  subMenuLabel: {
    fontSize: '0.7rem',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    height: 30,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    currentScreen: state.sessionManager.currentScreen,
    currentSection: state.sessionManager.currentSection,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedEmploymentStatus: state.sessionManager.selectedEmploymentStatus,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(NavButton))
