const compulsoryRecordFieldKeys = ['idPassport', 'name', 'surname', 'jobType', 'jobSubType']

const systemFields_Primary = {
  pk: {},
  sk: {},
  uid: {},
  pfid: {},
  cohorts: {},
  trashStatus: {},
  faceShots: {},
  docs: {},
  photos: {},
}

const systemFields_GeneralData = {
  reasonForTermination: {},
  terminationDate: {},
  employmentStatus: {},
  isNewProfileImport: {},
}

export { compulsoryRecordFieldKeys, systemFields_Primary, systemFields_GeneralData }
