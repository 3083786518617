export enum UiCategoryEnum {
  ADAGIN = "Adagin",
  ADAGIN_LOGS = "Adagin Logs",
  ADAGIN_SYNC = "Trigger Adagin Sync",
  AGRIGISTICS = "Agrigistics",
  AGRIGISTICS_LOGS = "Agrigistics Logs",
  AGRIGISTICS_SYNC = "Trigger Agrigistics Sync",
  PAYSPACE = "PaySpace",
  PAYSPACE_LOGS = "PaySpace Logs",
  PAYSPACE_SYNC = "Trigger PaySpace Sync",
  BILLING = "Billing",
  BULK_ACTIONS = "Bulk Actions",
  BULK_DISCIPLINE_FORMS = "Forms: Bulk Discipline",
  CANDIDATES = "Candidates",
  COMING_SOON = "Coming soon",
  CONFIGURATOR = "Configurator",
  DOCUMENT_VALIDITY = "Document Validity",
  DATA_FIELDS = "Data Fields",
  DATA_FLOWS = "Data Flows",
  DATA_LOGS = "Data logs",
  DATA_QUERIES = "Data Queries",
  DATA_MANAGER = "Data Manager",
  DEVICE_LOGS = "Device logs",
  DISCIPLINE = "Discipline",
  DOCUMENTS = "Documents",
  EMPLOYEES = "Employees",
  EXPORT = "Export",
  EXPORT_PROFILES = "Export Profiles",
  EXPORT_DOC_VALIDITY_DATA = "Export doc validity data",
  EXPORT_DOCS = "Export Docs",
  EXPORT_EMPLOYEE_CARDS = "Export Employee Cards",
  FACE_SHOTS = "Faceshots",
  FORMS = "Forms",
  GLOBAL_LOGS = "Global Logs",
  GROUPS = "Groups",
  INTEGRATIONS = "Integrations",
  FILES_AND_PERFORMANCE_DATA_IMPORTS = "Files and Performance Data Imports",
  JSON_CONFIG_FILES = "Config Files",
  LOGIN = "Login",
  LOGS = "Logs",
  OPEN_REQUESTS = "Open Requests",
  PEOPLE = "People",
  PERFORMANCE = "Performance",
  PPE_CONFIG = "PPE",
  PPE_INVENTORY = "PPE Inventory",
  PROCESSES = "Processes",
  PROFILES = "Profiles",
  REQUESTS = "Requests",
  REQUESTS_PPE = "PPE Requests",
  REQUEST_HANDLER = "Requests",
  REPORTING = "Reporting",
  IMPORT = "Import",
  PROFILE_IMPORTS = "Bulk Profile Updates",
  TERMINATE = "Terminate",
  TRANSFER = "Transfer",
  TRASH = "Trash",
  USERS = "Users",
  UNDER_PERFORMANCE = "Under Performance",
}

export type SubMenuSchema = {
  label: string
  iconName?: string
  screenName: UiCategoryEnum
  hasAccess: boolean
}
export type SubSubMenuSchema = Record<UiCategoryEnum, SubMenuSchema[]>

export interface MenuSchema {
  label: string
  screenName: UiCategoryEnum
  subMenus: SubMenuSchema[] | []
  subSubMenus?: SubSubMenuSchema
}

export type NavMenuAccess = {
  hasDataLogsAccess?: boolean
  hasDeviceLogsAccess?: boolean
  hasDocExportAccess?: boolean
  hasDocValidityAccess?: boolean
  hasDocumentLibraryAccess: boolean
  hasImportAccess?: boolean
  hasRequestHandlerAccess?: boolean
  hasUserManagementAccess?: boolean
  hasCandidatesAccess?: boolean
  hasEmployeesAccess?: boolean
  hasTrashAccess?: boolean
  hasBulkTerminationAccess?: boolean
  hasBulkProfileImportAccess?: boolean
  hasBulkDisciplineAccess?: boolean
  hasDataFlowConfigAccess?: boolean
  hasFieldConfigAccess?: boolean
  hasProcessConfigAccess?: boolean
  hasBillingAccess?: boolean
  hasJsonConfigFileAccess?: boolean
  hasGroupAccess?: boolean
  hasPpeStockAccess?: boolean
  hasPpeStockConfigAccess?: boolean
  hasPpeRequestAccess?: boolean
  hasAdvancedDataQueryAccess?: boolean
  hasExportAccess?: boolean
  hasAdaginAccess?: boolean
  hasAgrigisticsAccess?: boolean
  hasPaySpaceAccess?: boolean
  hasAdaginSyncTriggerAccess?: boolean
  hasAgrigisticsSyncTriggerAccess?: boolean
  hasPaySpaceSyncTriggerAccess?: boolean
  hasAdaginLogDownloadAccess?: boolean
  hasAgrigisticsLogDownloadAccess?: boolean
  hasPaySpaceLogDownloadAccess?: boolean
  hasFileAssociationAccess?: boolean
  hasPerformanceImportAccess?: boolean
}

export type ProfileNavMenuAccess = {
  hasDataManagerAccess: boolean
  hasFaceshotAccess: boolean
  hasDocPortalAccess: boolean
  hasPerformanceAccess: boolean
  hasDisciplineAccess: boolean
  hasLogsAccess: boolean
}
