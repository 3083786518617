import React, { useState } from "react"
import Icon from "@mdi/react"
import { mdiDownload } from "@mdi/js"
import { mdiFileAlertOutline, mdiFileCheckOutline } from "@mdi/js"
import _, { camelCase } from "lodash"
import Radium from "radium"

import { ColorPalette } from "../../config"
import ButtonGrey from "../BaseComponents/Buttons/ButtonGrey"
import ButtonBlue from "../BaseComponents/Buttons/ButtonBlue"
import LoadingModal from "./LoadingModal"
import AlertModalOneButton from "./AlertModalOneButton"
import FileSelectorButton from "../BaseComponents/Buttons/FileSelectorButton"
import CheckboxTabLabelled from "../BaseComponents/Checkboxes/CheckboxTabLabelled"
import {
  packageDataAndDownloadFile,
  filePicker,
  csvToJson,
  toValidPfDate,
  removeLeadingAndTrailingSpaces,
  toLowerCaseCustom,
} from "../../utils"
import { DateStandardEnum, CsvColumnConfig, FieldConfigKey } from "../../types"
import { FieldConfig } from "../../models/fieldConfig/fieldConfigModel"

type CsvFileProcessingModalProps = {
  open: boolean
  title: string
  instructions?: string
  csvColumnConfig: CsvColumnConfig[]
  fieldConfigEntity: FieldConfig
  systemFields?: string[]
  onCancel: () => void
  onSubmit: (jsonFileContent: Record<string, string>[]) => Promise<void>
}

const CsvFileProcessingModal = (props: CsvFileProcessingModalProps) => {
  const [dateStandard, setDateStandard] = useState<DateStandardEnum>(DateStandardEnum.UK)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [loadingModalMessage, setLoadingModalMessage] = useState("")
  const [warningModalState, setWarningModalState] = useState({
    warningModalOpen: false,
    warningModalHeader: "",
    warningModalMessage: "",
  })
  const [dateConventionSelectorVisible, setDateConventionSelectorVisible] = useState(false)
  const { open, title, csvColumnConfig, onCancel, onSubmit } = props
  const [transformedJsonData, setTransformedJsonData] = useState<Record<string, string>[]>([])
  const defaultPfDateFormat = "YYYY/MM/DD"

  const labelsToPfKeys = (
    jsonItem: Record<string, string>,
    fieldConfigEntity: FieldConfig,
    systemFields?: string[],
  ) => {
    Object.keys(jsonItem).forEach((csvColumnLabel) => {
      const trimmedLabel = removeLeadingAndTrailingSpaces(csvColumnLabel)
      let targetKey = ""
      for (const config of csvColumnConfig) {
        const { key, label } = config
        if (label !== trimmedLabel) {
          continue
        }
        targetKey = key
      }
      if (!targetKey) {
        const camelCaseLabel = camelCase(trimmedLabel)
        if (systemFields?.includes(camelCaseLabel)) {
          targetKey = camelCaseLabel
        }
      }
      if (!targetKey) {
        const maybeKey = fieldConfigEntity.getKeyForLabel(trimmedLabel)
        if (maybeKey) {
          targetKey = maybeKey
        }
      }

      const value = removeLeadingAndTrailingSpaces(jsonItem[csvColumnLabel])
      delete jsonItem[csvColumnLabel]
      if (targetKey) {
        jsonItem[targetKey] = value
      } else {
        jsonItem[trimmedLabel] = value
      }
    })
    return jsonItem
  }

  const ensurePfDateFormat = (jsonItem: Record<string, string>) => {
    Object.keys(jsonItem).forEach((key) => {
      const fieldConfigItemEntity = props.fieldConfigEntity.getFieldConfigItemEntity(key)
      if (fieldConfigItemEntity && fieldConfigItemEntity.isDateField()) {
        jsonItem[key] = toValidPfDate(jsonItem[key], defaultPfDateFormat, dateStandard)
      }
    })
    return jsonItem
  }

  const validate = (jsonFileContent: Record<string, string>[]) => {
    if (!jsonFileContent.length) {
      throw { code: "MissingData" }
    }
    const firstItem = jsonFileContent[0]
    const csvLabels = Object.keys(firstItem)
    const compulsoryLabels = [...csvColumnConfig].filter((config) => config.isCompulsory).map((config) => config.label)
    const missingLabels = _.difference(compulsoryLabels, csvLabels)
    if (missingLabels.length) {
      throw { code: "MissingLabels", missingLabels }
    }
  }

  const onActionButtonClick = async () => {
    try {
      if (!selectedFile) {
        throw { code: "NoSelectedFile" }
      }
      if (!transformedJsonData.length) {
        throw { code: "EmptyFile" }
      }
      const parsedTransformedData = transformedJsonData.map((jsonItem) => ensurePfDateFormat(jsonItem))
      setLoadingModalMessage("Submitting data...")
      setLoadingModalOpen(true)
      await onSubmit(parsedTransformedData)
      setLoadingModalOpen(false)
    } catch (error) {
      errorHandler(error)
    }
  }

  const getFileSelectorLabel = () => {
    let fileSelectorButtonLabel = "BROWSE AND SELECT FILE (CSV format only)"
    if (selectedFile) {
      fileSelectorButtonLabel = `CLICK TO CHOOSE A DIFFERENT FILE.`
    }
    return fileSelectorButtonLabel
  }

  const hasOneOrMoreDateFields = (jsonFileData: Record<string, string>[]) => {
    const firstItem = jsonFileData[0]
    const keys = Object.keys(firstItem)
    for (const key of keys) {
      try {
        const fieldConfigItemEntity = props.fieldConfigEntity.getFieldConfigItemEntity(key)
        if (fieldConfigItemEntity) {
          if (fieldConfigItemEntity.isDateField()) {
            return true
          }
        }
      } catch (error) {}
    }
    return false
  }

  const onFileSelect = async (
    event: React.ChangeEvent<{ value: string }>,
    fieldConfigEntity: FieldConfig,
    systemFields?: string[],
  ) => {
    try {
      const { file, type } = filePicker(event)[0]
      if (type !== "text/csv") {
        setWarningModalState({
          warningModalOpen: true,
          warningModalHeader: "Invalid format",
          warningModalMessage: "Your file must be in .csv format",
        })
        return
      }
      setSelectedFile(file)
      setLoadingModalMessage("Processing CSV data...")
      setLoadingModalOpen(true)
      const jsonFileData = (await csvToJson(file)) as Record<string, string>[]
      //@ts-ignore
      validate(jsonFileData)
      const transformedData = jsonFileData.map((jsonItem) => labelsToPfKeys(jsonItem, fieldConfigEntity, systemFields))
      setTransformedJsonData(transformedData)
      const dateConventionModalVisibility = hasOneOrMoreDateFields(transformedData)
      setDateConventionSelectorVisible(dateConventionModalVisibility)
      setLoadingModalOpen(false)
    } catch (error) {
      errorHandler(error)
    }
  }

  const errorHandler = (error: any) => {
    let warningModalHeader = "Problem"
    let warningModalMessage = "There was a problem processing your file"
    if (error.code === "MissingLabels") {
      warningModalHeader = "Invalid Template"
      warningModalMessage = `Your file is missing the following columns: ${error.missingLabels.join(", ")}`
    } else if (error.code === "MissingData") {
      warningModalHeader = "No Data"
      warningModalMessage = "Your file doesn't contain any data"
    } else if (error.code === "NoSelectedFile") {
      warningModalHeader = "No file selected"
      warningModalMessage = "Select a file to proceed."
    } else if (error.code === "EmptyFile") {
      warningModalHeader = "No data"
      warningModalMessage = "This file doesn't contain any data"
    }
    setLoadingModalOpen(false)
    setWarningModalState({
      warningModalOpen: true,
      warningModalHeader,
      warningModalMessage,
    })
  }

  let dateFormatSelector = null
  if (dateConventionSelectorVisible) {
    dateFormatSelector = (
      <>
        <CheckboxTabLabelled
          label="Are your dates in UK or US format?"
          values={[dateStandard]}
          selectorItems={Object.values(DateStandardEnum)}
          maxItems={1}
          selectionHandler={(values: any[]) => setDateStandard(values[0])}
          style={{ marginTop: 40, width: "90%" }}
        />
        <DateStandardExplainer />
      </>
    )
  }

  let modal = null
  if (open) {
    if (warningModalState.warningModalOpen) {
      modal = (
        <AlertModalOneButton
          open={true}
          dismiss={() => setWarningModalState((prevState) => ({ ...prevState, warningModalOpen: false }))}
          onClick={() => setWarningModalState((prevState) => ({ ...prevState, warningModalOpen: false }))}
          buttonLabel={"Ok"}
          header={warningModalState.warningModalHeader}
          body={warningModalState.warningModalMessage}
        />
      )
    } else {
      modal = (
        <div style={styles.screenContainer}>
          <div style={styles.cardContainer}>
            <h1 style={{ ...styles.textStyle }}>{title}</h1>
            <div
              onClick={() =>
                packageDataAndDownloadFile(
                  [...csvColumnConfig].map(({ label }) => label).join(","),
                  "template.csv",
                  "text/csv",
                )
              }
              style={styles.downloadContainer}>
              <Icon path={mdiDownload} color={ColorPalette.PRIMARY_TEXT} style={styles.buttonIconStyle} />
              <p>Download CSV template</p>
            </div>

            <p style={styles.instructionMessage}>{props.instructions}</p>

            <FileSelectorButton
              style={styles.selector}
              id="addFile"
              buttonLabel={getFileSelectorLabel()}
              fileName={selectedFile ? selectedFile.name : ""}
              fileHandler={(event) => onFileSelect(event, props.fieldConfigEntity, props.systemFields)}
              onCancelSelection={() => setSelectedFile(undefined)}
              iconPath={selectedFile ? mdiFileCheckOutline : mdiFileAlertOutline}
            />

            {dateFormatSelector}

            <div style={styles.actionButtons}>
              <ButtonGrey style={{ margin: 0 }} onClick={onCancel} disabled={false}>
                Cancel
              </ButtonGrey>
              <ButtonBlue style={{ margin: 0 }} onClick={onActionButtonClick}>
                Next
              </ButtonBlue>
            </div>
          </div>
          <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
        </div>
      )
    }
  }
  return modal
}

const DateStandardExplainer = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
    <div
      style={{
        fontSize: "0.8rem",
        paddingTop: "0.25em",
        textAlign: "center",
        color: ColorPalette.TERTIARY_TEXT,
      }}>
      <b>UK</b>&nbsp;&nbsp;-&nbsp;&nbsp;day/month/year or year/month/day
      <br />
      <b>US</b>&nbsp;&nbsp;-&nbsp;&nbsp;month/day/year or year/day/month
    </div>
  </div>
)

const styles = {
  screenContainer: {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 1000,
    overflow: "hidden",
  },
  cardContainer: {
    position: "absolute" as "absolute",
    top: window.innerHeight * 0.05,
    right: window.innerWidth * 0.34,
    zIndex: 100000,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-start",
    alignItems: "center" as "center",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    width: window.innerWidth * 0.32,
    padding: "30px 35px 30px 35px",
    borderRadius: 8,
  },
  textStyle: {
    textAlign: "center" as "center",
    alignSelf: "center",
    fontFamily: "roboto",
    fontWeight: "bold",
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: "1rem",
  },
  buttonIconStyle: {
    marginRight: 5,
    width: 15,
    height: "auto",
  },
  actionButtons: {
    display: "flex",
    alignContent: "center",
    marginTop: 40,
    gap: 20,
    width: "100%",
  },
  downloadContainer: {
    color: ColorPalette.PRIMARY_TEXT,
    margin: "10px 10px 0px 10px",
    fontWeight: "bolder",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ":active": {
      filter: "brightness(75%)",
    },
  },
  instructionMessage: { textAlign: "center" as "center", color: ColorPalette.PRIMARY_TEXT, marginTop: "0px" },
  selector: {
    height: "20vh",
    width: "92%",
    marginTop: "2em",
  },
}

export default Radium(CsvFileProcessingModal)
