import {
  applyImport,
  checkForProfileImportPlanList,
  checkForProfileImportResultList,
  getRemoteFile,
  planProfileImport,
  submitImportUploadRequest,
  submitPlanDownloadRequest,
  submitResultDownloadRequest,
} from "../providers"
import {
  AssociationId,
  AuthCredentials,
  FileFormat,
  ImportActionEnum,
  ImportPlanEvaluationItemSchema,
  ImportId,
  Username,
  ImportResultEvaluationItemSchema,
} from "../types"
import { jsonToCsv, packageAndUploadFile, writeRemoteFilesToLocalStorage } from "../utils"
import { SessionService } from "./sessionService"

export class ProfileImportService {
  submitImportUploadRequest = async (
    importRecords: Record<string, any>[],
    association: AssociationId,
    action: ImportActionEnum,
    username: Username,
    password: string,
  ): Promise<ImportId> => {
    const token = await SessionService.prepareAuthTokens(username, password)
    const importId = `${Date.now()}` as ImportId
    const { fileDir, fileName, fileFormat } = await submitImportUploadRequest(association, importId, username, token)

    let fileContent
    if (fileFormat === FileFormat.json) {
      fileContent = JSON.stringify(importRecords)
    } else if (fileFormat === FileFormat.csv) {
      fileContent = jsonToCsv(importRecords)
    }

    const fileNameAndformat = `${fileName}.${fileFormat}`
    await packageAndUploadFile([fileContent], fileNameAndformat, fileDir, fileFormat)
    await planProfileImport(association, importId, username, action, token)
    return importId
  }

  async getProfileImportPlanResults(
    association: AssociationId,
    importId: ImportId,
    authCredentials: AuthCredentials,
  ): Promise<{ isComplete: boolean; importList: ImportPlanEvaluationItemSchema[] } | void> {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { isComplete, fileConfig } = await checkForProfileImportPlanList(association, importId, username, token)
    if (isComplete) {
      const { fileDir, fileName, fileFormat } = fileConfig
      let resultsPath = `${fileDir}/${fileName}.${fileFormat}`
      const data = await getRemoteFile(resultsPath, { download: true, cacheControl: "no-cache" })
      if (!data) {
        throw { code: "MissingRecordsFile" }
      }
      return { isComplete, importList: JSON.parse(data.Body) }
    }
  }

  async downloadImportPlanList(association: AssociationId, importId: ImportId, authCredentials: AuthCredentials) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { fileDir, fileName, fileFormat } = await submitPlanDownloadRequest(association, importId, username, token)
    const fullFilePath = `${fileDir}/${fileName}.${fileFormat}`
    const docsToDownload = [{ docPath: fullFilePath, filename: `${fileName}.${fileFormat}` }]
    return await writeRemoteFilesToLocalStorage(docsToDownload)
  }

  async downloadImportResultList(association: AssociationId, importId: ImportId, authCredentials: AuthCredentials) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { fileDir, fileName, fileFormat } = await submitResultDownloadRequest(association, importId, username, token)
    const fullFilePath = `${fileDir}/${fileName}.${fileFormat}`
    const docsToDownload = [{ docPath: fullFilePath, filename: `${fileName}.${fileFormat}` }]
    return await writeRemoteFilesToLocalStorage(docsToDownload)
  }

  async getProfileImportResults(
    association: AssociationId,
    importId: ImportId,
    authCredentials: AuthCredentials,
  ): Promise<{ isComplete: boolean; importList: ImportResultEvaluationItemSchema[] } | void> {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { isComplete, fileConfig } = await checkForProfileImportResultList(association, importId, username, token)
    if (isComplete) {
      const { fileDir, fileName, fileFormat } = fileConfig
      let resultsPath = `${fileDir}/${fileName}.${fileFormat}`
      const data = await getRemoteFile(resultsPath, { download: true, cacheControl: "no-cache" })
      if (!data) {
        throw { code: "MissingRecordsFile" }
      }
      return { isComplete, importList: JSON.parse(data.Body) }
    }
  }

  async applyImport(association: AssociationId, importId: ImportId, authCredentials: AuthCredentials) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    await applyImport(association, importId, username, token)
  }
}
