import { AssociationId, IntegrationNamesEnum, ProcessTypesEnum, UnixMsString } from "../../types"
import { httpPost } from "../remoteDataProvider"

async function triggerSync(integrationName: IntegrationNamesEnum, association: AssociationId, token: string) {
  const { data } = await httpPost(
    "people_flow_core",
    `/integrations/trigger/${integrationName}/${association}`,
    {},
    token,
  )
  const { importId } = data
  return importId
}

async function bundleErrorLogs(
  association: AssociationId,
  integrationName: IntegrationNamesEnum,
  timerange: { fromUnixMsString: UnixMsString; toUnixMsString: UnixMsString },
  token: string,
) {
  const { POST_ADAGIN_EMPLOYEES, POST_AGRIGISTICS_EMPLOYEES } = ProcessTypesEnum
  const processType =
    integrationName === IntegrationNamesEnum.ADAGIN ? POST_ADAGIN_EMPLOYEES : POST_AGRIGISTICS_EMPLOYEES
  const { fromUnixMsString, toUnixMsString } = timerange
  const { data } = await httpPost(
    "people_flow_core",
    `/integrations/error-logs/${association}/${processType}/${fromUnixMsString}/${toUnixMsString}`,
    {},
    token,
  )
  return data
}

export { bundleErrorLogs, triggerSync }
