import { useState } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import { removeUnderScores, toUpperCaseCustom } from '../../../utils'
import { ColorPalette } from '../../../config/colors'
import Picker from '../../BaseComponents/Pickers/Picker'
import TextInputBasic from '../../BaseComponents/Text/TextInputBasic'

export const DataTableRow = (props: any) => {
  const [hoverMessage, setHoverMessage] = useState('')

  const showHoverMessage = () => {
    if (hoverMessage === '') {
      setHoverMessage(props.rowData.onHoverMessage)
    }
  }
  const collapseHoverMessage = () => {
    if (hoverMessage !== '') {
      setHoverMessage('')
    }
  }

  const {
    buttonWidth,
    checkbox,
    columnConfig,
    combinedSizeFactor,
    customFilter,
    disabled,
    leftComponent,
    onClick,
    rightComponent,
    rowData,
    currentCellInputFocus,
    rowStyle,
    style,
  } = props

  let messageOnHover = null
  let containerStyle = {}
  const hoverMessageBorderColour =
    rowData.hoverMessageType === 'info' ? ColorPalette.DARK_MEDIUM_GREY : ColorPalette.WARNING_RED_30PERCENT

  if (hoverMessage) {
    let messageIcon = null
    if (rowData.hoverMessageType === 'info') {
      messageIcon = <InfoIcon style={{ color: ColorPalette.DARK_MEDIUM_GREY, marginRight: '0.5em' }} />
    } else {
      messageIcon = (
        <ErrorIcon fontSize="small" style={{ color: ColorPalette.WARNING_RED_30PERCENT, marginRight: '0.5em' }} />
      )
    }

    messageOnHover = (
      <div style={styles.hoverMessageContainer}>
        {messageIcon}
        {hoverMessage}
      </div>
    )

    containerStyle = {
      border: `3px solid ${hoverMessageBorderColour}`,
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      boxShadow: '0px 3px 4px rgba(140,140,150, 0.5)',
    }
  }

  let checkboxComponent = null
  if (checkbox && rowData.disableSelect) {
    checkboxComponent = (
      <div style={style.errorCheckbox}>
        <ErrorIcon style={{ color: ColorPalette.OFF_WHITE }} />
      </div>
    )
  } else {
    checkboxComponent = <div style={{ backgroundColor: rowStyle.backgroundColor }}>{checkbox}</div>
  }

  const rowCells = generateCells(
    rowData,
    columnConfig,
    combinedSizeFactor,
    buttonWidth,
    props.trimText,
    currentCellInputFocus,
    props.onCellInputChange,
    props.onCellInputFocus,
  )

  return (
    <div style={containerStyle}>
      <div
        onMouseOver={rowData.onHoverMessage ? showHoverMessage : () => null}
        onMouseLeave={rowData.onHoverMessage ? collapseHoverMessage : () => null}
        style={style}>
        {checkboxComponent}
        {customFilter && <div style={{ backgroundColor: rowStyle.backgroundColor }}>{customFilter}</div>}
        {leftComponent}
        <ButtonGeneric
          key={`dataTable_rowItem_${rowData.id}`}
          style={rowStyle}
          disabled={disabled}
          onClick={onClick ? () => onClick(rowData) : () => null}
          label={rowCells}
        />
        <div style={{ backgroundColor: rowStyle.backgroundColor }}>{rightComponent}</div>
      </div>
      {messageOnHover}
    </div>
  )
}

function generateCells(
  rowData: any,
  columnConfig: any[],
  combinedSizeFactor: number,
  buttonWidth: number,
  trimText?: boolean,
  currentCellInputFocus?: string,
  onCellInputChange?: (rowId: string, itemId: string, value: any) => void,
  onCellInputFocus?: (rowId: string, itemId: string) => void,
): JSX.Element[] {
  return columnConfig.map((configItem, i) => {
    const item = rowData[configItem.id]
    let cell = null

    if (typeof item === 'string') {
      let str = ''
      if (!!trimText) {
        const lengthRatioLimit = 140
        const lengthRatio = buttonWidth / item.length
        const label =
          lengthRatio < lengthRatioLimit ? `${item.slice(0, Math.round(buttonWidth / lengthRatioLimit))}...` : item
        str = removeUnderScores(toUpperCaseCustom(label))
      } else {
        str = item
      }

      cell = <p style={{ textAlign: 'left' }}>{str}</p>
    } else {
      cell = item
    }

    if (typeof item === 'object') {
      const { value, selectorItems, disabled, onChange, props } = item
      const handleInputChange = (e: any) => {
        onCellInputChange
          ? onCellInputChange(rowData.id, configItem.id, e.target.value)
          : onChange(rowData.id, configItem.id, e.target.value)
      }

      if (item.compType === 'selector') {
        cell = (
          <div style={styles.pickerInput}>
            <Picker
              value={value}
              items={selectorItems}
              onChange={handleInputChange}
              noUnderline={true}
              placeholder="Select"
              style={props?.style}
              disabled={disabled}
              fontStyle={{ color: ColorPalette.SECONDARY_TEXT, fontSize: '0.8rem', ...props?.fontStyle }}
              key={`$tableRow_selectorInput_${item.key}`}
            />
          </div>
        )
      }

      if (item.compType === 'text') {
        let retainInputFocus = false
        if (currentCellInputFocus && currentCellInputFocus.length === 2) {
          const [rowId, itemId] = currentCellInputFocus
          if (rowId === rowData.id && itemId === configItem.id) {
            retainInputFocus = true
          }
        }

        cell = (
          <div style={styles.textInput}>
            <TextInputBasic
              value={value}
              disabled={disabled}
              onChange={handleInputChange}
              placeholder={props.placeholder}
              autoFocus={retainInputFocus}
              onFocus={() => (onCellInputFocus ? onCellInputFocus(rowData.id, configItem.id) : undefined)}
              style={{ fontSize: '0.8rem', ...props?.fontStyle }}
              key={`$tableRow_textInput_${item.key}`}
            />
          </div>
        )
      }
    }

    return (
      <div
        key={`${i}_${configItem.id}`}
        style={{
          ...styles.container,
          width: (buttonWidth * (configItem.sizeFactor ? configItem.sizeFactor : 1)) / combinedSizeFactor,
        }}>
        {cell}
      </div>
    )
  })
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
    padding: '0px 20px',
  },
  textInput: {
    borderRadius: 50,
    height: 30,
    width: '96%',
    padding: '0px 10px',
    backgroundColor: ColorPalette.OFF_WHITE,
    border: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -10,
  },
  pickerInput: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 50,
    width: '96%',
    height: 30,
    padding: '0px 15px',
    backgroundColor: ColorPalette.OFF_WHITE,
    border: 'none',
    boxShadow: '0px 1px 3px rgba(0, 0, 30, 0.3)',
    marginLeft: -10,
  },
  hoverMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'smaller',
    padding: '0.75em 2em',
    backgroundColor: ColorPalette.WARNING_RED_TRANSPARENT,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  errorCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(220, 20, 60, 0.25)',
    width: 45,
  },
}
