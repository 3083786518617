import {
  ConfigPayload,
  ConfigUpdateResponse,
  AssociationId,
  CognitoUser,
  RoleName,
  AsyncTaskTypesEnum,
  CognitoMessageDeliveryMedium,
} from '../../types'
import { UserProfileSchema } from '../../models'
import { GeneralErrorCodesEnum, SessionErrorCodesEnum } from '../../enums'
import { httpPost, httpGet } from '../remoteDataProvider'
import { extractErrorCode } from '../../utils'

export async function fetchSystemConfig(username: string, associationsToFetch: AssociationId[], token: string) {
  try {
    const payload: Record<string, any> = {
      idPassport: username,
      associations: associationsToFetch,
    }
    const { result, userProfile, masterSettings, clientSettings } = (
      await httpPost('people_flow_user', '/user-management/app-login', payload, token)
    ).data
    if (result !== 'success') {
      throw { code: SessionErrorCodesEnum.LoginError }
    }
    return {
      userProfile,
      masterSettings,
      clientSettings,
    }
  } catch (error) {
    throw error
  }
}

export async function updateConfig(payload: ConfigPayload, token: string) {
  const { data }: ConfigUpdateResponse = await httpPost(
    'people_flow_user',
    '/user-management/config-manager',
    payload,
    token,
  )
  if (data.result !== 'success') {
    throw { code: 'UnknownConfigUpdateError' }
  }
  return data
}

export async function getAsyncTasksByType(association: string, token: string) {
  try {
    const params = { selectedEmployer: association, type: AsyncTaskTypesEnum.DOC_EXPORT, action: 'getAllByType' }
    const { data } = await httpPost('people_flow_user', '/user-management/async-task', params, token)
    if (data.result !== 'success') {
      throw { code: 'UnknownFetchDownloadQueueError' }
    }
    return data
  } catch (error) {
    throw error
  }
}

export async function removeFromAsyncQueue(association: string, queueId: string, token: string) {
  const params = { selectedEmployer: association, action: 'deleteOne', id: queueId }
  const { data } = await httpPost('people_flow_user', '/user-management/async-task', params, token)
  if (data.result !== 'success') {
    throw { code: 'UnknownClearDownloadQueueError' }
  }
  return data
}

export async function updateDocConfig(payload: any, token: string) {
  const { data } = await httpPost('people_flow_user', '/user-management/update-doc-config', payload, token)
  if (data.result !== 'success') {
    throw { code: 'UpdateDocConfigError' }
  }
  return data
}

export async function updateDocVerificationTracker(payload: any, token: string) {
  const { data } = await httpPost('people_flow_user', '/user-management/doc-verification-tracker', payload, token)
  if (data.result !== 'success') {
    throw { code: 'UnknownUpdateDocVerificationTracker' }
  }
  return data
}

export async function triggerDocExport(
  association: string,
  batchLabel: string,
  targetDocs: Record<string, string[]>,
  token: string,
) {
  let items = [] as Record<string, any>
  Object.keys(targetDocs).forEach((idPassport) => items.push({ idPassport, docs: targetDocs[idPassport] }))

  if (!items.length) {
    throw { code: GeneralErrorCodesEnum.NoDocs }
  }
  const params = { selectedEmployer: association, batchLabel, items }
  const { data } = await httpPost('people_flow_user', '/user-management/doc-export', params, token)
  if (data.result !== 'success') {
    throw { code: 'UnknownTriggerDownloadError' }
  }
  return data
}

export async function getTimeBasedDocRecords(association: string, params: Record<string, any>, token: string) {
  let payload: Record<string, any> = {
    selectedEmployer: association,
    authedCohorts: params.cohorts,
  }
  if (params.profilePkList && Array.isArray(params.profilePkList) && params.profilePkList.length > 0) {
    params.profilePkList = params.profilePkList
  }
  payload = { ...payload, ...params }
  const { data } = await httpPost('people_flow_user', '/user-management/doc-audit-report', payload, token)
  if (data.result !== 'success') {
    throw { code: 'FetchTimeBasedDocDataError' }
  }
  return data
}

export async function triggerDisciplineFormGeneration(
  association: string,
  date: Date,
  emails: string[],
  token: string,
) {
  /**
   * Getting the start and end unixMs of the selected day.
   */
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const millisecondsPerDay = 86400000
  const dateString = `${year}/${month + 1}/${day}`
  const startUnixMs = new Date(dateString).getTime()
  const endUnixMs = startUnixMs + millisecondsPerDay - 600000

  const payload = {
    selectedEmployer: association,
    range: { startUnixMs, endUnixMs },
    emails,
  }
  const { data } = await httpPost('people_flow_user', '/user-management/discipline-generation', payload, token)
  if (data.result !== 'success') {
    throw { code: 'UnknownError' }
  }
  return data
}

export async function getUsersForAssociation(
  association: AssociationId,
  token: string,
): Promise<{ users: UserProfileSchema[]; accountRoleNames: string[] }> {
  let users = []
  let accountRoleNames = []
  try {
    console.log('association: ', association)
    const result = (await httpGet('people_flow_core', `/users/all/${association}`, token)).data as {
      userProfiles: {
        dto: UserProfileSchema
        cognitoUser: CognitoUser
        roleNames: RoleName[]
      }[]
      accountRoleNames: RoleName[]
    }
    console.log('result: ', result)
    users = result.userProfiles.map(({ dto, cognitoUser, roleNames }) => ({ ...dto, cognitoUser, roleNames }))
    accountRoleNames = result.accountRoleNames
  } catch (err) {
    return { users: [], accountRoleNames: [] }
  }
  return { users, accountRoleNames }
}

export async function createUser(
  association: AssociationId,
  userProfileForm: UserProfileSchema,
  token: string,
  messageDeliveryMediums?: CognitoMessageDeliveryMedium[],
) {
  try {
    const { username } = userProfileForm
    const payload = { userProfileForm, messageDeliveryMediums, isSelfService: false }
    const { userProfile, cognitoUser, roleNames } = (
      await httpPost('people_flow_core', `/users/${username}/new/${association}`, payload, token)
    ).data
    return { ...userProfile, cognitoUser, roleNames }
  } catch (error) {
    const errorCode = extractErrorCode(error)
    if (errorCode) {
      throw { code: errorCode }
    }
    throw error
  }
}

export async function resendTempPassword(
  username: string,
  token: string,
  messageDeliveryMediums?: CognitoMessageDeliveryMedium[],
) {
  try {
    const payload = { isSelfService: false, messageDeliveryMediums }
    await httpPost('people_flow_core', `/users/${username}/existing/resend-temp-password`, payload, token)
  } catch (error) {
    const errorCode = extractErrorCode(error)
    if (errorCode) {
      throw { code: errorCode }
    }
    throw error
  }
}

export async function updateUser(association: AssociationId, userProfileForm: UserProfileSchema, token: string) {
  try {
    const { username } = userProfileForm
    const payload = { userProfileForm, isSelfService: false }
    const { userProfile, cognitoUser, roleNames } = (
      await httpPost('people_flow_core', `/users/${username}/existing/${association}`, payload, token)
    ).data
    return { ...userProfile, cognitoUser, roleNames }
  } catch (error) {
    const errorCode = extractErrorCode(error)
    if (errorCode) {
      throw { code: errorCode }
    }
    throw error
  }
}
