import { CommonUnixMsEnum } from "../enums"
import { bundleErrorLogs, triggerSync } from "../providers"
import { AssociationId, AuthCredentials, FileConfig, IntegrationNamesEnum, UnixMsString } from "../types"
import { writeRemoteFilesToLocalStorage } from "../utils"
import { SessionService } from "./sessionService"

class IntegrationsService {
  async triggerSync(
    integrationName: IntegrationNamesEnum,
    associationId: AssociationId,
    authCredentials: AuthCredentials,
  ) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await triggerSync(integrationName, associationId, token)
  }

  async bundleErrorLogs(
    associationId: AssociationId,
    integrationName: IntegrationNamesEnum,
    authCredentials: AuthCredentials,
  ) {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const fromUnixMsString = `${Date.now() - CommonUnixMsEnum.ONE_DAY}` as UnixMsString
    const toUnixMsString = `${Date.now()}` as UnixMsString
    return await bundleErrorLogs(associationId, integrationName, { fromUnixMsString, toUnixMsString }, token)
  }

  async downloadErrorLogsFile(fileConfig: FileConfig) {
    const { filePath = "", fileName = "", fileFormat = "" } = fileConfig
    const fileNameAndFormat = `${fileName}.${fileFormat}`
    const fullFilePath = `${filePath}/${fileNameAndFormat}`
    const docsToDownload = [{ docPath: fullFilePath, filename: fileNameAndFormat }]
    return await writeRemoteFilesToLocalStorage(docsToDownload)
  }
}

export { IntegrationsService }
