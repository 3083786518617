import { PeopleFlowCombinedReducer, PersistedStateLoadHook } from '.'
import { ActionType, ReducerAction } from '../actions/actions'
import {
  IClientRequestInstance,
  ClientRequestsFactory,
  ClientRequestInstanceFactory,
  IClientRequests,
  TimeBasedDocFactory,
  IDocVerificationTracker,
  DocVerificationTrackerFactory,
  Profile,
  TimeBasedDocs,
} from '../../models'
import { DataCaptureMode, ProcessModes } from '../../config'
// import { DisciplinaryItemFactory, IDisciplineHistoryItem } from '../../models'
import { ViewModes } from '../../components/SideMenus/DocValiditySideMenu'
import { DocDownloadQueueItem } from '../../screens/Exports/DocExport'
import {
  AssociationSettingsRepository,
  MasterSettingsRepository,
  ProfileRepository,
  UsersRepository,
} from '../../repositories'
import {
  AsyncTaskDocExport,
  ValidEmploymentStatus,
  UiCategoryEnum,
  NavMenuAccess,
  EmploymentStatus,
  ProfileNavMenuAccess,
} from '../../types'

export interface PerformanceImportTracker {
  fileName: string
  code?: string
  formatErrors?: number
  timestamp: string
  incompleteData?: number
  actualColumns?: string[]
  missingColumns?: string[]
  itemCount?: number
  processing?: boolean
}

export interface ISessionState {
  activeDocumentPortalSection: string
  // allClientNames: string[]
  allClientUsers: { name: string; surname: string; idPassport: string }[]
  // allCohorts: string[]
  // allPositions: string[]
  allRequests: IClientRequests
  // allRoles: string[]
  allTimeBasedDocs: TimeBasedDocs
  comingFromDocValidity: boolean
  // currentDisciplinaryItem: IDisciplineHistoryItem
  currentScreen: UiCategoryEnum
  currentSection: UiCategoryEnum
  dataCaptureMode: DataCaptureMode
  disciplineItemUidToLoad: string
  // docImportList: any[]
  // documentImportTracker: any[]
  docValidityViewMode: ViewModes
  docVerificationTracker: IDocVerificationTracker
  docValidityFilterState: Record<string, Record<string, boolean>>
  asyncTaskQueueDocExport: AsyncTaskDocExport[]
  // env: string
  // hasDataLogsAccess: boolean
  // hasDeviceLogsAccess: boolean
  // hasDocValidityAccess: boolean
  // hasDocumentLibraryAccess: boolean
  // hasImportAccess: boolean
  // hasRequestHandlerAccess: boolean
  // hasTrashAccess: boolean
  // hasUserManagementAccess: boolean
  // hasCohortsAccess: boolean
  // hasDocMarkingAccess: boolean
  // hasBulkTerminationAccess: boolean
  idPassport: string
  isReadOnly: boolean
  itemsDownloading: string[]
  itemsBeingGenerated: DocDownloadQueueItem[]
  loading: boolean
  navMenuAccess: NavMenuAccess
  newPassword?: string
  otp?: string
  password: string
  performanceData: string
  performanceImportTracker: PerformanceImportTracker[]
  previousScreen: UiCategoryEnum
  processMode: ProcessModes
  profile: Profile
  profileNavMenuAccess: ProfileNavMenuAccess
  profilePic: string
  selectedAssociation: string
  selectedDocNames: string[]
  selectedEmploymentStatus: EmploymentStatus
  selectedFormIndex: number
  selectedRequest: IClientRequestInstance
  selectedRole: string
  selectedCohort: string
  sourceData: Record<string, any>

  associationRepo?: AssociationSettingsRepository | null
  profileRepo?: ProfileRepository | null
  userRepo?: UsersRepository | null
  masterSettingsRepo?: MasterSettingsRepository | null
}

function defaultState(): ISessionState {
  return {
    activeDocumentPortalSection: '',
    allClientUsers: [],
    allRequests: ClientRequestsFactory.create([]),
    allTimeBasedDocs: TimeBasedDocFactory.create([]),
    comingFromDocValidity: false,
    docValidityViewMode: ViewModes.ALL,
    currentScreen: UiCategoryEnum.PROFILES,
    currentSection: UiCategoryEnum.EMPLOYEES,
    dataCaptureMode: DataCaptureMode.STANDARD,
    disciplineItemUidToLoad: '',
    docVerificationTracker: DocVerificationTrackerFactory.create({}),
    docValidityFilterState: {},
    asyncTaskQueueDocExport: [],
    idPassport: '',
    isReadOnly: false,
    itemsDownloading: [],
    itemsBeingGenerated: [],
    loading: false,
    navMenuAccess: {} as NavMenuAccess,
    password: '',
    performanceData: '',
    performanceImportTracker: [],
    previousScreen: UiCategoryEnum.PROFILES,
    processMode: ProcessModes.GENERAL_EMPLOYEE,
    profile: new Profile({}),
    profileNavMenuAccess: {} as ProfileNavMenuAccess,
    profilePic: '',
    selectedAssociation: '',
    selectedCohort: '',
    selectedDocNames: [],
    selectedEmploymentStatus: EmploymentStatus.EMPLOYEE,
    selectedFormIndex: 0,
    selectedRequest: ClientRequestInstanceFactory.create({}),
    selectedRole: '',
    sourceData: {},

    associationRepo: null,
    profileRepo: null,
    userRepo: null,
    masterSettingsRepo: null,
  }
}

export default function reducer(state: ISessionState = defaultState(), action: ReducerAction): ISessionState {
  switch (action.type) {
    case ActionType.UPDATE_STATE:
      return { ...state, ...action.data }

    case ActionType.CHANGE_SCREEN:
      return { ...state, ...action.data }

    case ActionType.SET_SELECTED_ASSOCIATION:
      return { ...state, selectedAssociation: action.data.selectedAssociation.toLowerCase() }

    case ActionType.SET_SELECTED_COHORT:
      if (!action.data.selectedCohort) {
        return { ...state }
      }
      return { ...state, selectedCohort: action.data.selectedCohort }

    case ActionType.RESET_CREDENTIALS:
      return { ...state, password: '', newPassword: '', otp: '' }

    default:
      return { ...state }
  }
}

export const onSessionManagerLoad: PersistedStateLoadHook = (state: PeopleFlowCombinedReducer) => {
  if (!state.sessionManager) {
    return { ...state }
  }
  let sessionManager = { ...state.sessionManager }
  sessionManager.docVerificationTracker = DocVerificationTrackerFactory.create({})
  sessionManager.allTimeBasedDocs = TimeBasedDocFactory.create([])

  return { ...state, sessionManager }
}
