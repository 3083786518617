import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom } from '../../utils'

import React, { Component } from 'react'
import Radium from 'radium'
import Draggable, { DraggableEvent } from 'react-draggable'
import Icon from '@mdi/react'
import { mdiDragHorizontal } from '@mdi/js'

import Picker from '../BaseComponents/Pickers/PlainPicker'
import FieldConfigController from './FieldConfigController'

interface ICoordinates {
  x: number
  y: number
}

interface IFieldProps {
  allFieldOptions: any[]
  showMissingFieldWarning: boolean
  coordinates: ICoordinates
  compType: any
  disabled: boolean
  label: string
  textStyle: any
  fieldSelectionHandler: (args: string) => void
  onDrag: (...args: any[]) => void
  onStyleButtonClicked: (functionType: string, type?: string) => void
}

interface IFieldState {
  fieldControllerVisible: boolean
  isDragging: boolean
  initialX: number
  initialY: number
  hasInteracted: boolean
}

class Field extends Component<IFieldProps, IFieldState> {
  pageX?: number
  pageY?: number

  fontSizeMap: Record<string, number> = {
    TINY: 4,
    SMALL: 8,
    MEDIUM: 11,
    HUGE: 16,
  }

  componentTypeMap: Record<string, string> = {
    text: 'TEXT',
    paragraph: 'TEXT (MULTI)',
    selector: 'SELECTOR',
    date: 'DATE',
  }

  state = {
    fieldControllerVisible: false,
    isDragging: false,
    initialX: 0,
    initialY: 0,
    hasInteracted: false,
  }

  componentDidMount() {
    this.setState({
      initialX: this.props.coordinates.x > 0 ? this.props.coordinates.x : 100,
      initialY: this.props.coordinates.y > 0 ? this.props.coordinates.y : 100,
    })
  }

  toggleFieldController(fieldControllerVisible: boolean) {
    if (!this.props.disabled) {
      this.setState({ fieldControllerVisible, hasInteracted: true })
    }
  }

  onDragStart(e: DraggableEvent): void {
    this.pageX = (e as React.MouseEvent).pageX
    this.pageY = (e as React.MouseEvent).pageY
  }

  onDragStop(e: DraggableEvent): void {
    const { pageX, pageY } = e as React.MouseEvent

    if (pageX !== this.pageX || pageY !== this.pageY) {
      this.props.onDrag({
        x: pageX - (this.pageX || 0),
        y: pageY - (this.pageY || 0),
      })
    }
    this.setState({ isDragging: false })
  }

  render() {
    const { fontSizeName, fontWeight, fontStyle } = this.props.textStyle

    const textStyle = {
      fontFamily: 'roboto',
      fontStyle: fontStyle ? fontStyle : 'normal',
      fontWeight: typeof fontWeight === 'string' ? fontWeight : 'normal',
      fontSize: fontSizeName ? this.fontSizeMap[toUpperCaseCustom(fontSizeName)] : 10,
      color: ColorPalette.PRIMARY_TEXT,
    }

    let fieldController = null
    if (this.state.fieldControllerVisible && !this.state.isDragging) {
      fieldController = (
        <div style={{ ...styles.fieldConfigControllerContainer }}>
          <FieldConfigController
            componentType={this.componentTypeMap[this.props.compType]}
            fontSizeName={fontSizeName as string}
            onClick={(functionType, type) => this.props.onStyleButtonClicked(functionType, type)}
          />
        </div>
      )
    }

    return (
      <Draggable
        disabled={this.props.disabled}
        bounds="parent"
        onStop={(e) => this.onDragStop(e)}
        onDrag={() => this.setState({ isDragging: true })}
        onStart={(e) => this.onDragStart(e)}>
        <div
          onMouseEnter={() => this.toggleFieldController(true)}
          onMouseLeave={() => this.toggleFieldController(false)}
          style={{
            ...styles.container,
            left: this.state.initialX,
            top: this.state.initialY,
            backgroundColor: this.state.isDragging ? 'rgba(255,255,255,0.4)' : ColorPalette.CARD_WHITE,
            boxShadow: `0px 2px 2px ${this.state.hasInteracted ? 'rgba(0, 0, 100, 0.1)' : 'rgba(0, 200, 255, 0.8)'}`,
          }}>
          <Picker
            style={{
              ...textStyle,
              marginRight: 5,
              minWidth: 120,
              width: '82%',
              backgroundColor: this.state.isDragging ? 'rgba(255,255,255,0.4)' : ColorPalette.CARD_WHITE,
              color: this.props.showMissingFieldWarning ? ColorPalette.WARNING_RED : ColorPalette.PRIMARY_TEXT,
            }}
            value={this.props.label}
            items={this.props.allFieldOptions}
            disabled={this.props.disabled}
            onChange={(e) => this.props.fieldSelectionHandler(e)}
          />
          <Icon style={{ width: '18%' }} path={mdiDragHorizontal} size={0.9} color={ColorPalette.DARK_GREY} />
          {fieldController}
        </div>
      </Draggable>
    )
  }
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center' as 'center',
    justifyContent: 'flex-start',
    borderRadius: 4,
    height: 20,
    paddingLeft: 2,
    paddingRight: 10,
    maxWidth: 160,
    position: 'absolute' as 'absolute',
  },
  fieldConfigControllerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute' as 'absolute',
    left: -20,
    top: 20,
    zIndex: 1000,
    height: 50,
  },
}

export default Radium(Field)
