import { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import Grid, { GridSize } from '@material-ui/core/Grid'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { NavMenuAccess, UiCategoryEnum } from '../../types'
import { SessionService } from '../../services'
import NavBar from '../../components/Navigation/NavBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import Timeline from '../../components/GeneralUI/Timeline/Timeline'
import DataLogBlock from '../../components/Logs/DataLogBlock'
import { PeopleFlowCombinedReducer } from '../../store'
import { RouteComponentProps } from 'react-router'
import { AssociationSettingsRepository } from '../../repositories'
import { fetchChangeLogs } from '../../providers/remoteDataProvider'

interface GlobalProps extends RouteComponentProps {
  selectedAssociation: string
  selectedCohort: string
  idPassport: string
  password: string
  associationRepo: AssociationSettingsRepository
  currentScreen: UiCategoryEnum
  navMenuAccess: NavMenuAccess
  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface GlobalState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  sideMenuVisible: boolean
  tableWidth: GridSize
  logs: any[]
  currentBatchNumber: number
  isFirstPage?: boolean
  totalBatches: number
  screenHeight: number
}

class Global extends Component<GlobalProps, GlobalState> {
  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    screenHeight: window.innerHeight,
  }

  state: GlobalState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    sideMenuVisible: true,
    tableWidth: 10,
    logs: [],
    currentBatchNumber: 0,
    totalBatches: 0,
  }

  componentDidMount() {
    try {
      this.props.updateState({
        currentSection: UiCategoryEnum.GLOBAL_LOGS,
        currentScreen: UiCategoryEnum.DATA_LOGS,
      })
      this.refreshLogs(true)
    } catch (error) {
      this.closeModals()
    }
  }

  componentDidUpdate(prevProps: GlobalProps, prevState: GlobalState, snapshot: any) {
    const lookingAtTheSameEmployer =
      prevProps.selectedCohort === this.props.selectedCohort &&
      prevProps.selectedAssociation === this.props.selectedAssociation

    if (lookingAtTheSameEmployer) {
      return
    }

    try {
      this.refreshLogs(true)
    } catch (err) {
      this.closeModals()
    }
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  createLogsComponents(logs: any[]): JSX.Element[] {
    const { selectedAssociation, selectedCohort } = this.props
    const profilePksInCohort = this.props.associationRepo.getAuthorisedPks(selectedAssociation, selectedCohort)
    logs = logs.filter((log: any) => {
      if (!('profileIdPassport' in log)) {
        return true
      }
      const profileIdPassport = log['profileIdPassport']
      const includes = profilePksInCohort.includes(profileIdPassport)
      return includes
    })
    const logComponents = logs.map((logItem, index) => {
      return (
        <DataLogBlock
          mode={'global'}
          downloadedToDevice={logItem.downloadedToDevice}
          downloadedEverywhere={logItem.downloadedEverywhere}
          logData={logItem}
          key={`log_${logItem.profileIdPassport}_${index}`}
        />
      )
    })
    return logComponents
  }

  async refreshLogs(showLoadingModal: boolean) {
    try {
      if (showLoadingModal) {
        this.setState({
          loadingModalOpen: true,
          loadingModalMessage: 'Refreshing global logs...',
        })
      }

      const { selectedAssociation, idPassport, password, associationRepo } = this.props
      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const obj: { logs: any; currentBatchNumber: number } = await fetchChangeLogs(
        [],
        selectedAssociation,
        idPassport,
        fieldConfig,
        null,
        null,
        null,
        null,
        token,
      )
      const jsx = this.createLogsComponents(obj.logs)

      this.setState({
        ...this.initialModalState,
        logs: jsx,
        currentBatchNumber: obj.currentBatchNumber,
        totalBatches: obj.currentBatchNumber,
      })
    } catch (error) {
      this.closeModals()
      throw error
    }
  }

  async loadMoreLogs(direction: string): Promise<void> {
    if (this.state.currentBatchNumber === 0 && direction !== 'previous') {
      return new Promise((res) => res())
    }
    this.setState({
      loadingModalOpen: true,
      loadingModalMessage: 'Loading more logs...',
    })

    const { selectedAssociation, idPassport, password, associationRepo } = this.props
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    return fetchChangeLogs(
      [],
      selectedAssociation,
      idPassport,
      fieldConfig,
      this.state.currentBatchNumber + (direction === 'next' ? -1 : 1),
      null,
      null,
      null,
      token,
    )
      .then((obj: { logs: any[]; currentBatchNumber: number }) => {
        let jsx = this.createLogsComponents(obj.logs)
        this.setState(
          {
            ...this.initialModalState,
            logs: jsx,
            currentBatchNumber: obj.currentBatchNumber,
            isFirstPage: false,
          },
          this.forceUpdate,
        )
      })
      .catch((error) => {
        this.displayWarning(error)
      })
  }

  displayWarning(error: any) {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { hasImportAccess } = this.props.navMenuAccess

    return (
      <div style={{ ...styles.container, height: this.state.screenHeight, maxHeight: this.state.screenHeight }}>
        <NavBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={{ marginTop: 25 }}
          labelStyle={{ paddingLeft: 0 }}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            marginLeft: window.innerWidth * 0.16,
          }}>
          <Grid
            style={{
              ...styles.outerCard,
              height: this.state.screenHeight * 0.8,
              marginTop: this.state.screenHeight * 0.08,
            }}
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={this.state.tableWidth}>
            <Timeline
              logs={this.state.logs}
              shouldPaginate={this.state.currentBatchNumber !== 0}
              isFirstPage={this.state.currentBatchNumber === this.state.totalBatches ? true : false}
              isLastPage={this.state.currentBatchNumber === 1 ? true : false}
              loadPrevious={() => this.loadMoreLogs('previous')}
              loadNext={() => this.loadMoreLogs('next')}
            />
          </Grid>
        </div>

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
        <AlertModalOneButton
          open={!hasImportAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view employee/candidate info."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.SCREEN_TOP_GRADIENT}, ${ColorPalette.SCREEN_BOTTOM_GRADIENT})`,
    overflowX: 'hidden' as 'hidden',
    overflowY: 'hidden' as 'hidden',
  },
  outerCard: {
    zIndex: 1,
    alignSelf: 'center',
    backgroundColor: ColorPalette.CARD_WHITE,
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    overflow: 'hidden',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    passpord: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedCohort: state.sessionManager.selectedCohort,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentScreen: state.sessionManager.currentScreen,
    profilePic: state.sessionManager.profilePic,
    navMenuAccess: state.sessionManager.navMenuAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Global))
