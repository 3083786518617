import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useHistory, RouteComponentProps } from "react-router"

import { navButtonsWithCustomActions, systemFields_Primary, systemFields_GeneralData } from "../../config"
import {
  UiCategoryEnum,
  MenuSchema,
  SelectorConfig,
  SelectorItemsConfig,
  EmploymentStatus,
  FileFormat,
  ImportActionEnum,
  IntegrationNamesEnum,
} from "../../types"
import { toLowerCaseCustom, checkEmailFormat, addUnderScores, toUpperCaseCustom } from "../../utils"
import { ActionType } from "../../store/actions/actions"
import NavButton from "../BaseComponents/Buttons/NavButton"
import logoHeader from "../../assets/logoHeader2.png"
import AccountManagementButton from "../BaseComponents/Buttons/AccountManagementButton"
import AccountManagementModal from "../Modals/AccountManagementModal"
import ForgotPasswordModal from "../Modals/ForgotPassword"
import OptionsSelectorModal from "../Modals/OptionsSelectorModal"
import CsvFileProcessingModal from "../Modals/CsvFileProcessingModal"
import AlertModalOneButton from "../Modals/AlertModalOneButton"
import LoadingModal from "../Modals/LoadingModal"
import InfoCollectorModal from "../Modals/InfoCollector"
import WithRouterHistory from "../Routing/WithRouterHistory"
import { PeopleFlowCombinedReducer } from "../../store"
import { PickerHandlerEvent } from "../BaseComponents/Pickers/Picker"
import { SessionService, ExportService, NavBarService, ConfigService, IamService } from "../../services"
import HierarchialPicker from "../Modals/HierarchialPickerModal"
import { AssociationSettingsRepository, ProfileRepository, UsersRepository } from "../../repositories"
import { GeneralErrorCodesEnum } from "../../enums/errorCodeTypes"
import { ProfileImportService } from "../../services/profileImportService"
import { buildAdHocColumnConfig, buildTerminationColumnConfig } from "../../providers/csvColumnConfigProvider"
import { IntegrationsService } from "../../services/integrationsService"

const exportService = new ExportService()
const sessionService = new SessionService()
const navBarService = new NavBarService()
const configService = new ConfigService()
const iamService = new IamService()
const profileImportService = new ProfileImportService()
const integrationsService = new IntegrationsService()

const subMenuEmploymentStatusMap = {
  [UiCategoryEnum.EMPLOYEES]: EmploymentStatus.EMPLOYEE,
  [UiCategoryEnum.CANDIDATES]: EmploymentStatus.CANDIDATE,
} as Record<string, EmploymentStatus>

/**
TODO: Use one OptionSelectorModal instance along with the required function handlers. i.e. Don't repeat OptionSelectorModal.
*/
const { xlsx, txt, csv } = FileFormat
const availableExportFormats = [xlsx, txt, csv]
type MenuActions = "exportTemplate" | "exportFormat"

interface NavBarProps extends RouteComponentProps {
  primaryTableRef?: React.RefObject<any>
  reloadPageData?: () => void
}

const NavBar = (props: NavBarProps) => {
  const location = useLocation()
  const history = useHistory()

  const initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    dataOptionsSelectorModalOpen: false,
    emailCollectorModalOpen: false,
    docAuditReportEmailCollectorModalOpen: false,
    bulkDisciplineEmailCollectorModalOpen: false,
    bulkDisciplineDateModalOpen: false,
    templateOptionsSelectorModalOpen: false,
    terminationModalOpen: false,
    profileImportModalOpen: false,
    docAuditReportExportSuccessModalOpen: false,
    messageModalHeader: "",
    messageModalMessage: "" as string | JSX.Element,
    loadingModalAuxMessage: "",
    loadingModalMessage: "",
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [disciplineWarningMessage, setDisciplineWarningMessage] = useState("")
  const [menuStructure, setMenuStructure] = useState([] as MenuSchema[])
  const [exportTemplate, setExportTemplate] = useState("")
  const [exportFormat, setExportFormat] = useState(FileFormat.xlsx)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [availableExportConfigs, setAvailableExportConfigs] = useState<string[]>([])

  const [refreshTimestamp, setRefreshTimestamp] = useState<Date>(new Date())
  const [accountSettingsPickerConfig, setAccountSettingsPickerConfig] = useState({
    selectorConfig: [],
    selectorItemsConfig: {},
  } as { selectorConfig: SelectorConfig; selectorItemsConfig: SelectorItemsConfig })

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UsersRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const currentScreen = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentScreen)
  const selectedEmploymentStatus = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedEmploymentStatus,
  )

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })
  const setSelectedAssociation = (selectedAssociation: string) =>
    dispatch({ type: ActionType.SET_SELECTED_ASSOCIATION, data: { selectedAssociation } })
  const setSelectedCohort = (selectedCohort: string) =>
    dispatch({ type: ActionType.SET_SELECTED_COHORT, data: { selectedCohort } })

  useEffect(() => {
    initialise()
    const { pathname, state = {} } = props.history.location
    const accountPickerConfig = configService.generateHierarchialAccountPickerConfig(associationRepo, {
      selectedAssociation,
      selectedCohort,
    })
    setAccountSettingsPickerConfig(accountPickerConfig)
    // @ts-ignoretriggerUnixMs
    if (pathname === "/people/employees" && state.openTerminationModal) {
      setModalState({ ...initialModalState, terminationModalOpen: true })
    }
  }, [])

  useEffect(() => {
    if (!selectedAssociation || !selectedCohort) {
      return
    }
    initialise()
  }, [selectedAssociation, selectedCohort])

  const initialise = () => {
    const iamEntity = userRepo.getCurrentUserEntity().getAssocationIamEntity(selectedAssociation)
    if (!iamEntity) {
      console.log("No IAM entity found on the user's profile for the selected association")
      return
    }
    const navMenuAccess = iamService.buildNavMenuAccess(iamEntity, selectedCohort)
    const menuStructure = navBarService.buildMenuTabConfig(navMenuAccess)
    const availableExportConfigs = associationRepo.getAvailableExportConfigs(selectedAssociation)
    updateState({ navMenuAccess })
    setMenuStructure(menuStructure)
    setExportTemplate(availableExportConfigs.length ? availableExportConfigs[0] : "")
    setAvailableExportConfigs(availableExportConfigs)
  }

  const closeModals = () => {
    setModalState(initialModalState)
  }

  const forgotPassword = async () => {
    closeModals()
    setTimeout(() => setModalState((modalState) => ({ ...modalState, forgotPasswordModalOpen: true })), 250)
    await sessionService.forgotPassword(idPassport)
  }

  const openSettings = () => {
    if (currentScreen !== UiCategoryEnum.DATA_MANAGER) {
      setModalState({ ...initialModalState, settingsModalOpen: true })
      return
    }
    const messageModalHeader = "Disabled"
    const messageModalMessage = "Setting changes are disabled while you're busy on this screen."
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      messageModalHeader,
      messageModalMessage,
    })
  }

  const settingsChangedHandler = async (targetSettings: Record<string, string>) => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: "Refreshing...",
    })
    let { association, cohort } = targetSettings
    association = addUnderScores(toLowerCaseCustom(association))
    cohort = toUpperCaseCustom(cohort)
    const hasAssociationChange = association !== selectedAssociation
    const hasCohortChange = cohort !== selectedCohort
    if (!hasAssociationChange && !hasCohortChange) {
      closeModals()
      return
    }
    setTimeout(async () => {
      if (hasAssociationChange) {
        const newAssociationSettingsJson = await sessionService.hydrateAssociationSettings([association], {
          username: idPassport,
          password,
        })
        await associationRepo.initialise(newAssociationSettingsJson)
        const newProfileRepo = new ProfileRepository()
        await newProfileRepo.loadLocalProfileEntities(association)
        updateState({ profileRepo: newProfileRepo })
      }
      setSelectedAssociation(association)
      setSelectedCohort(cohort)
      await sessionService.saveAppLaunchSettings(association, cohort)

      const { pathname } = location
      const onPeopleListingPage = pathname === "/people/employees" || pathname === "/people/candidates"
      if (!onPeopleListingPage) {
        history.push("/people/employees")
      }
      if (props.reloadPageData) {
        props.reloadPageData()
      }
      closeModals()
    }, 200)
  }

  const exportPicker = (event: PickerHandlerEvent, keyName: MenuActions) => {
    if (keyName === "exportTemplate") {
      setExportTemplate(event.target.value)
    } else if (keyName === "exportFormat") {
      setExportFormat(event.target.value)
    }
  }

  const exportHandler = (type: string) => {
    if (type === "profileData") {
      setModalState({ ...initialModalState, emailCollectorModalOpen: true })
    } else if (type === "bulkDiscipline") {
      setModalState({ ...initialModalState, bulkDisciplineEmailCollectorModalOpen: true })
    }
  }

  const dateHandler = (selectedDate: Date) => {
    const currentDate = new Date()
    if (selectedDate.getTime() > currentDate.getTime() && selectedDate.getDay() !== currentDate.getDay()) {
      setDisciplineWarningMessage("That's in the future")
    } else {
      setSelectedDate(selectedDate)
      setDisciplineWarningMessage("")
    }
  }

  const getSelectedProfileIdPassports = (): string[] | undefined => {
    let selected = [] as string[]
    if (!props.primaryTableRef) {
      return undefined // indicates that we are not on the Profiles pages
    }
    if (props.primaryTableRef.current) {
      selected = Object.keys(props.primaryTableRef.current.getSelectedRows())
    }
    return selected
  }

  const exportProfiles = async (emailAddresses: string[]): Promise<void> => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: "Exporting...",
      })
      const selectedProfileIdPassports = getSelectedProfileIdPassports()

      if (selectedProfileIdPassports === undefined) {
        throw { code: GeneralErrorCodesEnum.NoExportProfilesSelectedFromProfilesPage }
      }

      if (!selectedProfileIdPassports?.length) {
        throw { code: GeneralErrorCodesEnum.NoExportProfilesSelected }
      }
      exportService.exportRemoteData(
        selectedAssociation,
        selectedProfileIdPassports,
        exportTemplate,
        exportFormat,
        emailAddresses,
        { username: idPassport, password },
      )
      setTimeout(() => {
        /**
         * Added to enhance the UX as it is disconcerting when an immediate result is given. (Makes the user feel as though nothing happened).
         */
        displayWarning({ code: "ExportTriggered" })
      }, 500)
    } catch (error) {
      displayWarning(error)
    }
  }

  const exportDocAuditReports = async (emailAddresses: string[]): Promise<void> => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: "Generating reports...",
    })
    const allCohorts = associationRepo.getAssociationSettingsEntity(selectedAssociation).getAllCohorts()
    exportService.exportDocAuditReports(selectedAssociation, emailAddresses, allCohorts, {
      username: idPassport,
      password,
    })
    setModalState({
      ...initialModalState,
      docAuditReportExportSuccessModalOpen: true,
      messageModalHeader: "Success",
      messageModalMessage: (
        <p>
          Your report is being generated and will be sent out shortly.
          <br />
          Check your inbox in a few minutes.
        </p>
      ),
    })
  }

  const generateBulkDisciplineForms = async (emailAddresses: string[]): Promise<void> => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: "Generating forms...",
      })
      await exportService.generateBulkDisciplineForms(selectedAssociation, selectedDate, emailAddresses, {
        username: idPassport,
        password,
      })

      displayWarning({ code: "GenerateBulkDisciplineSuccess" })
    } catch (error) {
      displayWarning(error)
    }
  }

  const emailSuccessHandler = (addresses: string[]) => {
    const { emailCollectorModalOpen, docAuditReportEmailCollectorModalOpen, bulkDisciplineEmailCollectorModalOpen } =
      modalState

    if (emailCollectorModalOpen) {
      exportProfiles(addresses)
    } else if (docAuditReportEmailCollectorModalOpen) {
      exportDocAuditReports(addresses)
    } else if (bulkDisciplineEmailCollectorModalOpen) {
      generateBulkDisciplineForms(addresses)
    }
  }

  const onMenuClick = (menuName: string, subMenuName?: string, subSubMenuName?: string, hasAccess?: boolean) => {
    if (!hasAccess) {
      displayWarning({ code: "NotAuthorised" })
      return
    }

    if (subSubMenuName !== undefined) {
      if (navButtonsWithCustomActions.includes(subSubMenuName)) {
        if (subSubMenuName === UiCategoryEnum.EXPORT_PROFILES) {
          setModalState({ ...initialModalState, templateOptionsSelectorModalOpen: true })
        }
        if (subSubMenuName === UiCategoryEnum.EXPORT_DOC_VALIDITY_DATA) {
          setModalState({ ...initialModalState, docAuditReportEmailCollectorModalOpen: true })
        }
        if (subSubMenuName === UiCategoryEnum.EXPORT_DOCS) {
          props.history.push("/exportdocs")
        }
        if (subSubMenuName === UiCategoryEnum.EXPORT_EMPLOYEE_CARDS) {
          props.history.push("/employeecards")
        }
        if (subSubMenuName === UiCategoryEnum.UNDER_PERFORMANCE) {
          setModalState({ ...initialModalState, bulkDisciplineDateModalOpen: true })
        }
        if (subSubMenuName === UiCategoryEnum.FILES_AND_PERFORMANCE_DATA_IMPORTS) {
          props.history.push("/imports/filesandperformancedataimports")
        }
        if (subSubMenuName === UiCategoryEnum.PROFILE_IMPORTS) {
          updateState({ currentSection: subMenuName, currentScreen: menuName })
          setModalState({ ...initialModalState, profileImportModalOpen: true })
        }
        if (subSubMenuName === UiCategoryEnum.ADAGIN_SYNC) {
          triggerSync(IntegrationNamesEnum.ADAGIN)
        }
        if (subSubMenuName === UiCategoryEnum.AGRIGISTICS_SYNC) {
          triggerSync(IntegrationNamesEnum.AGRIGISTICS)
        }
        if (subSubMenuName === UiCategoryEnum.PAYSPACE_SYNC) {
          triggerSync(IntegrationNamesEnum.PAYSPACE)
        }
        if (subSubMenuName === UiCategoryEnum.ADAGIN_LOGS) {
          downloadIntegrationLogs(IntegrationNamesEnum.ADAGIN)
        }
        if (subSubMenuName === UiCategoryEnum.AGRIGISTICS_LOGS) {
          downloadIntegrationLogs(IntegrationNamesEnum.AGRIGISTICS)
        }
        if (subSubMenuName === UiCategoryEnum.PAYSPACE_LOGS) {
          downloadIntegrationLogs(IntegrationNamesEnum.PAYSPACE)
        }
      }
      return
    }
    if (subMenuName !== undefined && navButtonsWithCustomActions.includes(subMenuName)) {
      if (subMenuName === UiCategoryEnum.TERMINATE) {
        updateState({ currentSection: subMenuName, currentScreen: menuName })
        setModalState({ ...initialModalState, terminationModalOpen: true })
      }
      return
    }
    let employmentStatus = selectedEmploymentStatus
    if (subMenuName && subMenuEmploymentStatusMap[subMenuName]) {
      employmentStatus = subMenuEmploymentStatusMap[subMenuName]
    }
    updateState({
      selectedEmploymentStatus: employmentStatus,
      currentScreen: menuName,
      currentSection: subMenuName,
    })
  }

  const generateNavButtons = (): JSX.Element[] => {
    return menuStructure.map((menuItem: MenuSchema, i) => {
      return (
        <NavButton
          key={`${i}_${menuItem.label}`}
          label={menuItem.label}
          subMenus={menuItem.subMenus}
          subSubMenus={menuItem.subSubMenus}
          buttonsWithCustomActions={navButtonsWithCustomActions}
          primaryTableRef={props.primaryTableRef}
          onMenuClick={(menuName: string, subMenuName?: string, subSubMenuName?: string, hasAccess?: boolean) =>
            onMenuClick(menuName, subMenuName, subSubMenuName, hasAccess)
          }
          style={{
            marginRight: 10,
            height: "100%",
            zIndex: 10,
          }}
        />
      )
    })
  }

  const initiateDataImport = async (importRecords: Record<string, string>[], action: ImportActionEnum) => {
    const importId = await profileImportService.submitImportUploadRequest(
      importRecords,
      selectedAssociation,
      action,
      idPassport,
      password,
    )
    closeModals()
    history.push("/imports/profiles", { importId, action })
  }

  const downloadIntegrationLogs = async (integrationName: IntegrationNamesEnum) => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: "Bundling logs...",
      })
      const fileConfig = await integrationsService.bundleErrorLogs(selectedAssociation, integrationName, {
        username: idPassport,
        password,
      })
      console.log("fileConfig", fileConfig)
      setModalState({
        ...modalState,
        loadingModalMessage: "Downloading logs...",
      })
      await integrationsService.downloadErrorLogsFile(fileConfig)
      setModalState({
        ...initialModalState,
        warningModalOpen: true,
        messageModalHeader: "Download complete",
        messageModalMessage: "A file containing all of today's error logs has been downloaded.",
      })
    } catch (error) {
      displayWarning(error)
    }
  }

  const triggerSync = async (integrationName: IntegrationNamesEnum) => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: "Triggering sync...",
      })
      const importId = await integrationsService.triggerSync(integrationName, selectedAssociation, {
        username: idPassport,
        password,
      })
      const action = ImportActionEnum.ADHOC
      closeModals()
      history.push("/imports/profiles", { importId, action })
    } catch (error) {
      displayWarning({ code: "InvalidIntegrationTrigger" })
    }
  }

  const logout = async () => {
    props.history.replace("/")
  }

  const displayWarning = (error: any) => {
    let header = "Warning"
    let warning = ""
    try {
      if (error.code === "NetworkError") {
        warning = "Seems like your internet connection is down. Reconnect to the network, then try again."
      } else if (error.code === "NoWebAccess") {
        header = "Not authorised"
        warning = "You are not authorised to perform this function."
      } else if ("message" in error) {
        warning =
          "The following error message was returned:\n\n'" +
          error.message +
          "'. \n\n Refresh the page and try again. If unsuccessful, then contact tech support"
      } else if (error.code === "NotAuthorisedBulkDiscipline") {
        header = "Not authorised"
        warning = "You don't have permission to generate discipline forms."
      } else if (error.code === "ExportDocAuditReportError") {
        warning = `We ran into a problem while generating your report. Try logging out and in, then retry. If still unsuccessful, then reach out to tech support for assistance.`
      } else if (error.code === "ExportTriggered") {
        header = "Triggered export"
        warning = `Your export has been triggered. (It will take a few minutes before it reaches your inbox).`
      } else if (error.code === "NoExportProfilesSelected") {
        header = "Make a selection"
        warning = `No CANDIDATE/EMPLOYEES selected`
      } else if (error.code === "InvalidIntegrationTrigger") {
        header = "Invalid integration trigger"
        warning = `That integration trigger is invalid. Reach out to customer support to correctly configure system permissions.`
      } else if (error.code === "NoExportProfilesSelectedFromProfilesPage") {
        header =
          'Visit the "CANDIDATES" or "EMPLOYEES" screens, contained under the "PEOPLE" menu, to select profiles for export.'
        warning =
          "You haven't selected any items to export from the employee or canditates pages.  You'll be redirected there."
      } else if (error.code === "GenerateBulkDisciplineSuccess") {
        header = "Success"
        warning = `Your forms are being generated.`
      } else if (error.code === "NoRecords") {
        header = "Bulk termination"
        warning = "No records found after file processing"
      } else if (error.code === "NotAuthorised") {
        header = "Unauthorised"
        warning = `You don't have permission to perform this action.`
      } else {
        warning = "We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support"
      }
    } catch (error) {
      warning = "We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support"
    }
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      messageModalHeader: header,
      messageModalMessage: warning,
    })
  }

  let backButton = null
  const {
    accountModalOpen,
    settingsModalOpen,
    bulkDisciplineDateModalOpen,
    bulkDisciplineEmailCollectorModalOpen,
    docAuditReportEmailCollectorModalOpen,
    emailCollectorModalOpen,
    forgotPasswordModalOpen,
    loadingModalOpen,
    loadingModalMessage,
    loadingModalAuxMessage,
    templateOptionsSelectorModalOpen,
    terminationModalOpen,
    profileImportModalOpen,
    warningModalOpen,
    docAuditReportExportSuccessModalOpen,
  } = modalState
  const navButtons = generateNavButtons()
  const user = userRepo?.getCurrentUserEntity()
  const fieldConfigEntity = associationRepo.getFieldConfigEntity(selectedAssociation)
  if (!user) {
    return null
  }

  const { name = "", surname = "", mobile = "", email = "" } = user.getPersonalUserInfo()
  const anyEmailCollectorModalOpen =
    emailCollectorModalOpen || docAuditReportEmailCollectorModalOpen || bulkDisciplineEmailCollectorModalOpen
  let settingsModal = null
  if (settingsModalOpen) {
    settingsModal = (
      <HierarchialPicker
        config={accountSettingsPickerConfig}
        onActionButtonPress={(selectedValues: Record<string, string>) => settingsChangedHandler(selectedValues)}
        key={`settingsModal`}
      />
    )
  }
  return (
    <div style={styles.container}>
      {backButton}
      <img src={logoHeader} alt="PeopleFlow" style={styles.logoStyle} />
      <div style={styles.navButtonContainer}>
        {navButtons}
        <AccountManagementButton
          style={styles.accountManagementButton}
          name={name}
          surname={surname}
          selectedAssociation={selectedAssociation}
          selectedCohort={selectedCohort}
          onClick={() =>
            setModalState((modalState) => ({ ...modalState, accountModalOpen: !modalState.accountModalOpen }))
          }
        />
      </div>
      <AccountManagementModal
        open={accountModalOpen}
        name={name}
        surname={surname}
        email={email}
        mobile={mobile}
        idPassport={idPassport}
        dismiss={() => closeModals()}
        onSettingsClicked={() => openSettings()}
        onLogoutClicked={() => logout()}
        onForgotPasswordClicked={() => forgotPassword()}
      />
      {settingsModal}
      <OptionsSelectorModal
        open={templateOptionsSelectorModalOpen}
        onSuccess={() => exportHandler("profileData")}
        onReject={() => closeModals()}
        successLabel={"Export"}
        cancelLabel={"Cancel"}
        label1={"Export template"}
        options1={availableExportConfigs}
        optionsName1="exportTemplate"
        selectedOption1={exportTemplate}
        label2={"Export format"}
        options2={availableExportFormats}
        optionsName2="exportFormat" // TODO: Check - should this be set to something else?
        selectedOption2={exportFormat}
        onChange={(e, name) => exportPicker(e, name as MenuActions)}
      />
      <OptionsSelectorModal
        open={bulkDisciplineDateModalOpen}
        onSuccess={() => exportHandler("bulkDiscipline")}
        onReject={closeModals}
        warningMessage={disciplineWarningMessage}
        successLabel="Next"
        cancelLabel="Cancel"
        dateLabel="Date of underperformance"
        showCalendar={true}
        selectedDate={selectedDate}
        handleDateChange={(selectedDate) => dateHandler(selectedDate)}
      />
      <InfoCollectorModal
        open={anyEmailCollectorModalOpen}
        defaultItems={[email]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={closeModals}
        onSuccess={(addresses) => emailSuccessHandler(addresses)}
        onReject={closeModals}
      />
      <ForgotPasswordModal open={forgotPasswordModalOpen} idPassport={idPassport} dismiss={closeModals} />
      <WithRouterHistory>
        {(history: RouteComponentProps["history"]) => {
          return (
            <>
              <CsvFileProcessingModal
                open={terminationModalOpen}
                title="Termination Data"
                instructions="Download and complete the CSV template."
                csvColumnConfig={buildTerminationColumnConfig(fieldConfigEntity)}
                fieldConfigEntity={fieldConfigEntity}
                systemFields={[...Object.keys(systemFields_Primary), ...Object.keys(systemFields_GeneralData)]}
                onSubmit={(importRecords) => initiateDataImport(importRecords, ImportActionEnum.TERMINATE)}
                onCancel={closeModals}
                key={`terminationDataUploadModal_${refreshTimestamp}`}
              />

              <CsvFileProcessingModal
                open={profileImportModalOpen}
                title="Import Data"
                instructions="Download and complete the CSV template."
                csvColumnConfig={buildAdHocColumnConfig(fieldConfigEntity)}
                fieldConfigEntity={associationRepo.getFieldConfigEntity(selectedAssociation)}
                systemFields={[...Object.keys(systemFields_Primary), ...Object.keys(systemFields_GeneralData)]}
                onSubmit={(importRecords) => initiateDataImport(importRecords, ImportActionEnum.ADHOC)}
                onCancel={closeModals}
                key={`adHocDataUploadModal_${refreshTimestamp}`}
              />
            </>
          )
        }}
      </WithRouterHistory>
      <AlertModalOneButton
        open={warningModalOpen || docAuditReportExportSuccessModalOpen}
        header={modalState.messageModalHeader}
        body={modalState.messageModalMessage}
        buttonLabel="Ok"
        onClick={closeModals}
      />
      <LoadingModal
        open={loadingModalOpen}
        loadingMessage={loadingModalMessage}
        auxilliaryMessage={loadingModalAuxMessage}
      />
    </div>
  )
}

const styles = {
  container: {
    height: 85,
    display: "flex",
    alignItems: "center",
    paddingLeft: "1.5%",
  },
  logoStyle: {
    marginLeft: 10,
    marginTop: 40,
    height: 60,
  },
  accountManagementButton: {
    marginTop: 20,
    marginLeft: 40,
    marginRight: 25,
  },
  navButtonContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    height: window.innerHeight * 0.05,
  },
}

export default NavBar
