export type AssociationId = string
export type CohortName = string
export type Username = string
export enum TrashStatus {
  NONE = "NONE",
  TRASHED = "TRASHED",
  PERMANENT = "PERMANENT",
}
export enum EmploymentStatus {
  EMPLOYEE = "EMPLOYEE",
  CANDIDATE = "CANDIDATE",
}
export enum DateStandardEnum {
  UK = "UK",
  US = "US",
}

export enum FileFormat {
  json = "json",
  csv = "csv",
  xlsx = "xlsx",
  txt = "txt",
}

export enum FileEncodingEnum {
  application_json = "application/json",
  text_csv = "text/csv",
  text_plain = "text/plain",
  application_xml = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export type FileName = string

export type BlobStoreParams = {
  region?: string
  bucket?: string
}

export type FileConfig = {
  filePath?: Filepath
  fileName?: FileName
  fileFormat?: FileFormat
} & BlobStoreParams

export type Filepath = string
