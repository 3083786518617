import { Component, createRef } from 'react'
import { ScrollView } from '@cantonjs/react-scroll-view'
import Grid from '@material-ui/core/Grid'

import { ColorPalette } from '../../config/colors'
import TextInputLabelled from '../BaseComponents/Text/TextInputLabelled'
import PickerContained from '../BaseComponents/Pickers/PickerContained'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { ItemSelectorTextBox } from '../GeneralUI/ItemSelectorTextBox/ItemSelectorTextBox'
import { ConditionalSelectorItems, FieldConfigItemSchema } from '../../types'

interface DataFlowSummaryProps {
  style: Object
  fieldConfig: Record<string, Record<string, any>>
  data: Record<string, any>
  sectionLabel: string
  sectionFlow: FieldConfigItemSchema[]
  fieldWarnings: Record<string, string>
  highlightEmpty: boolean
  dataFlowDisabled: boolean
  verifiedFields: Record<string, string>
  maxItemsPerRow?: number

  selectionHandler: (
    values: string[],
    key: string,
    workflowBranch: boolean,
    autoPopulate: Record<string, Record<string, any>>,
    isPriField: boolean,
  ) => void
  textHandler: (
    text: string,
    key: string,
    validationType: string | undefined,
    compulsory: boolean,
    autoPopulate: Record<string, Record<string, any>>,
    isPriField: boolean,
  ) => void
}

class DataFlowSummary extends Component<DataFlowSummaryProps> {
  containerRef = createRef<HTMLDivElement>()

  state = {
    mounted: false,
  }

  componentDidMount(): void {
    this.setState({ mounted: true })
  }

  verificationLabelMap = {
    saId: 'Verify',
  }

  textHandler(
    text: string,
    key: string,
    validationType: string | undefined,
    compulsory: boolean = false,
    autoPopulate: Record<string, Record<string, any>> = {},
    isPriField: boolean = false,
  ) {
    this.props.textHandler(text, key, validationType, compulsory, autoPopulate, isPriField)
  }

  selectionHandler(
    values: string[] = [],
    key: string,
    workflowBranch: boolean = false,
    autoPopulate: Record<string, Record<string, any>> = {},
    isPriField: boolean = false,
  ) {
    this.props.selectionHandler(values, key, workflowBranch, autoPopulate, isPriField)
  }

  extractConditionalSelectors(conditionalSelectorItems: ConditionalSelectorItems, data: Record<string, any>): string[] {
    let priFieldValue = data[conditionalSelectorItems.priFieldKey]
    if (priFieldValue !== undefined && priFieldValue in conditionalSelectorItems.values) {
      return conditionalSelectorItems.values[priFieldValue]
    }
    return []
  }

  initialiseRenderItem(item: FieldConfigItemSchema): JSX.Element {
    let { fieldConfig, data, fieldWarnings, highlightEmpty, dataFlowDisabled, verifiedFields } = this.props
    const key = item.key
    const value = data[key]

    if (key in fieldConfig === false) {
      return <></>
    }

    const defaultValue = item.defaultValue !== undefined ? item.defaultValue : fieldConfig[key].defaultValue
    const compType = item.compType !== undefined ? item.compType : fieldConfig[key].compType
    const label = item.label !== undefined ? item.label : fieldConfig[key].label
    const validationType = item.validationType !== undefined ? item.validationType : fieldConfig[key].validationType
    const compulsory = item.compulsory !== undefined ? item.compulsory : fieldConfig[key].compulsory
    const maxLength =
      item.maxLength !== undefined
        ? item.maxLength
        : fieldConfig[key].maxLength !== undefined
        ? fieldConfig[key].maxLength
        : 50
    const dateFormat = item.dateFormat !== undefined ? item.dateFormat : fieldConfig[key].dateFormat
    const multiline = item.multiline !== undefined ? item.multiline : fieldConfig[key].multiline
    const selectorItems = item.selectorItems !== undefined ? item.selectorItems : fieldConfig[key].selectorItems
    const conditionalSelectorItems = item.conditionalSelectorItems
      ? item.conditionalSelectorItems
      : fieldConfig[key].conditionalSelectorItems
    const workflowBranch = item.workflowBranch !== undefined ? item.workflowBranch : false
    const maxItems = item.maxItems !== undefined ? item.maxItems : fieldConfig[key].maxItems
    const disabled = item.disabled || dataFlowDisabled || verifiedFields[key] ? true : false
    const autoPopulate = item.autoPopulate ? item.autoPopulate : fieldConfig[key].autoPopulate
    const isPriField = item.isPriField !== undefined ? item.isPriField : fieldConfig[key].isPriField
    const singleSelectionMode =
      item.singleSelectionMode !== undefined ? item.singleSelectionMode : fieldConfig[key].singleSelectionMode

    if (!fieldConfig[key]) {
      fieldConfig[key] = {}
    } // Needed to prevent errors when encountering a misconfigured field

    switch (compType) {
      case 'text':
        return (
          <TextInputLabelled
            containerStyle={{ width: item.multiline ? '100%' : '50%', marginTop: 10 }}
            inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
            key={item.key}
            label={label}
            icon={item.iconName}
            value={value}
            defaultValue={defaultValue}
            // maxLength={maxLength}
            warning={fieldWarnings[key]}
            highlightEmpty={highlightEmpty}
            dateFormat={dateFormat}
            multiline={multiline}
            disabled={disabled}
            textHandler={(value, isMount) =>
              this.textHandler(value, key, validationType, compulsory, autoPopulate, isPriField && !isMount)
            }
          />
        )

      case 'picker':
        return (
          <PickerContained
            key={item.key}
            style={{
              ...styles.pickerStyle,
              color: highlightEmpty ? ColorPalette.WARNING_RED : ColorPalette.PRIMARY_TEXT,
            }}
            value={value}
            label={label}
            items={selectorItems}
            disabled={disabled}
            onChange={(event) =>
              this.selectionHandler([event.target.value], key, workflowBranch, autoPopulate, isPriField)
            }
          />
        )

      case 'itemSelector':
        return (
          <ItemSelectorTextBox
            key={key}
            jsonKey={key}
            label={label}
            values={data[key]}
            selectorItems={selectorItems}
            selectorHandler={(values, key) => this.selectionHandler(values, key)}
            singleSelectionMode={singleSelectionMode}
          />
        )

      case 'selector':
      case 'conditionalSelector':
        const selectors =
          compType === 'selector'
            ? selectorItems
            : this.extractConditionalSelectors(conditionalSelectorItems as ConditionalSelectorItems, data)
        return (
          <CheckboxTabLabelled
            style={{ width: '100%' }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            key={key}
            label={label}
            values={value}
            defaultValues={defaultValue}
            selectorItems={selectors}
            highlightEmpty={highlightEmpty}
            disabled={disabled}
            maxItems={maxItems}
            selectionHandler={(values, isMount) =>
              this.selectionHandler(values, key, workflowBranch, autoPopulate, isPriField && !isMount)
            }
            maxItemsPerRow={2}
          />
        )
      default:
        return <></>
    }
  }

  initialiseLayout(): JSX.Element[] {
    let { sectionFlow, maxItemsPerRow = 2 } = this.props
    if (!sectionFlow || sectionFlow.length === 0) {
      return []
    }

    let lineItemsToRender: JSX.Element[][] = []
    let counter = 0
    let index = 0
    let numberOfItems = 0
    let encounteredTextComponent = false

    sectionFlow.forEach((item, itemIndex) => {
      try {
        item = { ...this.props.fieldConfig[item.key], ...item }
        const renderItem = this.initialiseRenderItem(item)
        const { compType, multiline } = item
        if (compType === 'text' && !multiline) {
          encounteredTextComponent = true
          if (counter === maxItemsPerRow) {
            counter = 1
            numberOfItems += 1
            index = numberOfItems
            lineItemsToRender = [...lineItemsToRender, [renderItem]]
          } else {
            if (Array.isArray(lineItemsToRender[index])) {
              lineItemsToRender[index] = [...lineItemsToRender[index], renderItem]
            } else {
              lineItemsToRender = [...lineItemsToRender, [renderItem]]
            }
            counter += 1
          }
        } else {
          lineItemsToRender = [...lineItemsToRender, [renderItem]]
          numberOfItems += 1
          if (!encounteredTextComponent) {
            index = numberOfItems
          }
        }
      } catch (error) {}
    })

    return lineItemsToRender.map((lineItem, i) => {
      return (
        <div key={`lineItem_${i}`} style={styles.lineItem}>
          {lineItem}
        </div>
      )
    })
  }

  render() {
    const lineItemsToRender = this.initialiseLayout()
    let columnOne: JSX.Element[] = []
    let columnTwo: JSX.Element[] = []

    if (this.state.mounted) {
      if (lineItemsToRender.length > 1) {
        const midwayIndex = Math.ceil(lineItemsToRender.length / 2)
        columnOne = lineItemsToRender.slice(0, midwayIndex)
        columnTwo = lineItemsToRender.slice(midwayIndex)
      } else if (lineItemsToRender.length === 1) {
        columnOne = [lineItemsToRender[0]]
      }
    }
    return (
      <ScrollView
        // @ts-ignore
        innerRef={(ref: HTMLDivElement) => (this.containerRef.current = ref)}
        style={{
          ...styles.container,
          height:
            this.state.mounted && this.containerRef.current
              ? // @ts-ignore
                `calc(100vh - ${this.containerRef.current.offsetParent?.offsetTop}px)`
              : window.innerWidth * 0.65,
        }}
        contentContainerStyle={{
          paddingBottom: 100,
          width: window.innerWidth * 0.75,
          height: 'fit-content',
        }}>
        <Grid style={{ paddingTop: 0 }} item container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid
            style={{ paddingLeft: 20 }}
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={6}>
            {columnOne}
          </Grid>
          <Grid
            style={{ paddingRight: 20 }}
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={6}>
            {columnTwo}
          </Grid>
        </Grid>
      </ScrollView>
    )
  }
}

const styles = {
  container: {
    width: '100%',
  },
  card: {
    width: '90%',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 0,
    shadowOpacity: 0.16,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    elevation: 8,
  },
  pickerStyle: {
    marginRight: 5,
    minWidth: 120,
    width: '82%',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  lineItem: {
    marginTop: 40,
    width: '84%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
}

export default DataFlowSummary
