import React, { Component } from "react"
import Radium from "radium"
import Spinner from "react-spinkit"

import { ColorPalette } from "../../config/colors"
import TextInputLabelled from "../BaseComponents/Text/TextInputLabelled"
import ButtonBlue from "../BaseComponents/Buttons/ButtonBlue"
import { GeneralErrorCodesEnum } from "../../enums"

interface NewPasswordModalProps {
  submit: (newPassword: string, confirmNewPassword: string) => Promise<void>
  dismiss: () => void
}

interface NewPasswordModalState {
  warning: string
  newPassword: string
  confirmNewPassword: string
}

class NewPassword extends Component<NewPasswordModalProps, NewPasswordModalState> {
  state: NewPasswordModalState = {
    warning: "",
    newPassword: "",
    confirmNewPassword: "",
  }

  submit = async () => {
    try {
      const { newPassword, confirmNewPassword } = this.state
      await this.props.submit(newPassword, confirmNewPassword)
    } catch (error) {
      this.displayWarning(error)
    }
  }

  displayWarning(error: any) {
    console.log("error: ", error)
    let warning = ""
    if (error.code === GeneralErrorCodesEnum.InvalidPassword) {
      warning = "Your new password is invalid"
    } else if (error.code === GeneralErrorCodesEnum.NoInput) {
      warning = "Please enter a new password"
    } else if (error.code === GeneralErrorCodesEnum.PasswordMismatch) {
      warning = "Passwords don't match"
    } else {
      warning = "We encountered a problem. Refresh the page and try again."
    }
    this.setState({ warning })
  }

  render() {
    let actionButton = (
      <ButtonBlue style={{ marginTop: 20, width: "100%" }} onClick={this.submit}>
        {"Submit"}
      </ButtonBlue>
    )

    let warning = null
    if (this.state.warning) {
      warning = <p style={styles.warningTextStyle}>{this.state.warning}</p>
    }

    return (
      <>
        <button style={styles.screenContainer} onClick={() => this.props.dismiss()} />
        <div style={styles.cardContainer}>
          <TextInputLabelled
            containerStyle={{ marginTop: 20 }}
            primaryStyle={{ width: "75%", height: 50 }}
            inputLabelProps={{ color: "secondary" }}
            label="New password"
            value={this.state.newPassword || ""}
            textHandler={(value) => this.setState({ newPassword: value })}
            type="password"
            multiline={false}
            icon="password"
          />
          <TextInputLabelled
            containerStyle={{ marginTop: 20 }}
            primaryStyle={{ width: "75%", height: 50 }}
            inputLabelProps={{ color: "secondary" }}
            label="Retype password"
            value={this.state.confirmNewPassword || ""}
            textHandler={(value) => this.setState({ confirmNewPassword: value })}
            type="password"
            multiline={false}
            icon="password"
          />

          <div style={{ display: "flex", flexDirection: "column" as "column" }}>
            <p style={{ ...styles.textStyle, marginTop: 20 }}>- 8 characters long</p>
            <p style={styles.textStyle}>- Contain upper and lowercase letters</p>
            <p style={styles.textStyle}>- Contain numbers</p>
          </div>
          {actionButton}
          {warning}
        </div>
      </>
    )
  }
}

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    outline: 0,
    border: "none",
    overflow: "hidden",
  },
  cardContainer: {
    zIndex: 2,
    position: "absolute",
    top: window.innerHeight * 0.08,
    left: window.innerWidth * 0.4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    marginRight: window.innerHeight * 0.05,
    width: window.innerWidth * 0.22,
    padding: 30,
    paddingBottom: 30,
    borderRadius: 8,
  },
  textStyle: {
    fontSize: "0.8rem",
    fontWeight: "300",
    marginTop: -10,
    color: ColorPalette.PRIMARY_TEXT,
  },
  warningTextStyle: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: "0.9rem",
    fontWeight: "300",
    marginTop: 30,
    color: ColorPalette.WARNING_RED,
  },
}

export default Radium(NewPassword)
