import { useState } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiPlusCircleOutline, mdiTrashCan } from '@mdi/js'
import { ColorPalette } from '../../../config/colors'

interface ITwoColumnSelectorOurlinedProps {
  label?: string
  values: string[]
  showAddIcon?: boolean
  outlined?: boolean
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
  addOrRemoveLabel?: string
  onClick: () => void
  onValueClick?: (value: string) => void
  onRemoveClick?: (value: string) => void
}

const TwoColumnSelector = (props: ITwoColumnSelectorOurlinedProps) => {
  const [removeIconShowIndex, setRemoveIconShowIndex] = useState(-1)

  const { labelStyle, addOrRemoveLabel, outlined = true, showAddIcon } = props

  const handleSizeOptionClick = (e: React.MouseEvent, value: string) => {
    e.stopPropagation()
    if (props.onValueClick) {
      props.onValueClick(value)
    }
  }

  const handleRemoveClick = (e: React.MouseEvent, value: string) => {
    e.stopPropagation()
    if (props.onRemoveClick) {
      props.onRemoveClick(value)
    }
  }

  const renderOption = (value: string, index: number) => {
    let option: React.ReactNode = value
    if (props.onRemoveClick) {
      option = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 250 }}>{value}</div>
          <div
            id="remove"
            style={{
              ...styles.removeIconWrapper,
              visibility: removeIconShowIndex === index ? 'visible' : 'hidden',
            }}
            title={`Remove ${value}`}
            onClick={(e) => handleRemoveClick(e, value)}
            key={`remove_${value}_${index}`}>
            <Icon path={mdiTrashCan} size={0.65} />
          </div>
        </div>
      )
    }
    return (
      <div
        onMouseEnter={(e) => setRemoveIconShowIndex(index)}
        onMouseLeave={(e) => setRemoveIconShowIndex(-1)}
        onClick={(e) => handleSizeOptionClick(e, value)}
        style={props.onValueClick ? styles.value : styles.valueNotClickable}
        key={`${value}_${index}`}>
        {option}
      </div>
    )
  }

  let label = null
  if (outlined) {
    label = (
      <div style={{ display: 'flex', flexDirection: 'column' as 'column' }}>
        <p style={{ ...styles.label, ...labelStyle }}>{props.label}</p>
      </div>
    )
  } else if (props.label) {
    label = (
      <div style={{ display: 'flex', flexDirection: 'column' as 'column' }}>
        <p style={{ ...styles.label, alignSelf: 'flex-start', padding: 0, ...labelStyle }}>{props.label}</p>
      </div>
    )
  }

  let addIcon = null
  if (showAddIcon) {
    addIcon = <Icon path={mdiPlusCircleOutline} color={ColorPalette.PRIMARY_BLUE} style={styles.buttonIcon} />
  }

  let columnCount = props.values.length > 3 ? 2 : 1
  if (props.onValueClick) {
    columnCount = props.values.length > 2 ? 2 : 1
  }

  return (
    <div style={{ ...styles.input, ...props.style }} onClick={props.onClick}>
      {label}
      <div style={outlined ? styles.outlinedContainer : styles.defaultContainer}>
        <div style={{ columnCount, columnGap: '1em' }}>
          {props.values.length ? (
            <>
              {props.values.map(renderOption)}
              <div
                style={{
                  ...styles.value,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5em',
                  color: ColorPalette.MEDIUM_GREY,
                }}>
                {addIcon}
                {addOrRemoveLabel || 'Add / remove?'}
              </div>
            </>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', color: ColorPalette.MEDIUM_GREY }}>
              {addIcon}
              Click to add options
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Radium(TwoColumnSelector)

const styles = {
  input: {
    margin: '20px 1.5em 0px 1.5em',
    cursor: 'pointer',
  },
  outlinedContainer: {
    zIndex: 1,
    marginTop: '-1.25em',
    padding: '1.5em 3em',
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 16,
    paddingBottom: 15,
    color: ColorPalette.PRIMARY_TEXT,
    minHeight: '80px',
    maxHeight: '160px',
    overflowY: 'scroll' as 'scroll',
  },
  label: {
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    zIndex: 9,
    alignSelf: 'center',
    fontSize: '0.65rem',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center' as 'center',
  },
  value: {
    color: 'inherit',
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
  },
  removeIconWrapper: {
    color: ColorPalette.WARNING_RED_30PERCENT,
    display: 'flex',
    alignItems: 'center',
    paddingInline: '1em',
    ':hover': {
      color: ColorPalette.WARNING_RED,
    },
  },
  buttonIcon: {
    width: '1.25rem',
    height: '1.25rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  valueNotClickable: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '96%',
    textOverflow: 'ellipsis',
    overflowY: 'hidden' as 'hidden',
  },
  defaultContainer: { padding: '1em 0.5em' },
}
